import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { notification } from "antd";

import routes from "configs/routes";
import { useDispatch } from "react-redux";
import ModalError from "components/Modal/ModalError";
import { openModalApiError } from "stores/common/actions";
import { IconXSvg } from "components/Svgs";

function App() {
	const element = useRoutes(routes);
	const dispatch = useDispatch();

	notification.config({
		key: "notification-config",
		placement: "bottomLeft",
		closeIcon: <IconXSvg color="#7C7C7C" />,
		maxCount: 1,
	});

	useEffect(() => {
		Function.prototype.callbackApiError = (err) => dispatch(openModalApiError(err));
		return () => {};
	}, []);

	return (
		<>
			{element}
			<ModalError />
		</>
	);
}

export default App;
