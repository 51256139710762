import produce from "immer";
import { notificationsActions } from "./actions";

const INITIAL_STATE = {
	notifications: {},
	error: null,
};

const notificationsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case notificationsActions.FETCH_NOTIFICATIONS_SUCCESS:
			const docs = draft.notifications?.docs ?? [];
			draft.notifications = action.payload;
			break;
		case notificationsActions.FETCH_MORE_NOTIFICATIONS_SUCCESS:
			if (action.payload) {
				draft.notifications = { ...action.payload, docs: [...docs, ...action.payload?.docs] };
			}
		case notificationsActions.SOCKET_NOTIFICATIONS_SUCCESS:
			draft.notifications?.docs?.unshift(action.payload);
			break;
		default:
			break;
	}
	return draft;
});

export default notificationsReducer;
