import { combineActiontype } from "utils/helpers";

const page = "notifications";

export const notificationsActions = combineActiontype(page, {
	FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
	FETCH_NOTIFICATIONS_FAILURE: "FETCH_NOTIFICATIONS_FAILURE",

	FETCH_MORE_NOTIFICATIONS_SUCCESS: "FETCH_MORE_NOTIFICATIONS_SUCCESS",
	FETCH_MORE_NOTIFICATIONS_FAILURE: "FETCH_MORE_NOTIFICATIONS_FAILURE",

	SOCKET_NOTIFICATIONS_SUCCESS: "SOCKET_NOTIFICATIONS_SUCCESS",
	SOCKET_NOTIFICATIONS_FAILURE: "SOCKET_NOTIFICATIONS_FAILURE",
});

export const fetchNotificationsSuccess = (notis) => ({
	type: notificationsActions.FETCH_NOTIFICATIONS_SUCCESS,
	payload: notis,
});

export const fetchNotificationsFailure = (error) => ({
	type: notificationsActions.FETCH_NOTIFICATIONS_FAILURE,
	payload: error,
});

export const fetchMoreNotificationsSuccess = (notis) => ({
	type: notificationsActions.FETCH_MORE_NOTIFICATIONS_SUCCESS,
	payload: notis,
});

export const fetchMoreNotificationsFailure = (error) => ({
	type: notificationsActions.FETCH_MORE_NOTIFICATIONS_FAILURE,
	payload: error,
});

export const socketNotificationsSuccess = (notis) => ({
	type: notificationsActions.SOCKET_NOTIFICATIONS_SUCCESS,
	payload: notis,
});

export const socketNotificationsFailure = (error) => ({
	type: notificationsActions.SOCKET_NOTIFICATIONS_FAILURE,
	payload: error,
});
