/* eslint-disable react/prop-types */
import React from "react";

export const IconHomeSvg = ({ color = "#8E929A", ...props }) => {
	return (
		<svg width="18px" height="20px" viewBox="0 0 18 20" color={color} {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g transform="translate(-39.000000, -98.000000)" stroke="currentColor" strokeWidth="2">
					<g transform="translate(0.000000, 76.000000)">
						<g transform="translate(40.000000, 23.000000)">
							<path d="M0,6.3 L8.1,0 L16.2,6.3 L16.2,16.2 C16.2,17.1941125 15.3941125,18 14.4,18 L1.8,18 C0.80588745,18 0,17.1941125 0,16.2 L0,6.3 Z"></path>
							<polyline points="5.4 18 5.4 9 10.8 9 10.8 18"></polyline>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
