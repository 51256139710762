/* eslint-disable react/prop-types */
import React from "react";

export const IconCheckMarkDoneSvg = ({ width = 12, height = 12, color = "#19c0ff", ...props }) => {
	return (
		<svg stroke={color} fill={color} strokeWidth="0" viewBox="0 0 512 512" width={width} height={height} {...props}>
			<path
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="32"
				d="M464 128L240 384l-96-96m0 96l-96-96m320-160L232 284"
			></path>
		</svg>
	);
};
