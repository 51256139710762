import { combineActiontype } from "utils/helpers";

const page = "dbCommon";

export const commonActions = combineActiontype(page, {
	FETCH_IDENTIFY_SUCCESS: "FETCH_IDENTIFY_SUCCESS",
	FETCH_IDENTIFY_FAILURE: "FETCH_IDENTIFY_FAILURE",

	FETCH_VALIDATE_ACTIVATION_SUCCESS: "FETCH_VALIDATE_ACTIVATION_SUCCESS",
	FETCH_VALIDATE_ACTIVATION_FAILURE: "FETCH_VALIDATE_ACTIVATION_FAILURE",

	OPEN_MODAL_API_ERROR: "OPEN_MODAL_API_ERROR",
	CANCEL_MODAL_API_ERROR: "CANCEL_MODAL_API_ERROR",
});

export const fetchIdentifySuccess = (identify) => ({
	type: commonActions.FETCH_IDENTIFY_SUCCESS,
	payload: identify,
});

export const fetchIdentifyFailure = (error) => ({
	type: commonActions.FETCH_IDENTIFY_FAILURE,
	payload: error,
});

export const fetchValidateActivationySuccess = (avt) => ({
	type: commonActions.FETCH_VALIDATE_ACTIVATION_SUCCESS,
	payload: avt,
});

export const fetchValidateActivationyFailure = (error) => ({
	type: commonActions.FETCH_VALIDATE_ACTIVATION_FAILURE,
	payload: error,
});

export const openModalApiError = (payload) => ({
	type: commonActions.OPEN_MODAL_API_ERROR,
	payload,
});

export const cancelModalApiError = () => ({
	type: commonActions.CANCEL_MODAL_API_ERROR,
});
