/* eslint-disable react/prop-types */
import React from "react";

export const IconCheckSvg = ({ width = 12, height = 12, color = "#19c0ff", ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			{...props}
		>
			<polyline points="20 6 9 17 4 12"></polyline>
		</svg>
	);
};
