import { combineActiontype } from "utils/helpers";

const page = "dbBookings";

export const dbBookingsActions = combineActiontype(page, {
	FETCH_BOOKINGS_SUCCESS: "FETCH_BOOKINGS_SUCCESS",
	FETCH_BOOKINGS_FAILURE: "FETCH_BOOKINGS_FAILURE",

	FETCH_BOOKING_DETAIL_SUCCESS: "FETCH_BOOKING_DETAIL_SUCCESS",
	FETCH_BOOKING_DETAIL_FAILURE: "FETCH_BOOKING_DETAIL_FAILURE",
});

export const fetchBookingsSuccess = (bookings) => ({
	type: dbBookingsActions.FETCH_BOOKINGS_SUCCESS,
	payload: bookings,
});

export const fetchBookingsFailure = (error) => ({
	type: dbBookingsActions.FETCH_BOOKINGS_FAILURE,
	payload: error,
});

export const fetchBookingDetailSuccess = (bookings) => ({
	type: dbBookingsActions.FETCH_BOOKING_DETAIL_SUCCESS,
	payload: bookings,
});

export const fetchBookingDetailFailure = (error) => ({
	type: dbBookingsActions.FETCH_BOOKING_DETAIL_FAILURE,
	payload: error,
});
