import React from "react";

export const IconArrowUpSvg = (props) => (
	<svg width={10} height={7} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M4.999 2.366 1.218 6.15a.712.712 0 0 1-1.01 0 .718.718 0 0 1 0-1.012L4.494.85A.713.713 0 0 1 5.478.83l4.314 4.305c.14.14.208.324.208.506a.714.714 0 0 1-1.218.506L5 2.366Z"
			fill="#8E929A"
		/>
	</svg>
);
