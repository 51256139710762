/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";

import { IconLogoSvg } from "components/Svgs";
import Typography from "components/Typography";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return (
				<Container>
					<Wrapper>
						<IconLogoSvg color="#19C0FF" />
						<Typography color="#DD4558" variant="h0">
							Something seems off...
						</Typography>
						<Typography variant="small">We are experiencing some technical difficulties.</Typography>
						<Typography variant="small" style={{ marginBottom: 50 }}>
							Please try againlater.
						</Typography>
						<Button
							type="primary"
							width="300px"
							height="50px"
							onClick={() => {
								this.props.navigate("/");
								this.props.navigate(0);
							}}
						>
							<Typography variant="body2">GO BACK</Typography>
						</Button>
					</Wrapper>
				</Container>
			);
		}

		return this.props.children;
	}
}

const ErrorBoundaryWrapper = ({ children }) => {
	const navigate = useNavigate();

	return <ErrorBoundary navigate={navigate}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWrapper;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 100%;
	background-color: #f2f4f7;
	text-align: center;
`;

const Wrapper = styled.div`
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0 10px 20px 0 rgb(14 14 18 / 10%);
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 100px;
`;
