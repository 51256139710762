import produce from "immer";
import { signInActions } from "./actions";

const INITIAL_STATE = {
	user: {},
	accessToken: null,
};

const signInReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case signInActions.FETCH_GET_USER_SUCCESS:
			draft.user = action.payload;
			break;
		case signInActions.FETCH_UPDATE_ACCESS_TOKEN:
			draft.accessToken = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default signInReducer;
