import produce from "immer";
import { dbAmenitiesActions } from "./actions";

const INITIAL_STATE = {
	amenities: [],
	amenityDetail: {},
	error: null,
};

const dbAmenitiesReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbAmenitiesActions.FETCH_AMENITIES_SUCCESS:
			draft.amenities = action.payload;
			break;
		case dbAmenitiesActions.FETCH_AMENITY_DETAIL_SUCCESS:
			draft.amenityDetail = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbAmenitiesReducer;
