import React from "react";

export const IconDollarSvg = (props) => (
	<svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M13.985 13.16a1.493 1.493 0 1 1 0-2.989c.826 0 1.494.668 1.494 1.494 0 .29.237.527.526.527.29 0 .527-.237.527-.527a2.55 2.55 0 0 0-2.02-2.493v-.674a.528.528 0 0 0-.527-.526.528.528 0 0 0-.526.526v.674a2.544 2.544 0 0 0-1.963 3.02 2.55 2.55 0 0 0 2.489 2.02 1.493 1.493 0 1 1 0 2.988 1.493 1.493 0 0 1-1.494-1.494.528.528 0 0 0-.526-.526.528.528 0 0 0-.527.526c0 1.2.842 2.242 2.02 2.489v1a.528.528 0 0 0 1.052 0v-1a2.553 2.553 0 0 0 1.964-3.02 2.54 2.54 0 0 0-2.489-2.015Z"
			fill="#8E929A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="m22.462 5.515.042.042c4.672 4.693 4.65 12.28-.042 16.952-4.694 4.672-12.28 4.651-16.953-.042A11.991 11.991 0 0 1 2 13.99C2 7.367 7.367 2 13.986 2a11.869 11.869 0 0 1 8.476 3.514ZM3.047 13.99c0 6.03 4.909 10.933 10.939 10.933s10.933-4.909 10.933-10.938c0-6.03-4.904-10.933-10.933-10.933-6.03 0-10.939 4.909-10.939 10.938Z"
			fill="#8E929A"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.81 13.987c0-5.061 4.114-9.175 9.176-9.175 5.06 0 9.175 4.114 9.175 9.175 0 5.062-4.114 9.176-9.176 9.176-5.061 0-9.175-4.114-9.175-9.176Zm1.052 0c0 4.478 3.646 8.124 8.123 8.124 4.478 0 8.124-3.646 8.124-8.123 0-4.478-3.646-8.124-8.124-8.124-4.477 0-8.123 3.646-8.123 8.124Z"
			fill="#8E929A"
		/>
	</svg>
);
