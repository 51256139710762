import React from "react";

export const IconOutgoingSvg = (props) => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M8 13.5v-11M3.5 7 8 2.5 12.5 7"
			stroke="#DD4558"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
