import React from "react";

export const IconMoneySvg = (props) => (
	<svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M15.364 2.636A8.941 8.941 0 0 0 9 0a8.941 8.941 0 0 0-6.364 2.636A8.941 8.941 0 0 0 0 9c0 2.404.936 4.664 2.636 6.364A8.94 8.94 0 0 0 9 18a8.941 8.941 0 0 0 6.364-2.636A8.941 8.941 0 0 0 18 9a8.941 8.941 0 0 0-2.636-6.364ZM9 8.437a2.534 2.534 0 0 1 2.531 2.532 2.535 2.535 0 0 1-1.84 2.434.177.177 0 0 0-.129.17v.474a.573.573 0 0 1-.535.577.563.563 0 0 1-.59-.562v-.49a.176.176 0 0 0-.128-.169 2.535 2.535 0 0 1-1.84-2.418.572.572 0 0 1 .546-.579.563.563 0 0 1 .579.563c0 .808.686 1.46 1.507 1.402a1.412 1.412 0 0 0 1.302-1.302A1.408 1.408 0 0 0 9 9.562a2.534 2.534 0 0 1-2.531-2.53c0-1.157.779-2.133 1.839-2.435a.177.177 0 0 0 .13-.17v-.474c0-.303.232-.563.535-.577a.563.563 0 0 1 .59.562v.49c0 .079.052.147.128.169a2.535 2.535 0 0 1 1.84 2.419.572.572 0 0 1-.546.577.563.563 0 0 1-.579-.562c0-.808-.686-1.46-1.507-1.402A1.412 1.412 0 0 0 7.597 6.93 1.408 1.408 0 0 0 9 8.437Z"
			fill="#19C0FF"
		/>
	</svg>
);
