/* eslint-disable react/prop-types */
import React from "react";

export const IconExecutedSvg = ({ ...props }) => {
	return (
		<svg width="18px" height="18px" viewBox="0 0 56 56" version="1.1" {...props}>
			<g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
				<g>
					<g
						transform="translate(16.000000, 18.000000)"
						stroke="#7ED321"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3"
					>
						<polyline points="25 0 7.5 20 0 12.5"></polyline>
					</g>
				</g>
			</g>
		</svg>
	);
};
