import React from "react";

export const IconStatementSvg = (props) => (
	<svg width={19} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="m14.886 4.717-3.125-3.125a.312.312 0 0 0-.221-.092H4.978c-.69 0-1.25.56-1.25 1.25v12.5c0 .69.56 1.25 1.25 1.25h8.75c.689 0 1.25-.56 1.25-1.25V4.937a.312.312 0 0 0-.092-.22Zm-3.033-2.15 2.058 2.058h-1.434A.626.626 0 0 1 11.853 4V2.567Zm2.5 12.683c0 .345-.28.625-.625.625h-8.75a.626.626 0 0 1-.625-.625V2.75c0-.345.28-.625.625-.625h6.25V4c0 .69.56 1.25 1.25 1.25h1.875v10Z"
			fill="#8E929A"
			stroke="#8E929A"
			strokeWidth={0.2}
		/>
		<path
			d="M12.79 10.25H5.915a.312.312 0 1 0 0 .625h6.875a.312.312 0 1 0 0-.625ZM12.79 8.375H5.915a.312.312 0 1 0 0 .625h6.875a.312.312 0 1 0 0-.625ZM12.79 12.125H5.915a.312.312 0 1 0 0 .625h6.875a.312.312 0 1 0 0-.625ZM9.665 14h-3.75a.312.312 0 1 0 0 .625h3.75a.312.312 0 1 0 0-.625ZM8.728 6.813c0 .172.14.312.312.312h3.75a.312.312 0 1 0 0-.625H9.04a.312.312 0 0 0-.312.313ZM6.54 6.5h-.625a.312.312 0 1 0 0 .625h.313a.312.312 0 1 0 .625 0v-.057A.936.936 0 0 0 6.54 5.25a.313.313 0 0 1 0-.625h.625a.312.312 0 1 0 0-.625h-.312a.312.312 0 1 0-.625 0v.058a.936.936 0 0 0 .312 1.817.313.313 0 0 1 0 .625Z"
			fill="#8E929A"
			stroke="#8E929A"
			strokeWidth={0.2}
		/>
	</svg>
);
