/* eslint-disable react/prop-types */
import React from "react";

export const IconErrorSvg = ({ color = "#DD4558", ...props }) => {
	return (
		<svg width={props.width || "65px"} height={props.height || "64px"} viewBox="0 0 65 64" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-928.000000, -522.000000)" fill={color} fillRule="nonzero">
					<g transform="translate(613.000000, 430.000000)">
						<g transform="translate(142.500000, 92.000000)">
							<g transform="translate(173.000000, 0.000000)">
								<path d="M32.0002098,0 C14.3552108,0 0,14.3550951 0,32 C0,49.6449049 14.3552108,64 32.0002098,64 C49.644999,64 64,49.6449049 64,32 C64,14.3550951 49.644999,0 32.0002098,0 Z M32.0002098,58.7540984 C17.247782,58.7540984 5.24586724,46.7525246 5.24586724,32 C5.24586724,17.2476852 17.247782,5.24590164 32.0002098,5.24590164 C46.7524279,5.24590164 58.7541328,17.2476852 58.7541328,32 C58.7541328,46.7525246 46.7524279,58.7540984 32.0002098,58.7540984 Z"></path>
								<g transform="translate(18.000000, 18.000000)">
									<path d="M17.3176224,14 L27.3181461,3.99685699 C28.2272846,3.08748036 28.2272846,1.59140911 27.3181461,0.682032478 C26.4090076,-0.227344159 24.9133281,-0.227344159 24.0041896,0.682032478 L14.0036659,10.6851755 L4.00314218,0.682032478 C3.09400367,-0.227344159 1.59832417,-0.227344159 0.689185651,0.682032478 C0.234616392,1.1367208 0,1.73808277 0,2.33944474 C0,2.94080671 0.234616392,3.54216867 0.689185651,3.99685699 L10.6897093,14 L0.689185651,24.003143 C0.234616392,24.4578313 0,25.0591933 0,25.6605553 C0,26.2619172 0.234616392,26.8632792 0.689185651,27.3179675 C1.59832417,28.2273442 3.09400367,28.2273442 4.00314218,27.3179675 L14.0036659,17.3148245 L24.0041896,27.3179675 C24.9133281,28.2273442 26.4090076,28.2273442 27.3181461,27.3179675 C28.2272846,26.4085909 28.2272846,24.9125196 27.3181461,24.003143 L17.3176224,14 Z"></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
