import produce from "immer";
import { dbUserAccountsActions } from "./actions";

const INITIAL_STATE = {
	userAccounts: {},
	userAccountDetail: {},
	error: null,
};

const dbUserAccountsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbUserAccountsActions.FETCH_USER_ACCOUNTS_SUCCESS:
			draft.userAccounts = action.payload;
			break;
		case dbUserAccountsActions.FETCH_USER_ACCOUNT_DETAIL_SUCCESS:
			draft.userAccountDetail = action.payload;
			break;
		case dbUserAccountsActions.FETCH_ACTIVATE_USER_ACCOUNT_SUCCESS:
			draft.userAccountDetail.activated_at = action.payload;
			break;
		case dbUserAccountsActions.FETCH_BLOCK_USER_ACCOUNT_SUCCESS:
			draft.userAccountDetail.blocked_at = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbUserAccountsReducer;
