import React from "react";

export const IconTransferSvg = (props) => (
	<svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={16} cy={16} r={16} fill="#FEF2EC" />
		<g clipPath="url(#a)" fill="#F6803D">
			<path d="M23.5 18.001a.5.5 0 0 1-.5-.5c0-1.93-1.57-3.5-3.5-3.5h-4.833v2.167a.5.5 0 0 1-.846.361l-3.666-3.5a.499.499 0 0 1 0-.722l3.666-3.5a.5.5 0 0 1 .846.361v2.167h4.166A5.173 5.173 0 0 1 24 16.5v1a.5.5 0 0 1-.5.5Z" />
			<path d="M17.333 22.833v-2.166h-4.166A5.173 5.173 0 0 1 8 15.5v-1a.5.5 0 0 1 1 0c0 1.93 1.57 3.5 3.5 3.5h4.833v-2.167a.5.5 0 0 1 .846-.361l3.666 3.5a.5.5 0 0 1 0 .723l-3.666 3.5a.5.5 0 0 1-.846-.362Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" transform="translate(8 8)" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
