import produce from "immer";
import { dbWorldWideCompaniesActions } from "./actions";

const INITIAL_STATE = {
	wwCompanies: {},
	wwCompanyDetail: {},
};

const dbWorldWideCompaniesReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbWorldWideCompaniesActions.FETCH_WORLDWIDE_COMPANIES_SUCCESS:
			draft.wwCompanies = action.payload;
			break;
		case dbWorldWideCompaniesActions.FETCH_WORLDWIDE_COMPANY_DETAIL_SUCCESS:
			draft.wwCompanyDetail = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbWorldWideCompaniesReducer;
