import React from "react";

export const IconTopUpSvg = (props) => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M18.75 15v1.563c0 .861-.701 1.562-1.563 1.562H3.75a2.503 2.503 0 0 1-2.5-2.5V5c0-1.379 1.121-2.5 2.5-2.5h11.563a.937.937 0 1 1 0 1.875H3.75a.626.626 0 0 0 0 1.25h13.438c.86 0 1.562.7 1.562 1.563V8.75h-3.125a3.129 3.129 0 0 0-3.125 3.125A3.129 3.129 0 0 0 15.625 15h3.125Z"
			fill="#19C0FF"
		/>
		<path d="M18.75 10v3.75h-3.125a1.875 1.875 0 1 1 0-3.75h3.125Z" fill="#19C0FF" />
	</svg>
);
