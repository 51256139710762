import { ConfigProvider as AntdConfigProvider } from "antd";

AntdConfigProvider.config({
	theme: {
		primaryColor: "#19C0FF",
		infoColor: "#65686F",
		successColor: "#7ED321",
		processingColor: "#F5A623",
		errorColor: "#DD4558",
		warningColor: "#F4D522",
	},
});

export default AntdConfigProvider;
