/* eslint-disable react/prop-types */
import React from "react";

export const IconLinkSvg = ({ color = "#8A99A9", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8.2912 15.2438L5.93451 17.6005C4.95747 18.5776 3.37523 18.5776 2.39902 17.6007C1.42261 16.6243 1.42261 15.0419 2.39882 14.0657L7.11303 9.3515C8.08925 8.37524 9.67164 8.37524 10.6479 9.3515C10.9733 9.67693 11.5009 9.67693 11.8264 9.3515C12.1518 9.02607 12.1518 8.49841 11.8264 8.17298C10.1993 6.54586 7.56163 6.54586 5.93451 8.17298L1.22034 12.8871C-0.40678 14.5143 -0.40678 17.1519 1.22034 18.779C2.84726 20.407 5.48509 20.407 7.11307 18.779L9.46976 16.4223C9.79519 16.0969 9.79519 15.5692 9.46976 15.2438C9.14433 14.9184 8.61663 14.9184 8.2912 15.2438Z"
				fill={color}
			/>
			<path
				d="M18.779 1.22034C17.1519 -0.40678 14.5134 -0.40678 12.8863 1.22034L10.0587 4.04786C9.7333 4.37329 9.7333 4.90095 10.0587 5.22638C10.3842 5.55181 10.9118 5.55181 11.2373 5.22638L14.0648 2.39886C15.041 1.42261 16.6242 1.42261 17.6005 2.39886C18.5767 3.37508 18.5767 4.95747 17.6005 5.93369L12.4154 11.1188C11.4392 12.095 9.85682 12.095 8.8806 11.1188C8.55517 10.7933 8.02751 10.7933 7.70208 11.1188C7.37665 11.4442 7.37665 11.9718 7.70208 12.2973C9.3292 13.9244 11.9668 13.9244 13.594 12.2973L18.779 7.11225C20.4061 5.48513 20.4061 2.84746 18.779 1.22034Z"
				fill={color}
			/>
		</svg>
	);
};
