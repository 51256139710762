/* eslint-disable react/prop-types */
import React from "react";

export const IconFaviconSvg = ({ width = "419px", height = "319px", ...props }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 419 319"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g fill="#FFFFFF" fillRule="nonzero">
					<g>
						<path d="M259.018182,181.564345 C269.157086,223.25469 279.725445,260.169694 289.490909,303.809524 L284.878921,167.095238 L259.018182,181.564345 Z"></path>
						<path d="M259.793323,98.5796215 L254.797297,0 C126.565978,136.014703 0,319 0,319 C188.183625,181.054054 419,74.4563241 419,74.4563241 C179.956837,169.621784 136.008466,195.486649 136.008466,195.486649 C207.967886,91.1132973 230.583227,65.9036759 230.583227,65.9036759 C230.583227,65.9036759 233.231121,88.6647567 239.675994,128.582865 C239.675994,128.582865 373.35298,75.1288107 412.871541,60.0064866 C412.788275,59.9547567 263.490381,97.9243785 259.793323,98.5796215 Z"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};
