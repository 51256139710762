/* eslint-disable react/prop-types */
import React from "react";

export const IconCreatedSvg = ({ color = "#0E9EF3", ...props }) => {
	return (
		<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-694.000000, -365.000000)">
					<g transform="translate(330.000000, 80.000000)">
						<g transform="translate(45.000000, 268.000000)">
							<g transform="translate(319.000000, 17.000000)">
								<path
									d="M9,0 C13.9705627,-9.13077564e-16 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 6.08718376e-16,13.9705627 0,9 C-6.08718376e-16,4.02943725 4.02943725,9.13077564e-16 9,0 Z"
									fill={color}
								></path>
								<g transform="translate(8.000000, 3.000000)" fill="#FFFFFF" fillRule="nonzero">
									<path d="M0,4.9137931 L2,4.9137931 L2,12 L0,12 L0,4.9137931 Z M0,0 L2,0 L2,2.48275862 L0,2.48275862 L0,0 Z"></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
