import React from "react";

export const IconPendingSvg = (props) => (
	<svg width={37} height={37} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={18.877} cy={18.5} r={18} fill="#F5A623" />
		<g clipPath="url(#a)">
			<path
				d="M19.401 27.5c4.976 0 9.012-4.03 9.012-9s-4.036-9-9.012-9c-4.975 0-9.011 4.03-9.011 9s4.036 9 9.011 9Zm-.643-14.143c0-.353.29-.643.643-.643.355 0 .644.29.644.643v4.834l2.977 2.379a.643.643 0 1 1-.804 1.003L18.999 19a.644.644 0 0 1-.241-.501v-5.143Z"
				fill="#fff"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" transform="translate(9.889 9.5)" d="M0 0h18.024v18H0z" />
			</clipPath>
		</defs>
	</svg>
);
