/* eslint-disable react/prop-types */
import React from "react";

export const IconSlashSvg = ({ color = "#8E929A", ...props }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 24 24" {...props}>
			<g strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" fill="none" stroke={color}>
				<circle cx="12" cy="12" r="10" />
				<path d="M4.93 4.93l14.14 14.14" />
			</g>
		</svg>
	);
};
