import produce from "immer";
import { dbBookingsActions } from "./actions";

const INITIAL_STATE = {
	bookings: {},
	bookingDetail: {},
	error: null,
};

const dbBookingsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbBookingsActions.FETCH_BOOKINGS_SUCCESS:
			draft.bookings = action.payload;
			break;
		case dbBookingsActions.FETCH_BOOKING_DETAIL_SUCCESS:
			draft.bookingDetail = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbBookingsReducer;
