import { combineActiontype } from "utils/helpers";

const page = "mnAirports";

export const mnAirportsActions = combineActiontype(page, {
	FETCH_AIRPORTS_SUCCESS: "FETCH_AIRPORTS_SUCCESS",
	FETCH_AIRPORTS_FAILURE: "FETCH_AIRPORTS_FAILURE",

	FETCH_AIRPORT_DETAIL_SUCCESS: "FETCH_AIRPORT_DETAIL_SUCCESS",
	FETCH_AIRPORT_DETAIL_FAILURE: "FETCH_AIRPORT_DETAIL_FAILURE",
});

export const fetchAirportsSuccess = (airports) => ({
	type: mnAirportsActions.FETCH_AIRPORTS_SUCCESS,
	payload: airports,
});

export const fetchAirportsFailure = (error) => ({
	type: mnAirportsActions.FETCH_AIRPORTS_FAILURE,
	payload: error,
});

export const fetchAirportDetailSuccess = (airport) => ({
	type: mnAirportsActions.FETCH_AIRPORT_DETAIL_SUCCESS,
	payload: airport,
});

export const fetchAirportDetailFailure = (error) => ({
	type: mnAirportsActions.FETCH_AIRPORT_DETAIL_FAILURE,
	payload: error,
});
