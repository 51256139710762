/* eslint-disable react/prop-types */
import countries from "./countries";
import timeZones from "./timeZones";

import { PATH_DATABASE_COMPANIES, PATH_DATABASE_LOCATIONS, PATH_DATABASE_USER_ACCOUNTS } from "configs/routes";

export { countries, timeZones };

export const ERROR_DATA_FAILED = "Data Failed";
export const ERROR_CLIENT_UNKNOWN_ERROR = "Client Unknown Error";
export const REFRESH_TOKEN_EXPIRED = "Your session has expired, please login again.";

export const USERTYPES = {
	CUSTOMER: "CUSTOMER",
	EMPLOYEE: "EMPLOYEE",
};

export const SOCKET_EVENT = {
	CONNECT: "connect",
	DISCONNECT: "disconnect",
	MESSAGE: "message",
	ERROR: "error",
	POST: "post",
};

export const ACTIVE_AIRCRAFT_STATUS = "ACTIVE";
export const PENDING_AIRCRAFT_STATUS = "CONFLICTED";

export const MEDIA_XS_MAX = "575px";
export const MEDIA_SM_MIN = "576px";
export const MEDIA_SM_MAX = "767px";

export const MEDIA_MD_MIN = "768px";
export const MEDIA_MD_MAX = "991px";

export const MEDIA_LG_MIN = "992px";
export const MEDIA_LG_MAX = "1199px";

export const MEDIA_XL_MIN = "1200px";
export const MEDIA_XL_MAX = "1599px";

export const MEDIA_XXL_MIN = "1600px";

export const SORT_DIRECTION = {
	ascend: "asc",
	descend: "desc",
};

export const UPLOAD_ICON_MAX_SIZE = 20480;

export const UPLOAD_IMAGE_MAX_SIZE = 10485760;

export const UPLOAD_ONE_KB = 1024;

export const UPLOAD_ONE_MB = 1048576;

export const PAGE_SIZE_LIMIT = 20;
export const PAGE_SIZE_MAX_LIMIT = 50;

export const ADMIN_ROLE_SUPER = "SUPER";

export const ADMIN_ROLES = [
	{
		label: "Operations",
		value: "OPERATIONS",
	},
	{
		label: "Sales",
		value: "SALES",
	},
];

export const AVIAN_PAY_PAYOUT_SPEED_TYPES = [
	{
		label: "Immediate",
		value: 0,
	},
	{
		label: "24 Hrs PTD (1 day)",
		value: 24,
	},
	{
		label: "48 Hrs PTD (2 days)",
		value: 48,
	},
	{
		label: "72 Hrs PTD (3 days)",
		value: 72,
	},
	{
		label: "96 Hrs PTD (4 days)",
		value: 96,
	},
	{
		label: "120 Hrs PTD (5 days)",
		value: 120,
	},
	{
		label: "168 Hrs PTD (1 week)",
		value: 168,
	},
	{
		label: "336 Hrs PTD (2 weeks)",
		value: 336,
	},
	{
		label: "504 Hrs PTD (3 weeks)",
		value: 504,
	},
	{
		label: "672 Hrs PTD (4weeks)",
		value: 672,
	},
];

export const LOG_ERRORS = [
	{
		label: "ALL",
		value: "ALL",
	},
	{
		label: "ERROR",
		value: "ERROR",
	},
	{
		label: "NOTIFICATION",
		value: "NOTIFICATION",
	},
	{
		label: "WARNING",
		value: "WARNING",
	},
];

export const AVIAN_PAY_ACCOUNT_FILTER_STATUSES = [
	{
		label: "All Status",
		value: "ALL",
	},
	{
		label: "Awaiting Activation",
		value: "AWAITING",
	},
	{
		label: "Active Account",
		value: "ACTIVATED",
	},
];

export const STATEMENT_STATUSES = [
	{
		label: "All Status",
		value: "ALL",
	},
	{
		label: "Pending",
		value: "PENDING",
	},
	{
		label: "Payment Due",
		value: "PAYMENT_DUE",
	},
	{
		label: "Posted",
		value: "POSTED",
	},
];

export const TRANSFER_STATUSES = [
	{
		label: "Pending",
		value: "PENDING",
	},
	{
		label: "History",
		value: "HISTORY",
	},
];

export const DISPUTE_STATUSES = [
	{
		label: "All Status",
		value: "ALL",
	},
	{
		label: "Under Review",
		value: "UNDER_REVIEW",
	},
	{
		label: "Complete",
		value: "COMPLETE",
	},
];

export const TRANSFER_TYPES = [
	{
		label: "External Account",
		value: "EXTERNAL_ACCOUNT",
	},
	{
		label: "Company",
		value: "COMPANY",
	},
	{
		label: "Individual",
		value: "INDIVIDUAL",
	},
	{
		label: "Top Up Avian Pay Account",
		value: "TOP_UP",
	},
];

export const MARKETPLACE_ITEM_TYPES = [
	{
		label: "Empty Leg",
		value: "Flight",
	},
	{
		label: "Request",
		value: "QuoteRequest",
	},
];

export const FILTER_STATUS_AVIAN_PAY_DASHBOARD = [
	{
		label: "All Status",
		value: "ALL",
	},
	{
		label: "Pending",
		value: "PENDING",
	},
	{
		label: "Awaiting Confirmation",
		value: "AWAITING_CONFIRMATION",
	},
	{
		label: "Posted",
		value: "POSTED",
	},
];

export const FILTER_TYPE_AVIAN_PAY_DASHBOARD = [
	{
		label: "All Type",
		value: "ALL",
	},
	{
		label: "Invoice Payment",
		value: "INVOICE_PAYMENT",
	},
	{
		label: "External Transfer",
		value: "EXTERNAL_TRANSFER",
	},
	{
		label: "Company Transfer",
		value: "COMPANY_TRANSFER",
	},
	{
		label: "Account Top Up",
		value: "TOP_UP",
	},
	{
		label: "Individual Transfer",
		value: "INDIVIDUAL_TRANSFER",
	},
	{
		label: "Flight Payment",
		value: "FLIGHT_PAYMENT",
	},
];

export const AVIAN_PAY_ACTIVITY_ACCOUNT_TYPE = {
	NEW_ACCOUNT: "NEW_ACCOUNT",
	FAST_PAYOUT_REQUEST: "FAST_PAYOUT_REQUEST",
};

export const MODEL_TRANSFER_TYPES = {
	COMPANY_TRANSFER: "COMPANY_TRANSFER",
	INDIVIDUAL_TRANSFER: "INDIVIDUAL_TRANSFER",
	EXTERNAL_TRANSFER: "EXTERNAL_TRANSFER",
	AVIANPAY_TOP_UP: "AVIANPAY_TOP_UP",
	JETCARD_TOP_UP: "JETCARD_TOP_UP",
	REFUNDS: "REFUNDS",
	TIME_RELEASES: "TIME_RELEASES",
	INVOICE_PAYMENT: "INVOICE_PAYMENT",
	FLIGHT_PAYMENT: "FLIGHT_PAYMENT",
	PLATFROM_PAYOUT: "PLATFROM_PAYOUT",
};

export const MODEL_TRANSFER_ACTIVITY_TYPES = {
	INTERNAL_ACTIVITY: "INTERNAL_ACTIVITY",
	WIRE_TRANSFER_ACTIVITY: "WIRE_TRANSFER_ACTIVITY",
	CREDIT_CARD_ACTIVITY: "CREDIT_CARD_ACTIVITY",
	REFUNDS: "REFUNDS",
};

export const JET_CARD_PAYMENT_TYPE = {
	JETCARD_TOP_UP: "JETCARD_TOP_UP",
	FLIGHT_PAYMENT: "FLIGHT_PAYMENT",
	CHARGE_JETCARD: "CHARGE_JETCARD",
	INVOICE_PAYMENT: "INVOICE_PAYMENT",
};

export const TRANSFER_ACTIVITY_TYPES = [
	{
		label: "All Type",
		value: "ALL",
	},
	{
		label: "Internal Activity",
		value: "INTERNAL_ACTIVITY",
	},
	{
		label: "Wire Transfer Activity",
		value: "WIRE_TRANSFER_ACTIVITY",
	},
	{
		label: "Credit Card Activity",
		value: "CREDIT_CARD_ACTIVITY",
	},
];

export const NOTIFICATIONS_REFS = {
	// Flight: PATH_DATABASE_FLIGHTS,
	// Booking: PATH_DATABASE_BOOKINGS,
	// Aircraft: PATH_DATABASE_AIRCRAFTS,
	// Conversation: PATH_DATABASE_MESSAGES,
	// CalendarEvent: PATH_BASE,
	// NewsMessage: PATH_BASE,
	Company: PATH_DATABASE_COMPANIES,
	// Invoice: PATH_ADMINISTRATION_INVOICES,
	Location: PATH_DATABASE_LOCATIONS,
	// Order: PATH_BASE,
	// Itinerary: PATH_BASE,
	// QuoteRequest: PATH_BASE,
	User: PATH_DATABASE_USER_ACCOUNTS,
	// AircraftPriceVerification: PATH_DATABASE_AIRCRAFTS,
};

export const GENDERS = [
	{
		label: "Male",
		value: "Male",
	},
	{
		label: "Female",
		value: "Female",
	},
	{
		label: "Other",
		value: "Other",
	},
];

export const AIRCRAFT_TYPES = [
	{
		label: "Vip",
		value: "VIP",
	},
	{
		label: "Air Ambulance",
		value: "AIR_AMBULANCE",
	},
	{
		label: "Airliner",
		value: "AIRLINER",
	},
];

export const JETOS_FEES = [
	{
		key: "level_1",
		title: "< $4,999",
	},
	{
		key: "level_2",
		title: "$5,000 - $9,999",
	},
	{
		key: "level_3",
		title: "$10,000 - $19,999",
	},
	{
		key: "level_4",
		title: "$20,000 - $29,999",
	},
	{
		key: "level_5",
		title: "$30,000 - $39,999",
	},
	{
		key: "level_6",
		title: "$40,000 - $49,999",
	},
	{
		key: "level_7",
		title: "$50,000 - $59,999",
	},
	{
		key: "level_8",
		title: "$60,000 - $69,999",
	},
	{
		key: "level_9",
		title: "$70,000 - $79,999",
	},
	{
		key: "level_10",
		title: "$80,000 - $89,999",
	},
	{
		key: "level_11",
		title: "$90,000 - $99,999",
	},
	{
		key: "level_12",
		title: "$100,000 - $124,999",
	},
	{
		key: "level_13",
		title: "$125,000 - $149,999",
	},
	{
		key: "level_14",
		title: "$150,000 - $174,999",
	},
	{
		key: "level_15",
		title: "$175,000 - $199,999",
	},
	{
		key: "level_16",
		title: "$200,000 - $499,999",
	},
	{
		key: "level_17",
		title: "> $500,000",
	},
];

export const CLASSTYPES = [
	{
		label: "Twin Pistons",
		value: "CLASS_TWIN_PISTONS",
	},
	{
		label: "Turbo Props",
		value: "CLASS_TURBO_PROPS",
	},
	{
		label: "Light",
		value: "CLASS_LIGHT",
	},
	{
		label: "Midsize",
		value: "CLASS_MIDSIZE",
	},
	{
		label: "Heavy",
		value: "CLASS_HEAVY",
	},
	{
		label: "Jumbo",
		value: "CLASS_JUMBO",
	},
	{
		label: "Super Midsize",
		value: "CLASS_SUPER_MIDSIZE",
	},
	{
		label: "Airtaxi",
		value: "CLASS_AIRTAXI",
	},
	{
		label: "Very Light",
		value: "CLASS_VERY_LIGHT",
	},
	{
		label: "Vip Airliner",
		value: "CLASS_VIP_AIRLINER",
	},
	{
		label: "Long Range Jet",
		value: "CLASS_LONG_RANGE_JET",
	},
];

export const COMPANY_TYPES = [
	{
		label: "Broker",
		value: "BROKER",
	},
	{
		label: "Operator",
		value: "OPERATOR",
	},
	{
		label: "Fbo",
		value: "FBO",
	},
	{
		label: "Catering",
		value: "CATERING",
	},
	{
		label: "Maintenance",
		value: "MAINTENANCE",
	},
];

export const ON_PLATFORM = true;

export const ERROR_STATUS = {
	OPEN: "OPEN",
	CLOSED: "CLOSED",
};

export const USER_TYPE = "user";
export const IDENTIFY_TYPE = "identify";

export const UNAUTHORIZED_CODE = 401;

export const STATUS_SUCCESS = 200;

export const BOOKING_STATUS = {
	CREATED: "CREATED",
	CONFIRMED: "CONFIRMED",
	AWAITING_CONFIRMATION: "AWAITING_CONFIRMATION",
	EXECUTED: "EXECUTED",
	EXPIRED: "EXPIRED",
	CANCELLED: "CANCELLED",
};

export const COMPANY_SUGGESTIONS_STATUS = {
	AWAITING_VERIFICATION: "WAITING",
	VERIFIED: "ACCEPTED",
	DECLINED: "REJECTED",
};

export const FLIGHT_MOVEMENT_STATUS = {
	OUTBOUND: "OUTBOUND",
	INBOUND: "INBOUND",
};

export const FLIGHT_STATUS = {
	CREATED: "CREATED",
	CONFIRMED: "CONFIRMED",
	AWAITING_CONFIRMATION: "AWAITING_CONFIRMATION",
	CANCELLED: "CANCELLED",
	EXECUTED: "EXECUTED",
};

export const PAYMENT_STATUS = {
	CREATED: "CREATED",
	PAID: "PAID",
	AWAITING_PAYMENT: "AWAITING_PAYMENT",
	AWAITING_REFUND: "AWAITING_REFUND",
	CANCELLED: "CANCELLED",
};

export const PAYOUT_STATUS = {
	CREATED: "CREATED",
	PAID: "PAID",
	AWAITING_PAYMENT: "AWAITING_PAYMENT",
	CANCELLED: "CANCELLED",
};

export const COMPANY_SUGGESTIONS_PAGE = {
	CONTACT: "CONTACT",
	PROVIDER: "PROVIDER",
	ABOUT: "ABOUT",
	FLEET: "FLEET",
	LOCATIONS: "LOCATION",
	SERVICES: "LOCATION_SERVICE",
	WORLD_WIDE_AIRCRAFT: "WORLD_WIDE_AIRCRAFT",
};

export const MARKETPLACE_REF_TYPES = {
	FLIGHT: "Flight",
	QUOTE_REQUEST: "QuoteRequest",
};

export const FILE_EXTENSION = {
	pdf: "application/pdf",
	imagePNG: "image/png",
	imageJPG: "image/jpg",
	imageJPEG: "image/jpeg",
	imageSVG: "image/svg+xml",
};

export const FILE_ACCEPT = {
	pdf: ".pdf",
	svg: ".svg",
};
export const CONVERSATION_TYPE = {
	CUSTOMER_GENERAL: "CUSTOMER_GENERAL",
	CREW_SUPPORTED_FLIGHT: "CREW_SUPPORTED_FLIGHT",
	COMPANY_SUPPORTED_FLIGHT: "COMPANY_SUPPORTED_FLIGHT",
	SUPPORT: "SUPPORT",
	DISPUTE: "DISPUTE",
	GROUP: "GROUP",
};

export const IDENTITY_TYPE = {
	COMPANY: "COMPANY",
	USER: "USER",
};
export const SUPPORT_CONVERSATION_KEYS = {
	JETOS: "JETOS",
	AVIAN_PAY: "AVIAN_PAY",
};

export const SOCKET_MESSAGE_TYPE = {
	NOTIFICATION: "notification",
	USER_OFFLINE: "user_offline",
	USER_ONLINE: "user_online",
	MESSAGE: "message",
};

export const MESSAGE_TYPE = {
	TEXT: "TEXT",
	IMAGE: "IMAGE",
	FILE: "FILE",
};

export const MESSAGE_REFS = {
	flight: "Flight",
	booking: "Booking",
	dispute: "Dispute",
};

export const UPLOAD_IMAGE_MODELS = {
	CONVERSATIONS: "conversations",
};

export const UPLOAD_IMAGE_TYPE = {
	MAIN: "main",
	ICON: "icons",
	GALLERY: "gallery",
	BACKGROUND: "background",
	GENERAL: "general",
	LEGAL: "legal",
	TERM_AND_CONDITIONS: "terms_and_conditions",
	CHARTER_AGREEMENTS: "charter_agreements",
};
