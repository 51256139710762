import React from "react";
import { Modal as AntdModal } from "antd";
import styled from "styled-components";
import Lottie from "lottie-react";
import lottieAnimation from "assets/jsons/lottie.json";

const Loading = () => {
	return (
		<StyledModal centered visible={true} footer={null} closable={false}>
			<Lottie loop animationData={lottieAnimation} />
		</StyledModal>
	);
};

export default Loading;

const StyledModal = styled(AntdModal)`
	.ant-modal-content {
		box-shadow: none;
		border: 0;
		background: transparent;
	}
	.ant-modal-body {
		padding: 0;
	}
`;
