/* eslint-disable react/prop-types */
import React from "react";

export const IconEditSvg = ({ color = "#19C0FF", ...props }) => {
	return (
		<svg width="14" height="14" viewBox="0 0 24 24" {...props}>
			<g strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" fill="none" stroke={color}>
				<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
				<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
			</g>
		</svg>
	);
};
