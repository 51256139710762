/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import cn from "classnames";

const Typography = ({
	children = "",
	variant = "paragraph",
	align,
	style,
	color,
	top,
	left,
	right,
	bottom,
	gutterBottom,
	decoration,
	fontLato,
	onClick,
	truncate,
}) => {
	let textStyle = { ...style };

	if (color) textStyle.color = color;
	if (top) textStyle.marginTop = top;
	if (left) textStyle.marginLeft = left;
	if (right) textStyle.marginRight = right;
	if (bottom) textStyle.marginBottom = bottom;
	if (fontLato) textStyle.fontFamily = "Lato";
	if (truncate) textStyle = { ...textStyle, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" };

	return (
		<Text
			className={cn({ [`typography--variant-${variant}`]: variant })}
			style={textStyle}
			align={align}
			gutterBottom={gutterBottom}
			decoration={decoration}
			onClick={onClick}
		>
			{children}
		</Text>
	);
};

export default Typography;

const Text = styled.p`
	text-align: ${({ align }) => align};
	margin-bottom: ${({ gutterBottom }) => gutterBottom};
	text-decoration: ${({ decoration }) => decoration};
	padding: 0;
	margin: 0;
	&.typography--variant-avatar {
		color: #19c0ff;
		font-family: AvenirNext-DemiBold;
		font-size: 14px;
	}
	&.typography--variant-avatar-error {
		color: #ffffff;
		font-family: AvenirNext-Medium;
		font-size: 12px;
	}
	&.typography--variant-h0 {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 40px;
	}
	&.typography--variant-h1 {
		color: #121216;
		font-family: AvenirNext-Bold;
		font-size: 36px;
		letter-spacing: 3px;
		line-height: 43px;
	}
	&.typography--variant-h2 {
		color: #0e0e12;
		font-family: AvenirNext-DemiBold;
		font-size: 24px;
		letter-spacing: 1px;
		line-height: 32px;
	}
	&.typography--variant-h3 {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 20px;
		letter-spacing: 0.8px;
		line-height: 32px;
	}
	&.typography--variant-h4 {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 16px;
		line-height: 26px;
	}
	&.typography--variant-h5 {
		color: #0e0e12;
		font-family: AvenirNext-DemiBold;
		font-size: 12px;
		letter-spacing: 0.7px;
		line-height: 16px;
	}
	&.typography--variant-h6 {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 14px;
		letter-spacing: 0.8px;
		line-height: 19px;
	}
	&.typography--variant-sider {
		color: #cdd1d8;
		font-family: AvenirNext-DemiBold;
		font-size: 11px;
		letter-spacing: 0.7px;
		line-height: 24px;
	}
	&.typography--variant-p {
		color: #65686f;
		font-family: AvenirNext-Medium;
		font-size: 14px;
		line-height: 24px;
	}
	&.typography--variant-small {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 14px;
		letter-spacing: 0.3px;
		line-height: 17px;
	}
	&.typography--variant-title1 {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 18px;
		letter-spacing: 0.8px;
		line-height: 32px;
	}
	&.typography--variant-title2 {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 16px;
		letter-spacing: 0.7px;
		line-height: 32px;
	}
	&.typography--variant-title3 {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 12px;
		line-height: 14px;
	}
	&.typography--variant-subtitle1 {
		color: #6c6f76;
		font-family: AvenirNext-DemiBold;
		font-size: 10px;
		letter-spacing: 0.7px;
		line-height: 12px;
	}
	&.typography--variant-subtitle2 {
		color: #6c6f76;
		font-family: AvenirNext-Medium;
		font-size: 9px;
		letter-spacing: 0.4px;
		line-height: 11px;
	}
	&.typography--variant-subtitle3 {
		color: #19c0ff;
		font-family: AvenirNext-DemiBold;
		font-size: 9px;
		letter-spacing: 1px;
		line-height: 11px;
	}
	&.typography--variant-subtitle4 {
		color: #8e929a;
		font-family: AvenirNext-Medium;
		font-size: 12px;
		line-height: 14px;
	}
	&.typography--variant-subtitle5 {
		color: #65686f;
		font-family: AvenirNext-DemiBold;
		font-size: 10px;
		letter-spacing: 0.7px;
		line-height: 18px;
	}
	&.typography--variant-body1 {
		color: #000000;
		font-family: AvenirNext-Medium;
		font-size: 15px;
		letter-spacing: 0.7px;
		line-height: 18px;
	}
	&.typography--variant-body2 {
		color: #cdd1d8;
		font-family: AvenirNext-DemiBold;
		font-size: 11px;
		letter-spacing: 0.7px;
		line-height: 24px;
	}
	&.typography--variant-body3 {
		color: #8e929a;
		font-family: AvenirNext-Medium;
		font-size: 13px;
		line-height: 40px;
	}
	&.typography--variant-body4 {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 13px;
		letter-spacing: 0.4px;
		line-height: 16px;
	}
	&.typography--variant-body5 {
		color: #65686f;
		font-family: AvenirNext-Medium;
		font-size: 13px;
		line-height: 16px;
	}
	&.typography--variant-body6 {
		color: #65686f;
		font-family: AvenirNext-Medium;
		font-size: 13px;
		line-height: 24px;
	}
	&.typography--variant-paragraph {
		color: #65686f;
		font-family: AvenirNext-Regular;
		font-size: 13px;
		line-height: 24px;
	}
	&.typography--variant-paragraphSm {
		color: #65686f;
		font-family: AvenirNext-Medium;
		font-size: 11px;
		line-height: 16px;
	}
	&.typography--variant-tabTitle {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 11px;
		line-height: 13px;
	}
	&.typography--variant-tag {
		color: #19c0ff;
		font-family: AvenirNext-DemiBold;
		font-size: 9px;
		line-height: 14px;
	}
	&.typography--variant-text1 {
		color: #121216;
		font-family: AvenirNext-DemiBold;
		font-size: 8px;
	}
	&.typography--variant-filename {
		color: #121216;
		font-family: Lato;
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		overflow: hidden;
		word-break: break-word;
	}
	&.typography--variant-message {
		color: #65686f;
		font-family: Lato;
		font-weight: 400;
		font-size: 12px;
		line-height: 22px;
		overflow: hidden;
		word-break: break-word;
	}
	&.typography--variant-last-message {
		color: #65686f;
		font-family: Lato;
		font-weight: 500;
		font-size: 12px;
		line-height: 22px;
	}
	&.typography--variant-last-message-unseen {
		color: #0e0e12;
		font-family: Lato;
		font-weight: 600;
		font-size: 12px;
		line-height: 22px;
	}
`;
