import React from "react";

export const IconJetSvg = (props) => (
	<svg width={14} height={13} xmlns="http://www.w3.org/2000/svg" {...props}>
		<title>{"md-airplane"}</title>
		<path
			d="M4.5 12.5h1.25L9 7.5h3.525c.553 0 .975-.463.975-1 0-.537-.422-1-.975-1H9L5.75.5H4.5l1.5 5H2.45L1.475 4H.5L1 6.5.5 9h.975l.975-1.5H6l-1.5 5Z"
			fill="#19C0FF"
			fillRule="nonzero"
		/>
	</svg>
);
