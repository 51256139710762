const timeZones = [
	{ value: "Pacific/Kiritimati", label: "(+14:00) Pacific - Kiritimati" },
	{ value: "Pacific/Enderbury", label: "(+13:00) Pacific - Enderbury" },
	{ value: "Pacific/Fakaofo", label: "(+13:00) Pacific - Fakaofo" },
	{ value: "Pacific/Tongatapu", label: "(+13:00) Pacific - Tongatapu" },
	{ value: "Pacific/Apia", label: "(+13:00) Pacific - Apia" },
	{ value: "Pacific/Chatham", label: "(+12:45) Pacific - Chatham" },
	{ value: "Pacific/Auckland", label: "(+12:00) Pacific - Auckland" },
	{ value: "Pacific/Fiji", label: "(+12:00) Pacific - Fiji" },
	{ value: "Pacific/Tarawa", label: "(+12:00) Pacific - Tarawa" },
	{ value: "Pacific/Majuro", label: "(+12:00) Pacific - Majuro" },
	{ value: "Pacific/Kwajalein", label: "(+12:00) Pacific - Kwajalein" },
	{ value: "Pacific/Nauru", label: "(+12:00) Pacific - Nauru" },
	{ value: "Asia/Kamchatka", label: "(+12:00) Asia - Kamchatka" },
	{ value: "Asia/Anadyr", label: "(+12:00) Asia - Anadyr" },
	{ value: "Pacific/Funafuti", label: "(+12:00) Pacific - Funafuti" },
	{ value: "Pacific/Wake", label: "(+12:00) Pacific - Wake" },
	{ value: "Pacific/Wallis", label: "(+12:00) Pacific - Wallis" },
	{ value: "Antarctica/Macquarie", label: "(+11:00) Antarctica - Macquarie" },
	{ value: "Pacific/Pohnpei", label: "(+11:00) Pacific - Pohnpei" },
	{ value: "Pacific/Kosrae", label: "(+11:00) Pacific - Kosrae" },
	{ value: "Pacific/Noumea", label: "(+11:00) Pacific - Noumea" },
	{ value: "Pacific/Norfolk", label: "(+11:00) Pacific - Norfolk" },
	{ value: "Pacific/Bougainville", label: "(+11:00) Pacific - Bougainville" },
	{ value: "Asia/Magadan", label: "(+11:00) Asia - Magadan" },
	{ value: "Asia/Sakhalin", label: "(+11:00) Asia - Sakhalin" },
	{ value: "Asia/Srednekolymsk", label: "(+11:00) Asia - Srednekolymsk" },
	{ value: "Pacific/Guadalcanal", label: "(+11:00) Pacific - Guadalcanal" },
	{ value: "Pacific/Efate", label: "(+11:00) Pacific - Efate" },
	{ value: "Australia/Lord_Howe", label: "(+10:30) Australia - Lord Howe" },
	{ value: "Antarctica/DumontDUrville", label: "(+10:00) Antarctica - Dumont D'Urville" },
	{ value: "Australia/Hobart", label: "(+10:00) Australia - Hobart" },
	{ value: "Australia/Currie", label: "(+10:00) Australia - Currie" },
	{ value: "Australia/Melbourne", label: "(+10:00) Australia - Melbourne" },
	{ value: "Australia/Sydney", label: "(+10:00) Australia - Sydney" },
	{ value: "Australia/Brisbane", label: "(+10:00) Australia - Brisbane" },
	{ value: "Australia/Lindeman", label: "(+10:00) Australia - Lindeman" },
	{ value: "Pacific/Chuuk", label: "(+10:00) Pacific - Chuuk" },
	{ value: "Pacific/Guam", label: "(+10:00) Pacific - Guam" },
	{ value: "Pacific/Port_Moresby", label: "(+10:00) Pacific - Port Moresby" },
	{ value: "Asia/Vladivostok", label: "(+10:00) Asia - Vladivostok" },
	{ value: "Asia/Ust-Nera", label: "(+10:00) Asia - Ust-Nera" },
	{ value: "Australia/Broken_Hill", label: "(+09:30) Australia - Broken Hill" },
	{ value: "Australia/Adelaide", label: "(+09:30) Australia - Adelaide" },
	{ value: "Australia/Darwin", label: "(+09:30) Australia - Darwin" },
	{ value: "Asia/Jayapura", label: "(+09:00) Asia - Jayapura" },
	{ value: "Asia/Tokyo", label: "(+09:00) Asia - Tokyo" },
	{ value: "Asia/Pyongyang", label: "(+09:00) Asia - Pyongyang" },
	{ value: "Asia/Seoul", label: "(+09:00) Asia - Seoul" },
	{ value: "Pacific/Palau", label: "(+09:00) Pacific - Palau" },
	{ value: "Asia/Chita", label: "(+09:00) Asia - Chita" },
	{ value: "Asia/Yakutsk", label: "(+09:00) Asia - Yakutsk" },
	{ value: "Asia/Khandyga", label: "(+09:00) Asia - Khandyga" },
	{ value: "Asia/Dili", label: "(+09:00) Asia - Dili" },
	{ value: "Australia/Eucla", label: "(+08:45) Australia - Eucla" },
	{ value: "Antarctica/Casey", label: "(+08:00) Antarctica - Casey" },
	{ value: "Australia/Perth", label: "(+08:00) Australia - Perth" },
	{ value: "Asia/Brunei", label: "(+08:00) Asia - Brunei" },
	{ value: "Asia/Shanghai", label: "(+08:00) Asia - Shanghai" },
	{ value: "Asia/Hong_Kong", label: "(+08:00) Asia - Hong Kong" },
	{ value: "Asia/Makassar", label: "(+08:00) Asia - Makassar" },
	{ value: "Asia/Ulaanbaatar", label: "(+08:00) Asia - Ulaanbaatar" },
	{ value: "Asia/Choibalsan", label: "(+08:00) Asia - Choibalsan" },
	{ value: "Asia/Macau", label: "(+08:00) Asia - Macau" },
	{ value: "Asia/Kuala_Lumpur", label: "(+08:00) Asia - Kuala Lumpur" },
	{ value: "Asia/Kuching", label: "(+08:00) Asia - Kuching" },
	{ value: "Asia/Manila", label: "(+08:00) Asia - Manila" },
	{ value: "Asia/Irkutsk", label: "(+08:00) Asia - Irkutsk" },
	{ value: "Asia/Singapore", label: "(+08:00) Asia - Singapore" },
	{ value: "Asia/Taipei", label: "(+08:00) Asia - Taipei" },
	{ value: "Antarctica/Davis", label: "(+07:00) Antarctica - Davis" },
	{ value: "Indian/Christmas", label: "(+07:00) Indian - Christmas" },
	{ value: "Asia/Jakarta", label: "(+07:00) Asia - Jakarta" },
	{ value: "Asia/Pontianak", label: "(+07:00) Asia - Pontianak" },
	{ value: "Asia/Bangkok", label: "(+07:00) Asia - Bangkok" },
	{ value: "Asia/Hovd", label: "(+07:00) Asia - Hovd" },
	{ value: "Asia/Novosibirsk", label: "(+07:00) Asia - Novosibirsk" },
	{ value: "Asia/Barnaul", label: "(+07:00) Asia - Barnaul" },
	{ value: "Asia/Tomsk", label: "(+07:00) Asia - Tomsk" },
	{ value: "Asia/Novokuznetsk", label: "(+07:00) Asia - Novokuznetsk" },
	{ value: "Asia/Krasnoyarsk", label: "(+07:00) Asia - Krasnoyarsk" },
	{ value: "Asia/Ho_Chi_Minh", label: "(+07:00) Asia - Ho Chi Minh" },
	{ value: "Indian/Cocos", label: "(+06:30) Indian - Cocos" },
	{ value: "Asia/Yangon", label: "(+06:30) Asia - Yangon" },
	{ value: "Antarctica/Vostok", label: "(+06:00) Antarctica - Vostok" },
	{ value: "Asia/Dhaka", label: "(+06:00) Asia - Dhaka" },
	{ value: "Asia/Thimphu", label: "(+06:00) Asia - Thimphu" },
	{ value: "Asia/Urumqi", label: "(+06:00) Asia - Urumqi" },
	{ value: "Indian/Chagos", label: "(+06:00) Indian - Chagos" },
	{ value: "Asia/Bishkek", label: "(+06:00) Asia - Bishkek" },
	{ value: "Asia/Almaty", label: "(+06:00) Asia - Almaty" },
	{ value: "Asia/Qostanay", label: "(+06:00) Asia - Qostanay" },
	{ value: "Asia/Omsk", label: "(+06:00) Asia - Omsk" },
	{ value: "Asia/Kathmandu", label: "(+05:45) Asia - Kathmandu" },
	{ value: "Asia/Kolkata", label: "(+05:30) Asia - Kolkata" },
	{ value: "Asia/Colombo", label: "(+05:30) Asia - Colombo" },
	{ value: "Antarctica/Mawson", label: "(+05:00) Antarctica - Mawson" },
	{ value: "Asia/Qyzylorda", label: "(+05:00) Asia - Qyzylorda" },
	{ value: "Asia/Aqtobe", label: "(+05:00) Asia - Aqtobe" },
	{ value: "Asia/Aqtau", label: "(+05:00) Asia - Aqtau" },
	{ value: "Asia/Atyrau", label: "(+05:00) Asia - Atyrau" },
	{ value: "Asia/Oral", label: "(+05:00) Asia - Oral" },
	{ value: "Indian/Maldives", label: "(+05:00) Indian - Maldives" },
	{ value: "Asia/Karachi", label: "(+05:00) Asia - Karachi" },
	{ value: "Asia/Yekaterinburg", label: "(+05:00) Asia - Yekaterinburg" },
	{ value: "Indian/Kerguelen", label: "(+05:00) Indian - Kerguelen" },
	{ value: "Asia/Dushanbe", label: "(+05:00) Asia - Dushanbe" },
	{ value: "Asia/Ashgabat", label: "(+05:00) Asia - Ashgabat" },
	{ value: "Asia/Samarkand", label: "(+05:00) Asia - Samarkand" },
	{ value: "Asia/Tashkent", label: "(+05:00) Asia - Tashkent" },
	{ value: "Asia/Kabul", label: "(+04:30) Asia - Kabul" },
	{ value: "Asia/Dubai", label: "(+04:00) Asia - Dubai" },
	{ value: "Asia/Yerevan", label: "(+04:00) Asia - Yerevan" },
	{ value: "Asia/Baku", label: "(+04:00) Asia - Baku" },
	{ value: "Asia/Tbilisi", label: "(+04:00) Asia - Tbilisi" },
	{ value: "Indian/Mauritius", label: "(+04:00) Indian - Mauritius" },
	{ value: "Indian/Reunion", label: "(+04:00) Indian - Reunion" },
	{ value: "Europe/Astrakhan", label: "(+04:00) Europe - Astrakhan" },
	{ value: "Europe/Volgograd", label: "(+04:00) Europe - Volgograd" },
	{ value: "Europe/Saratov", label: "(+04:00) Europe - Saratov" },
	{ value: "Europe/Ulyanovsk", label: "(+04:00) Europe - Ulyanovsk" },
	{ value: "Europe/Samara", label: "(+04:00) Europe - Samara" },
	{ value: "Indian/Mahe", label: "(+04:00) Indian - Mahe" },
	{ value: "Asia/Tehran", label: "(+03:30) Asia - Tehran" },
	{ value: "Antarctica/Syowa", label: "(+03:00) Antarctica - Syowa" },
	{ value: "Asia/Qatar", label: "(+03:00) Asia - Qatar" },
	{ value: "Europe/Minsk", label: "(+03:00) Europe - Minsk" },
	{ value: "Africa/Nairobi", label: "(+03:00) Africa - Nairobi" },
	{ value: "Asia/Baghdad", label: "(+03:00) Asia - Baghdad" },
	{ value: "Asia/Riyadh", label: "(+03:00) Asia - Riyadh" },
	{ value: "Europe/Moscow", label: "(+03:00) Europe - Moscow" },
	{ value: "Europe/Simferopol", label: "(+03:00) Europe - Simferopol" },
	{ value: "Europe/Kirov", label: "(+03:00) Europe - Kirov" },
	{ value: "Africa/Juba", label: "(+03:00) Africa - Juba" },
	{ value: "Europe/Istanbul", label: "(+03:00) Europe - Istanbul" },
	{ value: "Europe/Helsinki", label: "(+02:00) Europe - Helsinki" },
	{ value: "Europe/Sofia", label: "(+02:00) Europe - Sofia" },
	{ value: "Africa/Maputo", label: "(+02:00) Africa - Maputo" },
	{ value: "Asia/Nicosia", label: "(+02:00) Asia - Nicosia" },
	{ value: "Asia/Famagusta", label: "(+02:00) Asia - Famagusta" },
	{ value: "Europe/Tallinn", label: "(+02:00) Europe - Tallinn" },
	{ value: "Africa/Cairo", label: "(+02:00) Africa - Cairo" },
	{ value: "Europe/Athens", label: "(+02:00) Europe - Athens" },
	{ value: "Asia/Jerusalem", label: "(+02:00) Asia - Jerusalem" },
	{ value: "Asia/Amman", label: "(+02:00) Asia - Amman" },
	{ value: "Asia/Beirut", label: "(+02:00) Asia - Beirut" },
	{ value: "Africa/Johannesburg", label: "(+02:00) Africa - Johannesburg" },
	{ value: "Europe/Vilnius", label: "(+02:00) Europe - Vilnius" },
	{ value: "Europe/Riga", label: "(+02:00) Europe - Riga" },
	{ value: "Africa/Tripoli", label: "(+02:00) Africa - Tripoli" },
	{ value: "Europe/Chisinau", label: "(+02:00) Europe - Chisinau" },
	{ value: "Africa/Windhoek", label: "(+02:00) Africa - Windhoek" },
	{ value: "Asia/Gaza", label: "(+02:00) Asia - Gaza" },
	{ value: "Asia/Hebron", label: "(+02:00) Asia - Hebron" },
	{ value: "Europe/Bucharest", label: "(+02:00) Europe - Bucharest" },
	{ value: "Europe/Kaliningrad", label: "(+02:00) Europe - Kaliningrad" },
	{ value: "Africa/Khartoum", label: "(+02:00) Africa - Khartoum" },
	{ value: "Asia/Damascus", label: "(+02:00) Asia - Damascus" },
	{ value: "Europe/Kiev", label: "(+02:00) Europe - Kiev" },
	{ value: "Europe/Uzhgorod", label: "(+02:00) Europe - Uzhgorod" },
	{ value: "Europe/Zaporozhye", label: "(+02:00) Europe - Zaporozhye" },
	{ value: "Europe/Andorra", label: "(+01:00) Europe - Andorra" },
	{ value: "Europe/Tirane", label: "(+01:00) Europe - Tirane" },
	{ value: "Africa/Lagos", label: "(+01:00) Africa - Lagos" },
	{ value: "Europe/Vienna", label: "(+01:00) Europe - Vienna" },
	{ value: "Europe/Belgrade", label: "(+01:00) Europe - Belgrade" },
	{ value: "Europe/Brussels", label: "(+01:00) Europe - Brussels" },
	{ value: "Europe/Zurich", label: "(+01:00) Europe - Zurich" },
	{ value: "Europe/Prague", label: "(+01:00) Europe - Prague" },
	{ value: "Europe/Berlin", label: "(+01:00) Europe - Berlin" },
	{ value: "Europe/Copenhagen", label: "(+01:00) Europe - Copenhagen" },
	{ value: "Africa/Algiers", label: "(+01:00) Africa - Algiers" },
	{ value: "Africa/El_Aaiun", label: "(+01:00) Africa - El Aaiun" },
	{ value: "Europe/Madrid", label: "(+01:00) Europe - Madrid" },
	{ value: "Africa/Ceuta", label: "(+01:00) Africa - Ceuta" },
	{ value: "Europe/Paris", label: "(+01:00) Europe - Paris" },
	{ value: "Europe/Gibraltar", label: "(+01:00) Europe - Gibraltar" },
	{ value: "Europe/Budapest", label: "(+01:00) Europe - Budapest" },
	{ value: "Europe/Dublin", label: "(+01:00) Europe - Dublin" },
	{ value: "Europe/Rome", label: "(+01:00) Europe - Rome" },
	{ value: "Europe/Luxembourg", label: "(+01:00) Europe - Luxembourg" },
	{ value: "Africa/Casablanca", label: "(+01:00) Africa - Casablanca" },
	{ value: "Europe/Monaco", label: "(+01:00) Europe - Monaco" },
	{ value: "Europe/Malta", label: "(+01:00) Europe - Malta" },
	{ value: "Europe/Amsterdam", label: "(+01:00) Europe - Amsterdam" },
	{ value: "Europe/Oslo", label: "(+01:00) Europe - Oslo" },
	{ value: "Europe/Warsaw", label: "(+01:00) Europe - Warsaw" },
	{ value: "Europe/Stockholm", label: "(+01:00) Europe - Stockholm" },
	{ value: "Africa/Ndjamena", label: "(+01:00) Africa - Ndjamena" },
	{ value: "Africa/Tunis", label: "(+01:00) Africa - Tunis" },
	{ value: "Antarctica/Troll", label: "(+00:00) Antarctica - Troll" },
	{ value: "Africa/Abidjan", label: "(+00:00) Africa - Abidjan" },
	{ value: "Atlantic/Canary", label: "(+00:00) Atlantic - Canary" },
	{ value: "Atlantic/Faroe", label: "(+00:00) Atlantic - Faroe" },
	{ value: "Europe/London", label: "(+00:00) Europe - London" },
	{ value: "Africa/Accra", label: "(+00:00) Africa - Accra" },
	{ value: "America/Danmarkshavn", label: "(+00:00) America - Danmarkshavn" },
	{ value: "Africa/Bissau", label: "(+00:00) Africa - Bissau" },
	{ value: "Atlantic/Reykjavik", label: "(+00:00) Atlantic - Reykjavik" },
	{ value: "Africa/Monrovia", label: "(+00:00) Africa - Monrovia" },
	{ value: "Europe/Lisbon", label: "(+00:00) Europe - Lisbon" },
	{ value: "Atlantic/Madeira", label: "(+00:00) Atlantic - Madeira" },
	{ value: "Africa/Sao_Tome", label: "(+00:00) Africa - Sao Tome" },
	{ value: "Atlantic/Cape_Verde", label: "(-01:00) Atlantic - Cape Verde" },
	{ value: "America/Scoresbysund", label: "(-01:00) America - Scoresbysund" },
	{ value: "Atlantic/Azores", label: "(-01:00) Atlantic - Azores" },
	{ value: "America/Noronha", label: "(-02:00) America - Noronha" },
	{ value: "Atlantic/South_Georgia", label: "(-02:00) Atlantic - South Georgia" },
	{ value: "Antarctica/Palmer", label: "(-03:00) Antarctica - Palmer" },
	{ value: "Antarctica/Rothera", label: "(-03:00) Antarctica - Rothera" },
	{ value: "America/Argentina/Buenos_Aires", label: "(-03:00) America - Buenos Aires, Argentina" },
	{ value: "America/Argentina/Cordoba", label: "(-03:00) America - Cordoba, Argentina" },
	{ value: "America/Argentina/Salta", label: "(-03:00) America - Salta, Argentina" },
	{ value: "America/Argentina/Jujuy", label: "(-03:00) America - Jujuy, Argentina" },
	{ value: "America/Argentina/Tucuman", label: "(-03:00) America - Tucuman, Argentina" },
	{ value: "America/Argentina/Catamarca", label: "(-03:00) America - Catamarca, Argentina" },
	{ value: "America/Argentina/La_Rioja", label: "(-03:00) America - La Rioja, Argentina" },
	{ value: "America/Argentina/San_Juan", label: "(-03:00) America - San Juan, Argentina" },
	{ value: "America/Argentina/Mendoza", label: "(-03:00) America - Mendoza, Argentina" },
	{ value: "America/Argentina/San_Luis", label: "(-03:00) America - San Luis, Argentina" },
	{ value: "America/Argentina/Rio_Gallegos", label: "(-03:00) America - Rio Gallegos, Argentina" },
	{ value: "America/Argentina/Ushuaia", label: "(-03:00) America - Ushuaia, Argentina" },
	{ value: "America/Belem", label: "(-03:00) America - Belem" },
	{ value: "America/Fortaleza", label: "(-03:00) America - Fortaleza" },
	{ value: "America/Recife", label: "(-03:00) America - Recife" },
	{ value: "America/Araguaina", label: "(-03:00) America - Araguaina" },
	{ value: "America/Maceio", label: "(-03:00) America - Maceio" },
	{ value: "America/Bahia", label: "(-03:00) America - Bahia" },
	{ value: "America/Sao_Paulo", label: "(-03:00) America - Sao Paulo" },
	{ value: "America/Santarem", label: "(-03:00) America - Santarem" },
	{ value: "America/Punta_Arenas", label: "(-03:00) America - Punta Arenas" },
	{ value: "Atlantic/Stanley", label: "(-03:00) Atlantic - Stanley" },
	{ value: "America/Cayenne", label: "(-03:00) America - Cayenne" },
	{ value: "America/Godthab", label: "(-03:00) America - Godthab" },
	{ value: "America/Miquelon", label: "(-03:00) America - Miquelon" },
	{ value: "America/Paramaribo", label: "(-03:00) America - Paramaribo" },
	{ value: "America/Montevideo", label: "(-03:00) America - Montevideo" },
	{ value: "America/St_Johns", label: "(-03:30) America - St Johns" },
	{ value: "America/Port_of_Spain", label: "(-04:00) America - Port of Spain" },
	{ value: "America/Curacao", label: "(-04:00) America - Curacao" },
	{ value: "America/Barbados", label: "(-04:00) America - Barbados" },
	{ value: "Atlantic/Bermuda", label: "(-04:00) Atlantic - Bermuda" },
	{ value: "America/La_Paz", label: "(-04:00) America - La Paz" },
	{ value: "America/Campo_Grande", label: "(-04:00) America - Campo Grande" },
	{ value: "America/Cuiaba", label: "(-04:00) America - Cuiaba" },
	{ value: "America/Porto_Velho", label: "(-04:00) America - Porto Velho" },
	{ value: "America/Boa_Vista", label: "(-04:00) America - Boa Vista" },
	{ value: "America/Manaus", label: "(-04:00) America - Manaus" },
	{ value: "America/Halifax", label: "(-04:00) America - Halifax" },
	{ value: "America/Glace_Bay", label: "(-04:00) America - Glace Bay" },
	{ value: "America/Moncton", label: "(-04:00) America - Moncton" },
	{ value: "America/Goose_Bay", label: "(-04:00) America - Goose Bay" },
	{ value: "America/Blanc-Sablon", label: "(-04:00) America - Blanc-Sablon" },
	{ value: "America/Santiago", label: "(-04:00) America - Santiago" },
	{ value: "America/Santo_Domingo", label: "(-04:00) America - Santo Domingo" },
	{ value: "America/Thule", label: "(-04:00) America - Thule" },
	{ value: "America/Guyana", label: "(-04:00) America - Guyana" },
	{ value: "America/Martinique", label: "(-04:00) America - Martinique" },
	{ value: "America/Puerto_Rico", label: "(-04:00) America - Puerto Rico" },
	{ value: "America/Asuncion", label: "(-04:00) America - Asuncion" },
	{ value: "America/Caracas", label: "(-04:00) America - Caracas" },
	{ value: "America/Eirunepe", label: "(-05:00) America - Eirunepe" },
	{ value: "America/Rio_Branco", label: "(-05:00) America - Rio Branco" },
	{ value: "America/Nassau", label: "(-05:00) America - Nassau" },
	{ value: "America/Toronto", label: "(-05:00) America - Toronto" },
	{ value: "America/Nipigon", label: "(-05:00) America - Nipigon" },
	{ value: "America/Thunder_Bay", label: "(-05:00) America - Thunder Bay" },
	{ value: "America/Iqaluit", label: "(-05:00) America - Iqaluit" },
	{ value: "America/Pangnirtung", label: "(-05:00) America - Pangnirtung" },
	{ value: "America/Atikokan", label: "(-05:00) America - Atikokan" },
	{ value: "America/Bogota", label: "(-05:00) America - Bogota" },
	{ value: "America/Havana", label: "(-05:00) America - Havana" },
	{ value: "America/Guayaquil", label: "(-05:00) America - Guayaquil" },
	{ value: "America/Port-au-Prince", label: "(-05:00) America - Port-au-Prince" },
	{ value: "America/Jamaica", label: "(-05:00) America - Jamaica" },
	{ value: "America/Panama", label: "(-05:00) America - Panama" },
	{ value: "America/Cancun", label: "(-05:00) America - Cancun" },
	{ value: "America/Lima", label: "(-05:00) America - Lima" },
	{ value: "America/Grand_Turk", label: "(-05:00) America - Grand Turk" },
	{ value: "America/New_York", label: "(-05:00) America - New York" },
	{ value: "America/Detroit", label: "(-05:00) America - Detroit" },
	{ value: "America/Kentucky/Louisville", label: "(-05:00) America - Louisville, Kentucky" },
	{ value: "America/Kentucky/Monticello", label: "(-05:00) America - Monticello, Kentucky" },
	{ value: "America/Indiana/Indianapolis", label: "(-05:00) America - Indianapolis, Indiana" },
	{ value: "America/Indiana/Vincennes", label: "(-05:00) America - Vincennes, Indiana" },
	{ value: "America/Indiana/Winamac", label: "(-05:00) America - Winamac, Indiana" },
	{ value: "America/Indiana/Marengo", label: "(-05:00) America - Marengo, Indiana" },
	{ value: "America/Indiana/Petersburg", label: "(-05:00) America - Petersburg, Indiana" },
	{ value: "America/Indiana/Vevay", label: "(-05:00) America - Vevay, Indiana" },
	{ value: "America/Belize", label: "(-06:00) America - Belize" },
	{ value: "America/Winnipeg", label: "(-06:00) America - Winnipeg" },
	{ value: "America/Rainy_River", label: "(-06:00) America - Rainy River" },
	{ value: "America/Resolute", label: "(-06:00) America - Resolute" },
	{ value: "America/Rankin_Inlet", label: "(-06:00) America - Rankin Inlet" },
	{ value: "America/Regina", label: "(-06:00) America - Regina" },
	{ value: "America/Swift_Current", label: "(-06:00) America - Swift Current" },
	{ value: "Pacific/Easter", label: "(-06:00) Pacific - Easter" },
	{ value: "America/Costa_Rica", label: "(-06:00) America - Costa Rica" },
	{ value: "Pacific/Galapagos", label: "(-06:00) Pacific - Galapagos" },
	{ value: "America/Guatemala", label: "(-06:00) America - Guatemala" },
	{ value: "America/Tegucigalpa", label: "(-06:00) America - Tegucigalpa" },
	{ value: "America/Mexico_City", label: "(-06:00) America - Mexico City" },
	{ value: "America/Merida", label: "(-06:00) America - Merida" },
	{ value: "America/Monterrey", label: "(-06:00) America - Monterrey" },
	{ value: "America/Matamoros", label: "(-06:00) America - Matamoros" },
	{ value: "America/Bahia_Banderas", label: "(-06:00) America - Bahia Banderas" },
	{ value: "America/Managua", label: "(-06:00) America - Managua" },
	{ value: "America/El_Salvador", label: "(-06:00) America - El Salvador" },
	{ value: "America/Chicago", label: "(-06:00) America - Chicago" },
	{ value: "America/Indiana/Tell_City", label: "(-06:00) America - Tell City, Indiana" },
	{ value: "America/Indiana/Knox", label: "(-06:00) America - Knox, Indiana" },
	{ value: "America/Menominee", label: "(-06:00) America - Menominee" },
	{ value: "America/North_Dakota/Center", label: "(-06:00) America - Center, North Dakota" },
	{ value: "America/North_Dakota/New_Salem", label: "(-06:00) America - New Salem, North Dakota" },
	{ value: "America/North_Dakota/Beulah", label: "(-06:00) America - Beulah, North Dakota" },
	{ value: "America/Edmonton", label: "(-07:00) America - Edmonton" },
	{ value: "America/Cambridge_Bay", label: "(-07:00) America - Cambridge Bay" },
	{ value: "America/Yellowknife", label: "(-07:00) America - Yellowknife" },
	{ value: "America/Inuvik", label: "(-07:00) America - Inuvik" },
	{ value: "America/Creston", label: "(-07:00) America - Creston" },
	{ value: "America/Dawson_Creek", label: "(-07:00) America - Dawson Creek" },
	{ value: "America/Fort_Nelson", label: "(-07:00) America - Fort Nelson" },
	{ value: "America/Mazatlan", label: "(-07:00) America - Mazatlan" },
	{ value: "America/Chihuahua", label: "(-07:00) America - Chihuahua" },
	{ value: "America/Ojinaga", label: "(-07:00) America - Ojinaga" },
	{ value: "America/Hermosillo", label: "(-07:00) America - Hermosillo" },
	{ value: "America/Denver", label: "(-07:00) America - Denver" },
	{ value: "America/Boise", label: "(-07:00) America - Boise" },
	{ value: "America/Phoenix", label: "(-07:00) America - Phoenix" },
	{ value: "America/Vancouver", label: "(-08:00) America - Vancouver" },
	{ value: "America/Whitehorse", label: "(-08:00) America - Whitehorse" },
	{ value: "America/Dawson", label: "(-08:00) America - Dawson" },
	{ value: "America/Tijuana", label: "(-08:00) America - Tijuana" },
	{ value: "Pacific/Pitcairn", label: "(-08:00) Pacific - Pitcairn" },
	{ value: "America/Los_Angeles", label: "(-08:00) America - Los Angeles" },
	{ value: "Pacific/Gambier", label: "(-09:00) Pacific - Gambier" },
	{ value: "America/Anchorage", label: "(-09:00) America - Anchorage" },
	{ value: "America/Juneau", label: "(-09:00) America - Juneau" },
	{ value: "America/Sitka", label: "(-09:00) America - Sitka" },
	{ value: "America/Metlakatla", label: "(-09:00) America - Metlakatla" },
	{ value: "America/Yakutat", label: "(-09:00) America - Yakutat" },
	{ value: "America/Nome", label: "(-09:00) America - Nome" },
	{ value: "Pacific/Marquesas", label: "(-09:30) Pacific - Marquesas" },
	{ value: "Pacific/Rarotonga", label: "(-10:00) Pacific - Rarotonga" },
	{ value: "Pacific/Tahiti", label: "(-10:00) Pacific - Tahiti" },
	{ value: "Pacific/Honolulu", label: "(-10:00) Pacific - Honolulu" },
	{ value: "America/Adak", label: "(-10:00) America - Adak" },
	{ value: "Pacific/Pago_Pago", label: "(-11:00) Pacific - Pago Pago" },
	{ value: "Pacific/Niue", label: "(-11:00) Pacific - Niue" },
];

export default timeZones;
