/* eslint-disable react/prop-types */
import React from "react";

export const IconPlusSvg = ({ color = "#8E929A", strokeWidth = "2", ...props }) => {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 24 24"
			fill="currentColor"
			stroke="currentColor"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			color={color}
			{...props}
		>
			<line x1="12" y1="0" x2="12" y2="24"></line>
			<line x1="0" y1="12" x2="24" y2="12"></line>
		</svg>
	);
};
