/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

export const IconPricingSvg = ({ color = "#19C0FF", size = 32, includeBorder = false, ...props }) => {
	return (
		<Container includeBorder={includeBorder} size={`${size}px`}>
			<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
				<path
					d="M6.88369 8.75033V4.30642C7.83433 4.46677 8.7307 4.85685 9.49704 5.44264C9.64981 5.54741 9.82907 5.60424 10.0147 5.60674C10.6093 5.60674 11.0941 5.12826 11.1004 4.53365C11.1016 4.27864 11.0019 4.03372 10.8226 3.85192C9.71544 2.90379 8.32672 2.34452 6.87108 2.2612V0.77146C6.87108 0.345988 6.52642 0.00133577 6.10095 0.00133577C6.09212 8.5757e-05 6.08326 8.5757e-05 6.07443 8.5757e-05C5.64267 -0.00624243 5.28665 0.338448 5.28032 0.77146V2.21069C2.43974 2.41269 0.508162 4.16755 0.508162 6.50312C0.508162 9.36893 2.94475 10.1643 5.28032 10.7955V15.8455C4.04812 15.6801 2.88666 15.1688 1.93474 14.3684C1.75548 14.2257 1.53325 14.1462 1.30348 14.1412C0.721485 14.1816 0.273316 14.6689 0.280855 15.2521C0.279605 15.5071 0.379333 15.7521 0.558592 15.9339C1.86275 17.108 3.53932 17.7834 5.2929 17.8402V19.2289C5.2929 19.2378 5.29415 19.2466 5.29415 19.2555C5.31434 19.6872 5.68173 20.0205 6.11349 19.9991C6.53896 19.9991 6.88362 19.6544 6.88362 19.2289V17.815C10.3302 17.5878 11.7189 15.492 11.7189 13.2701C11.719 10.2906 9.2193 9.38158 6.88369 8.75033ZM5.29297 8.34634C3.91687 7.94235 2.84377 7.52575 2.84377 6.35163C2.84377 5.17752 3.81589 4.33165 5.29297 4.21806V8.34634ZM6.88369 15.8707V11.2753C8.31031 11.6793 9.42126 12.2222 9.40864 13.5478C9.40864 14.5073 8.75215 15.6435 6.88369 15.8707Z"
					fill={color}
				/>
			</svg>
		</Container>
	);
};

const Container = styled.div`
	background: ${({ includeBorder }) => (includeBorder ? "#f0f3f9" : "transparent")};
	display: flex;
	border-radius: 50%;
	width: ${({ includeBorder, size }) => (includeBorder ? size : "fit-content")};
	height: ${({ includeBorder, size }) => (includeBorder ? size : "fit-content")};
	justify-content: center;
	align-items: center;
`;
