import { combineActiontype } from "utils/helpers";

const page = "signIn";

export const signInActions = combineActiontype(page, {
	FETCH_GET_USER_SUCCESS: "FETCH_GET_USER_SUCCESS",
	FETCH_GET_USER_FAILURE: "FETCH_GET_USER_FAILURE",

	FETCH_UPDATE_ACCESS_TOKEN: "FETCH_UPDATE_ACCESS_TOKEN",
});

export const fetchGetUserSuccess = (user) => ({
	type: signInActions.FETCH_GET_USER_SUCCESS,
	payload: user,
});

export const fetchGetUserFailure = (error) => ({
	type: signInActions.FETCH_GET_USER_FAILURE,
	payload: error,
});

export const fetchUpdateAccessToken = (payload) => ({
	type: signInActions.FETCH_UPDATE_ACCESS_TOKEN,
	payload,
});
