/* eslint-disable react/prop-types */
import React from "react";

export const IconChartSvg = ({ color = "#65686F", ...props }) => {
	return (
		<svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-325.000000, -15.000000)" fill={color} fillRule="nonzero">
					<g transform="translate(294.000000, 0.000000)">
						<g transform="translate(31.000000, 15.000000)">
							<path d="M3.5433821,13.9130435 L0.630530939,13.9130435 C0.282326533,13.9130435 0,13.6396569 0,13.3025876 L0,6.52349937 C0,6.18638121 0.282326533,5.91304348 0.630530939,5.91304348 L3.5433821,5.91304348 C3.89158651,5.91304348 4.17391304,6.18638121 4.17391304,6.52349937 L4.17391304,13.3026364 C4.17391304,13.6398034 3.89158651,13.9130435 3.5433821,13.9130435 L3.5433821,13.9130435 Z M1.26106188,12.6921317 L2.91285117,12.6921317 L2.91285117,7.13395526 L1.26106188,7.13395526 L1.26106188,12.6921317 Z"></path>
							<path d="M9.45642558,13.9130435 L6.54357442,13.9130435 C6.19531957,13.9130435 5.91304348,13.6386181 5.91304348,13.300268 L5.91304348,0.612775512 C5.91304348,0.274376363 6.19531957,0 6.54357442,0 L9.45642558,0 C9.80457955,0 10.0869565,0.274376363 10.0869565,0.612775512 L10.0869565,13.300317 C10.0869565,13.6387652 9.80457955,13.9130435 9.45642558,13.9130435 Z M7.17410536,12.6874925 L8.82589464,12.6874925 L8.82589464,1.22555102 L7.17410536,1.22555102 L7.17410536,12.6874925 Z"></path>
							<path d="M15.3694691,13.9130435 L12.4566179,13.9130435 C12.108363,13.9130435 11.826087,13.6380384 11.826087,13.2989735 L11.826087,2.35320043 C11.826087,2.01408641 12.108363,1.73913043 12.4566179,1.73913043 L15.3694691,1.73913043 C15.7176735,1.73913043 16,2.01408641 16,2.35320043 L16,13.2990226 C16,13.6381857 15.7176735,13.9130435 15.3694691,13.9130435 Z M13.0871488,12.6849035 L14.7389381,12.6849035 L14.7389381,2.96727042 L13.0871488,2.96727042 L13.0871488,12.6849035 Z"></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
