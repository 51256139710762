import produce from "immer";
import { adInvoicesActions } from "./actions";

const INITIAL_STATE = {
	invoices: {},
	error: null,
};

const adInvoicesReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case adInvoicesActions.FETCH_AD_INVOICES_SUCCESS:
			draft.invoices = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default adInvoicesReducer;
