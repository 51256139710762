/* eslint-disable react/prop-types */
import React from "react";

export const IconAirportSvg = ({ color = "#8E929A", ...props }) => {
	return (
		<svg width="14px" height="18px" viewBox="0 0 18 23" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-40.000000, -524.000000)" fill={color} fillRule="nonzero">
					<g transform="translate(0.000000, 78.000000)">
						<g transform="translate(0.000000, 424.383787)">
							<g transform="translate(40.042877, 21.616213)">
								<path d="M9.0975566,0.0050945121 C9.07256977,0.00168234444 9.04737911,0 9.02216038,0 L8.96977358,0.000934134743 L8.88588679,0 C8.86041509,0 8.83553774,0.00169828569 8.811,0.0050945121 C3.94666981,0.117934135 0,4.14916998 0,9.02216055 C0,15.3842266 7.17019811,21.3311039 8.60739623,22.4585662 C8.70620938,22.5362578 8.82828302,22.5783682 8.95398113,22.5783682 C9.07633019,22.5783682 9.19893396,22.5384624 9.30056604,22.4585662 C10.737934,21.3311039 17.9077925,15.3842266 17.9077925,9.02216055 C17.9077925,4.14916998 13.9619717,0.118103946 9.0975566,0.0050945121 Z M8.95406604,21.2978209 C7.04784906,19.7430285 1.12296226,14.4707266 1.12296226,9.02241527 C1.12296226,4.73680206 4.60927358,1.19470772 8.89471698,1.12678319 C8.90966038,1.12661338 8.92426415,1.12584923 8.93869811,1.12432093 L8.95406604,1.12432093 L8.96883962,1.12432093 C8.98361321,1.12559451 8.99838679,1.12652847 9.01341509,1.12678319 C13.2986887,1.19470772 16.785,4.73680206 16.785,9.02241527 C16.7846604,14.4706417 10.8601132,19.7430285 8.95406604,21.2978209 Z"></path>
								<path d="M14.3691792,5.74607564 C14.3822547,5.27688696 14.2104906,4.83928319 13.885217,4.51400961 C13.5726792,4.20104734 13.1523113,4.02885866 12.7023113,4.02885866 C12.2267547,4.02885866 11.7762453,4.21768885 11.4202358,4.57429263 L10.0461226,6.04299074 L4.94516038,4.51570772 L3.84316981,5.61727376 C3.53173585,5.93100017 3.53266981,6.44043413 3.84503774,6.75263225 L3.90141509,6.80917942 L7.46796226,8.77211338 L6.11626415,10.1471605 L4.50721698,9.81866055 L3.75503774,10.5708398 C3.615247,10.7100988 3.53687449,10.8994277 3.53733756,11.0967455 C3.53733756,11.2956794 3.6150283,11.4828964 3.75554717,11.6228209 L6.79882075,14.6660945 C6.93797662,14.8060597 7.12735797,14.8845151 7.32472642,14.8839677 C7.52221577,14.8847058 7.71174068,14.8061551 7.85080189,14.6659247 L8.60323585,13.9142549 L8.26675472,12.2741322 L9.63433019,10.9256605 L11.6132264,14.5209058 L11.6701132,14.5777926 C11.8206305,14.7283142 12.0249264,14.8126482 12.2377925,14.8121322 C12.4509405,14.8126421 12.65545,14.7279342 12.8058113,14.5768587 L13.9061038,13.476736 L12.3689717,8.34138696 L13.8381792,6.96608508 C14.167783,6.63656621 14.3564434,6.20329263 14.3691792,5.74607564 L14.3691792,5.74607564 Z M13.0575566,6.15914168 L11.093434,7.9969247 L12.6378679,13.1569813 L12.3194717,13.4753775 L9.90246226,9.08414168 L7.04428302,11.9028398 L7.38169811,13.5478021 L7.32498113,13.6043492 L4.81754717,11.0967455 L4.87426415,11.0400285 L6.48950943,11.3696322 L9.3074434,8.50287753 L4.94634906,6.10225489 L5.26508491,5.78368885 L10.3909245,7.31827376 L12.2275189,5.35474546 C12.3583585,5.22390583 12.5269811,5.15182093 12.7023113,5.15182093 C12.8522547,5.15182093 12.9903962,5.20717942 13.0908396,5.30787753 C13.1958679,5.41290583 13.2508868,5.55716055 13.2465566,5.71440583 C13.2418868,5.88362281 13.1698868,6.04613225 13.0575566,6.15914168 Z"></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
