import produce from "immer";
import { adBackOfficeUsersActions } from "./actions";

const INITIAL_STATE = {
	backOfficeUsers: {},
	backOfficeUserDetail: {},
};

const adBackOfficeUsersReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case adBackOfficeUsersActions.FETCH_BACK_OFFICE_USERS_SUCCESS:
			draft.backOfficeUsers = action.payload;
			break;
		case adBackOfficeUsersActions.FETCH_BACK_OFFICE_USER_DETAIL_SUCCESS:
			draft.backOfficeUserDetail = action.payload;
			break;
		case adBackOfficeUsersActions.FETCH_BLOCK_BACK_OFFICE_USER_SUCCESS:
			draft.backOfficeUserDetail.blocked_at = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default adBackOfficeUsersReducer;
