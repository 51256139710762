import produce from "immer";
import { dbWorldWideAircraftsActions } from "./actions";

const INITIAL_STATE = {
	wwAircrafts: {},
	wwAircraftDetail: {},
};

const dbWorldWideAircraftsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbWorldWideAircraftsActions.FETCH_WORLDWIDE_AIRCRAFTS_SUCCESS:
			draft.wwAircrafts = action.payload;
			break;
		case dbWorldWideAircraftsActions.FETCH_WORLDWIDE_AIRCRAFT_DETAIL_SUCCESS:
			draft.wwAircraftDetail = action.payload;
			break;
		case dbWorldWideAircraftsActions.FETCH_ACTIVATE_WORLDWIDE_AIRCRAFT_SUCCESS:
			draft.wwAircraftDetail.verified_at = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbWorldWideAircraftsReducer;
