import produce from "immer";
import { dbLogsActions } from "./actions";

const INITIAL_STATE = {
	logs: {},
	logDetail: {},
	error: null,
};

const dbLogsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbLogsActions.FETCH_LOGS_SUCCESS:
			draft.logs = action.payload;
			break;
		case dbLogsActions.FETCH_LOG_DETAIL_SUCCESS:
			draft.logDetail = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbLogsReducer;
