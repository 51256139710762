import { combineActiontype } from "utils/helpers";

const page = "adInvoices";

export const adInvoicesActions = combineActiontype(page, {
	FETCH_AD_INVOICES_SUCCESS: "FETCH_AD_INVOICES_SUCCESS",
	FETCH_AD_INVOICES_FAILURE: "FETCH_AD_INVOICES_FAILURE",
});

export const fetchAdInvoicesSuccess = (invoices) => ({
	type: adInvoicesActions.FETCH_AD_INVOICES_SUCCESS,
	payload: invoices,
});

export const fetchAdInvoicesFailure = (error) => ({
	type: adInvoicesActions.FETCH_AD_INVOICES_FAILURE,
	payload: error,
});
