/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

export const IconAircraftSvg = ({ horizontal = false, ...props }) => {
	let style = {
		background: "#19C0FF",
		fill: "#FFFFFF",
		width: "16px",
		height: "18px",
		transform: "none",
	};

	if (horizontal)
		style = {
			background: "transparent",
			fill: "#19C0FF",
			width: "32px",
			height: "34px",
			transform: "rotate(90deg)",
		};

	return (
		<Container background={style.background} transform={style.transform}>
			<svg width={style.width} height={style.height} viewBox="0 0 16 18" {...props} version="1.1">
				<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g transform="translate(-403.000000, -952.000000)" fill={style.fill} fillRule="nonzero">
						<g transform="translate(375.000000, 187.000000)">
							<g transform="translate(20.000000, 758.000000)">
								<g transform="translate(8.000000, 7.000000)">
									<path d="M16,12 L16,10.3333333 L9.33333333,6 L9.33333333,1.3 C9.33333333,0.5625 8.71666667,0 8,0 C7.28333333,0 6.66666667,0.5625 6.66666667,1.3 L6.66666667,6 L0,10.3333333 L0,12 L6.66666667,10 L6.66666667,14.7333333 L4.66666667,16.0333333 L4.66666667,17.3333333 L8,16.6666667 L11.3333333,17.3333333 L11.3333333,16.0333333 L9.33333333,14.7333333 L9.33333333,10 L16,12 Z"></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</Container>
	);
};

const Container = styled.div`
	width: 34px;
	height: 34px;
	background: ${({ background }) => background};
	transform: ${({ transform }) => transform};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
`;
