import React from "react";

export const IconCreditCardSvg = (props) => (
	<svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={16} cy={16} r={16} fill="#E7F7ED" />
		<path
			d="M24 13.094v-.844a1.75 1.75 0 0 0-1.75-1.75H9.75A1.75 1.75 0 0 0 8 12.25v.844c0 .086.07.156.156.156h15.688c.086 0 .156-.07.156-.156ZM8 14.406v5.344c0 .966.784 1.75 1.75 1.75h12.5A1.75 1.75 0 0 0 24 19.75v-5.344a.156.156 0 0 0-.156-.156H8.156a.156.156 0 0 0-.156.156Zm4 4.094a.5.5 0 0 1-.5.5H11a.5.5 0 0 1-.5-.5V18a.5.5 0 0 1 .5-.5h.5a.5.5 0 0 1 .5.5v.5Z"
			fill="#12B04A"
		/>
	</svg>
);
