import { combineActiontype } from "utils/helpers";

const page = "apAccounts";

export const apAccountsActions = combineActiontype(page, {
	FETCH_AP_ACCOUNTS_SUCCESS: "FETCH_AP_ACCOUNTS_SUCCESS",
	FETCH_AP_ACCOUNTS_FAILURE: "FETCH_AP_ACCOUNTS_FAILURE",
});

export const fetchApAccountsSuccess = (accounts) => ({
	type: apAccountsActions.FETCH_AP_ACCOUNTS_SUCCESS,
	payload: accounts,
});

export const fetchApAccountsFailure = (error) => ({
	type: apAccountsActions.FETCH_AP_ACCOUNTS_FAILURE,
	payload: error,
});
