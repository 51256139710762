/* eslint-disable react/prop-types */
import * as React from "react";

export const IconMarketplaceSvg = ({ ...props }) => {
	return (
		<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clipPath="url(#a)">
				<path
					d="m18.895 7.54-1.84-2.453.072-.502a.527.527 0 0 0-.149-.447l-1.119-1.12L16.98 1.9a.527.527 0 0 0-.747-.746l-1.118 1.12-1.119-1.12a.528.528 0 0 0-.447-.149l-5.22.746a.527.527 0 0 0-.3.15L1.566 8.363a1.936 1.936 0 0 0 0 2.734l5.47 5.47c.111.111.234.206.363.285v.213c0 1.067.868 1.934 1.934 1.934h7.734A1.936 1.936 0 0 0 19 17.066v-9.21a.527.527 0 0 0-.105-.317ZM2.31 10.351a.88.88 0 0 1 0-1.243l6.34-6.339 4.785-.683.932.932-.746.746-.373-.373a.527.527 0 0 0-.745.746l1.491 1.491a.526.526 0 0 0 .746 0 .527.527 0 0 0 0-.746l-.373-.373.746-.745.932.932-.684 4.785-6.339 6.34a.873.873 0 0 1-.621.257.873.873 0 0 1-.622-.258l-5.469-5.469Zm15.634 6.714a.88.88 0 0 1-.879.88H9.332a.88.88 0 0 1-.876-.815c.477-.013.95-.2 1.313-.564l6.463-6.463a.528.528 0 0 0 .15-.299l.463-3.241 1.1 1.467v9.035Z"
					fill="#A9AEB6"
					stroke="#A9AEB6"
					strokeWidth={0.2}
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h20v20H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};
