import { combineActiontype } from "utils/helpers";

const page = "mnGenericAircrafts";

export const mnGenericAircraftsActions = combineActiontype(page, {
	FETCH_GENERIC_AIRCRAFTS_SUCCESS: "FETCH_GENERIC_AIRCRAFTS_SUCCESS",
	FETCH_GENERIC_AIRCRAFTS_FAILURE: "FETCH_GENERIC_AIRCRAFTS_FAILURE",

	FETCH_GENERIC_AIRCRAFT_DETAIL_SUCCESS: "FETCH_GENERIC_AIRCRAFT_DETAIL_SUCCESS",
	FETCH_GENERIC_AIRCRAFT_DETAIL_FAILURE: "FETCH_GENERIC_AIRCRAFT_DETAIL_FAILURE",
});

export const fetchGenericAircraftsSuccess = (genericAircrafts) => ({
	type: mnGenericAircraftsActions.FETCH_GENERIC_AIRCRAFTS_SUCCESS,
	payload: genericAircrafts,
});

export const fetchGenericAircraftsFailure = (error) => ({
	type: mnGenericAircraftsActions.FETCH_GENERIC_AIRCRAFTS_FAILURE,
	payload: error,
});

export const fetchGenericAircraftDetailSuccess = (genericAircraft) => ({
	type: mnGenericAircraftsActions.FETCH_GENERIC_AIRCRAFT_DETAIL_SUCCESS,
	payload: genericAircraft,
});

export const fetchGenericAircraftDetailFailure = (error) => ({
	type: mnGenericAircraftsActions.FETCH_GENERIC_AIRCRAFT_DETAIL_FAILURE,
	payload: error,
});
