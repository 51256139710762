import React from "react";

export const IconTotalBalanceSvg = (props) => (
	<svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.945 15.769c-.199-3.244-2.278-6.088-5.348-7.332L20.955 3a.047.047 0 0 0 .013-.025 1.26 1.26 0 0 0-.531-1.732 1.295 1.295 0 0 0-.915-.118l-1.049.237a16.644 16.644 0 0 1-6.435.175l-3.103-.519c-.71-.118-1.388.35-1.51 1.044a1.22 1.22 0 0 0 .148.831l3.25 5.613c-2.995 1.269-4.997 4.081-5.189 7.263l-.633 10.575c-.02.343.25.637.595.656h17.336a.63.63 0 0 0 .64-.619v-.037l-.627-10.575ZM8.679 2.295c0-.012 0-.019.007-.031.012-.013.019-.013.025-.013l3.103.52c2.303.387 4.663.324 6.94-.188l1.043-.238c.013-.006.026 0 .032.013.005.008.006.01.006.014v.004L16.42 7.901a.582.582 0 0 0-.064.144 12.14 12.14 0 0 0-.46-.1l.002-.008a.039.039 0 0 0 .004-.017l.531-2.037a.617.617 0 0 0-.46-.757c-.34-.08-.691.12-.774.45l-.525 2.044c-.02.063-.02.125-.02.188-.12-.007-.242-.007-.364-.007-.083 0-.172 0-.256.007L12.978 4.57a.64.64 0 0 0-.8-.412.621.621 0 0 0-.421.786c0 .002.002.004.005.007l.973 2.988c-.23.044-.454.087-.685.15L8.68 2.295ZM6.914 15.843l-.595 9.913H22.26l-.595-9.913c-.198-3.156-2.463-5.819-5.604-6.581a7.352 7.352 0 0 0-1.33-.2c-4.075-.238-7.574 2.8-7.817 6.781Z"
			fill="#8E929A"
		/>
		<path
			d="M14.75 17.115c-.58 0-1.046-.441-1.046-.978 0-.549.466-.984 1.046-.984.58 0 1.046.441 1.046.978 0 .328.287.596.638.596.35 0 .638-.268.638-.596 0-.972-.69-1.819-1.684-2.087v-.376c0-.328-.287-.596-.638-.596-.35 0-.638.268-.638.596v.376c-1.23.328-1.945 1.532-1.594 2.683.287.93 1.199 1.575 2.232 1.575.58 0 1.046.441 1.046.978 0 .537-.465.984-1.046.984-.58 0-1.046-.441-1.046-.978 0-.328-.287-.597-.638-.597-.35 0-.637.269-.637.597 0 .972.688 1.819 1.683 2.087v.388c0 .328.287.596.638.596.35 0 .638-.268.638-.596v-.388c1.23-.328 1.945-1.533 1.594-2.684-.287-.93-1.199-1.574-2.232-1.574Z"
			fill="#8E929A"
		/>
	</svg>
);
