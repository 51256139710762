import React from "react";

export const IconBalanceDueSvg = (props) => (
	<svg width={37} height={37} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={18.877} cy={18.5} r={18} fill="#40A1F6" />
		<path
			d="M20.68 22.284a.952.952 0 0 0-.267-.194 3.226 3.226 0 0 0-.677-.238v1.789c.375-.043.806-.179 1.003-.529a.816.816 0 0 0 .075-.558.562.562 0 0 0-.134-.27ZM20.738 23.116l.001-.003-.001.003ZM17.977 19.426a.753.753 0 0 0-.078.662.558.558 0 0 0 .217.238c.1.068.21.122.322.166.094.038.2.074.314.11v-1.62c-.288.062-.603.193-.775.444ZM20.746 23.102l-.007.013.007-.013Z"
			fill="#fff"
		/>
		<path
			d="M20.368 13.352c1.523-1.278 2.541-4.158 1.915-4.284-.833-.167-2.64.567-3.514.696-1.239.15-2.589-1.342-3.346-.51-.616.675.441 3.133 2.096 4.204-4.936 2.423-11.875 14.586.23 15.469 16.748 1.221 8.361-13.214 2.619-15.575Zm1.438 9.592a1.758 1.758 0 0 1-.68 1.194c-.396.308-.895.45-1.39.49v.524a.494.494 0 0 1-.698.448.496.496 0 0 1-.287-.448v-.572a3.213 3.213 0 0 1-.252-.058 2.419 2.419 0 0 1-1.205-.74 2.17 2.17 0 0 1-.433-.791c-.016-.055-.033-.11-.039-.167a.49.49 0 0 1 .534-.54.496.496 0 0 1 .424.336c.019.056.031.114.052.17a1.254 1.254 0 0 0 .292.438c.175.168.396.277.627.342v-1.947c-.452-.117-.918-.268-1.291-.557a1.502 1.502 0 0 1-.436-.524 1.635 1.635 0 0 1-.153-.704 1.677 1.677 0 0 1 .552-1.267c.363-.333.845-.52 1.329-.588v-.543a.494.494 0 0 1 .698-.448c.173.08.287.257.287.447v.541c.064.008.128.018.192.03.475.085.94.281 1.286.623a1.877 1.877 0 0 1 .464.745c.018.057.037.116.046.176a.492.492 0 0 1-.947.248c-.02-.053-.031-.11-.052-.164a.94.94 0 0 0-.313-.399 1.6 1.6 0 0 0-.677-.264v1.863c.285.068.57.142.842.25.412.162.802.41 1.027.803-.035-.061-.069-.12.001.002.069.12.036.063.001.002.18.315.238.692.2 1.05Z"
			fill="#fff"
		/>
		<path d="m20.732 23.127-.007.013a.715.715 0 0 0 .007-.013Z" fill="#fff" />
	</svg>
);
