import { combineActiontype } from "utils/helpers";

const page = "dbSessions";

export const dbSessionsActions = combineActiontype(page, {
	FETCH_SESSIONS_SUCCESS: "FETCH_SESSIONS_SUCCESS",
	FETCH_SESSIONS_FAILURE: "FETCH_SESSIONS_FAILURE",

	FETCH_SESSION_DETAIL_SUCCESS: "FETCH_SESSION_DETAIL_SUCCESS",
	FETCH_SESSION_DETAIL_FAILURE: "FETCH_SESSION_DETAIL_FAILURE",
});

export const fetchSessionsSuccess = (sessions) => ({
	type: dbSessionsActions.FETCH_SESSIONS_SUCCESS,
	payload: sessions,
});

export const fetchSessionsFailure = (error) => ({
	type: dbSessionsActions.FETCH_SESSIONS_FAILURE,
	payload: error,
});

export const fetchSessionDetailSuccess = (session) => ({
	type: dbSessionsActions.FETCH_SESSION_DETAIL_SUCCESS,
	payload: session,
});

export const fetchSessionDetailFailure = (error) => ({
	type: dbSessionsActions.FETCH_SESSION_DETAIL_FAILURE,
	payload: error,
});
