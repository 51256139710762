import produce from "immer";
import { mnGenericAircraftsActions } from "./actions";

const INITIAL_STATE = {
	genericAircrafts: {},
	genericAircraftDetail: {},
	error: null,
};

const mnGenericAircraftsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case mnGenericAircraftsActions.FETCH_GENERIC_AIRCRAFTS_SUCCESS:
			draft.genericAircrafts = action.payload;
			break;
		case mnGenericAircraftsActions.FETCH_GENERIC_AIRCRAFT_DETAIL_SUCCESS:
			draft.genericAircraftDetail = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default mnGenericAircraftsReducer;
