/* eslint-disable react/prop-types */
import React from "react";

export const IconFlashSvg = ({ fill = "#12B04A", stroke = "#ffffff", ...props }) => (
	<svg width={16} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={8} cy={7.5} r={7} fill={fill} stroke={stroke} />
		<g clipPath="url(#a)">
			<path
				d="M7.042 11.979c.119.05.257.008.327-.102l3.164-5.027a.264.264 0 0 0-.223-.405H8.198l.785-3.116a.264.264 0 0 0-.478-.206L5.34 8.15a.264.264 0 0 0 .223.405h2.111L6.89 11.67a.264.264 0 0 0 .152.308Z"
				fill="#fff"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" transform="translate(3 3)" d="M0 0h10v10H0z" />
			</clipPath>
		</defs>
	</svg>
);
