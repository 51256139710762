import { combineActiontype } from "utils/helpers";

const page = "adPayouts";

export const adPayoutsActions = combineActiontype(page, {
	FETCH_AD_PAYOUTS_SUCCESS: "FETCH_AD_PAYOUTS_SUCCESS",
	FETCH_AD_PAYOUTS_FAILURE: "FETCH_AD_PAYOUTS_FAILURE",
});

export const fetchAdPayoutsSuccess = (payouts) => ({
	type: adPayoutsActions.FETCH_AD_PAYOUTS_SUCCESS,
	payload: payouts,
});

export const fetchAdPayoutsFailure = (error) => ({
	type: adPayoutsActions.FETCH_AD_PAYOUTS_FAILURE,
	payload: error,
});
