/* eslint-disable react/prop-types */
import produce from "immer";
import { CONVERSATION_TYPE, MESSAGE_TYPE, SUPPORT_CONVERSATION_KEYS } from "utils/constants";
import { checkTextIsLink } from "utils/helpers";
import { messagesActions } from "./actions";

const INITIAL_STATE = {
	jetOSSupportConversations: {},
	avianPaySupportConversations: {},
	avianPayDisputeConversations: {},
	conversationDetail: {},
	conversationsUnRead: [],
};

const messagesReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		/** jet os support */
		case messagesActions.FETCH_JET_OS_SUPPORT_CONVERSATIONS_SUCCESS:
			if (typeof action.payload === "object") {
				const { docs } = action.payload;
				const docsObject = {};
				docs?.forEach((cs) => {
					docsObject[cs._id] = cs;
				});
				draft.jetOSSupportConversations = { ...action.payload, docs: docsObject };
			}
			break;
		case messagesActions.FETCH_MORE_JET_OS_SUPPORT_CONVERSATIONS_SUCCESS:
			if (typeof action.payload === "object") {
				const { docs, page } = action.payload;
				if (draft.jetOSSupportConversations?.page && page && draft.jetOSSupportConversations?.page !== page) {
					const docsObject = {};
					docs?.forEach((cs) => {
						docsObject[cs._id] = cs;
					});
					const draftDocs = draft.jetOSSupportConversations?.docs ?? {};
					draft.jetOSSupportConversations = { ...action.payload, docs: { ...draftDocs, ...docsObject } };
				}
			}
			break;

		/** avian pay support */
		case messagesActions.FETCH_AVIAN_PAY_SUPPORT_CONVERSATIONS_SUCCESS:
			if (typeof action.payload === "object") {
				const { docs } = action.payload;
				const docsObject = {};
				docs?.forEach((cs) => {
					docsObject[cs._id] = cs;
				});
				draft.avianPaySupportConversations = { ...action.payload, docs: docsObject };
			}
			break;
		case messagesActions.FETCH_MORE_AVIAN_PAY_SUPPORT_CONVERSATIONS_SUCCESS:
			if (typeof action.payload === "object") {
				const { docs, page } = action.payload;
				if (draft.avianPaySupportConversations?.page && page && draft.avianPaySupportConversations?.page !== page) {
					const docsObject = {};
					docs?.forEach((cs) => {
						docsObject[cs._id] = cs;
					});
					const draftDocs = draft.avianPaySupportConversations?.docs ?? {};
					draft.avianPaySupportConversations = { ...action.payload, docs: { ...draftDocs, ...docsObject } };
				}
			}
			break;

		/** avian pay dispute */
		case messagesActions.FETCH_AVIAN_PAY_DISPUTE_CONVERSATIONS_SUCCESS:
			if (typeof action.payload === "object") {
				const { docs } = action.payload;
				const docsObject = {};
				docs?.forEach((cs) => {
					docsObject[cs._id] = cs;
				});
				draft.avianPayDisputeConversations = { ...action.payload, docs: docsObject };
			}
			break;
		case messagesActions.FETCH_MORE_AVIAN_PAY_DISPUTE_CONVERSATIONS_SUCCESS:
			if (typeof action.payload === "object") {
				const { docs, page } = action.payload;
				if (draft.avianPayDisputeConversations?.page && page && draft.avianPayDisputeConversations?.page !== page) {
					const docsObject = {};
					docs?.forEach((cs) => {
						docsObject[cs._id] = cs;
					});
					const draftDocs = draft.avianPayDisputeConversations?.docs ?? {};
					draft.avianPayDisputeConversations = { ...action.payload, docs: { ...draftDocs, ...docsObject } };
				}
			}
			break;

		/** common */
		case messagesActions.FETCH_CONVERSATION_DETAIL_SUCCESS:
			if (typeof action.payload === "object") {
				const info = action.payload.info ?? {};
				const usersObject = {};
				info?._users?.forEach((us) => {
					usersObject[us._id] = us;
				});
				draft.conversationDetail = { ...action.payload, info: { ...info, usersObject } };
			}
			break;
		case messagesActions.FETCH_MORE_CONVERSATION_DETAIL_SUCCESS:
			const { next, docs } = action.payload ?? {};
			if (draft.conversationDetail?.docs && docs?.length > 0) {
				draft.conversationDetail = {
					...draft.conversationDetail,
					docs: [...draft.conversationDetail.docs, ...docs],
					next,
				};
			}
			break;
		case messagesActions.LOCAL_ADD_NEW_MESSAGE:
		case messagesActions.FETCH_SOCKET_NEW_MESSAGE:
			if (typeof action.payload === "object" && action.payload?.conversation_id) {
				const { _id, text, type, url, meta, created_at, seen, _user, conversation_id, info } = action.payload;

				const last_message = { text, type, created_at, seen, _user };

				const jetOSSupport = draft.jetOSSupportConversations?.docs ?? {};
				const avianPaySupport = draft.avianPaySupportConversations?.docs ?? {};
				const avianPayDispute = draft.avianPayDisputeConversations?.docs ?? {};
				if (jetOSSupport[conversation_id] || avianPaySupport[conversation_id] || avianPayDispute[conversation_id]) {
					if (jetOSSupport[conversation_id]) {
						draft.jetOSSupportConversations.docs = {
							...jetOSSupport,
							[conversation_id]: { ...jetOSSupport[conversation_id], last_message },
						};
					}
					if (avianPaySupport[conversation_id]) {
						draft.avianPaySupportConversations.docs = {
							...avianPaySupport,
							[conversation_id]: { ...avianPaySupport[conversation_id], last_message },
						};
					}
					if (avianPayDispute[conversation_id]) {
						draft.avianPayDisputeConversations.docs = {
							...avianPayDispute,
							[conversation_id]: { ...avianPayDispute[conversation_id], last_message },
						};
					}
				} else {
					if (info) {
						const { support_key, type } = info;
						const cs = { _id: conversation_id, ...info, last_message, seen: false };
						if (type === CONVERSATION_TYPE.SUPPORT) {
							if (support_key === SUPPORT_CONVERSATION_KEYS.JETOS) {
								draft.jetOSSupportConversations.docs = { [conversation_id]: cs, ...jetOSSupport };
							}
							if (support_key === SUPPORT_CONVERSATION_KEYS.AVIAN_PAY) {
								draft.avianPaySupportConversations.docs = { [conversation_id]: cs, ...avianPaySupport };
							}
						}
						if (type === CONVERSATION_TYPE.DISPUTE) {
							draft.avianPayDisputeConversations.docs = { [conversation_id]: cs, ...avianPayDispute };
						}
					}
				}

				if (conversation_id === draft.conversationDetail?.info?.conversation_id) {
					const draftDocs = draft.conversationDetail?.docs ?? [];
					const draftInfo = draft.conversationDetail?.info ?? {};
					const draftAssets = draftInfo?.docs ?? {};

					let ms = {};
					if (type === MESSAGE_TYPE.TEXT) {
						const link = checkTextIsLink(text);
						if (link) {
							ms = {
								_id,
								url: link,
							};
							draft.conversationDetail.info = {
								...draftInfo,
								docs: { ...draftAssets, links: [...draftAssets.links, ms] },
							};
						}
					} else {
						ms = {
							_id,
							url,
							type,
							filename: meta.filename,
							size: meta.size,
						};

						if (type === MESSAGE_TYPE.FILE) {
							draft.conversationDetail.info = {
								...draftInfo,
								docs: { ...draftAssets, files: [...draftAssets.files, ms] },
							};
						}
						if (type === MESSAGE_TYPE.IMAGE) {
							draft.conversationDetail.info = {
								...draftInfo,
								docs: { ...draftAssets, images: [...draftAssets.images, ms] },
							};
						}
					}
					draft.conversationDetail = { ...draft.conversationDetail, docs: [action.payload, ...draftDocs] };
				}
			}
			break;
		case messagesActions.LOCAL_UPDATE_MESSAGE:
			if (typeof action.payload === "object") {
				const draftDocs = draft.conversationDetail?.docs ?? [];
				const updateDocs = draftDocs.map((ms) => (ms._id === action.payload._id ? action.payload : ms));
				draft.conversationDetail = { ...draft.conversationDetail, docs: updateDocs };
			}
			break;
		case messagesActions.FETCH_CONVERSATIONS_UNREAD_LIST:
			draft.conversationsUnRead = action.payload?.docs ?? [];
			break;
		default:
			break;
	}
	return draft;
});

export default messagesReducer;
