import React from "react";

export const IconUserSvg = (props) => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7.75 7.744c.927 0 1.73-.332 2.385-.988a3.263 3.263 0 0 0 .988-2.384c0-.926-.332-1.729-.988-2.384A3.264 3.264 0 0 0 7.751 1c-.927 0-1.729.332-2.384.988a3.263 3.263 0 0 0-.988 2.384c0 .926.332 1.729.988 2.384a3.263 3.263 0 0 0 2.384.988ZM13.652 11.764a8.322 8.322 0 0 0-.113-.884 6.964 6.964 0 0 0-.218-.89 4.388 4.388 0 0 0-.366-.83 3.133 3.133 0 0 0-.551-.718 2.432 2.432 0 0 0-.792-.497 2.738 2.738 0 0 0-1.011-.183c-.143 0-.281.058-.548.232-.164.107-.357.231-.571.368a3.272 3.272 0 0 1-.739.326c-.3.097-.603.146-.903.146-.3 0-.604-.05-.904-.146a3.263 3.263 0 0 1-.738-.326c-.212-.135-.405-.26-.571-.368-.267-.174-.405-.232-.548-.232-.364 0-.704.061-1.011.183-.308.122-.575.29-.792.498-.208.199-.394.44-.551.718a4.401 4.401 0 0 0-.366.83c-.088.273-.16.572-.218.889a8.3 8.3 0 0 0-.113.885c-.019.268-.028.546-.028.826 0 .731.232 1.323.69 1.76.453.43 1.052.648 1.78.648h6.74c.729 0 1.327-.218 1.78-.649.458-.436.69-1.027.69-1.759 0-.282-.01-.56-.028-.826Z"
			fill="#8E929A"
		/>
	</svg>
);
