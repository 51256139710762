import { combineActiontype } from "utils/helpers";

const page = "dbLogs";

export const dbLogsActions = combineActiontype(page, {
	FETCH_LOGS_SUCCESS: "FETCH_LOGS_SUCCESS",
	FETCH_LOGS_FAILURE: "FETCH_LOGS_FAILURE",

	FETCH_LOG_DETAIL_SUCCESS: "FETCH_LOG_DETAIL_SUCCESS",
	FETCH_LOG_DETAIL_FAILURE: "FETCH_LOG_DETAIL_FAILURE",
});

export const fetchLogsSuccess = (logs) => ({
	type: dbLogsActions.FETCH_LOGS_SUCCESS,
	payload: logs,
});

export const fetchLogsFailure = (error) => ({
	type: dbLogsActions.FETCH_LOGS_FAILURE,
	payload: error,
});

export const fetchLogDetailSuccess = (log) => ({
	type: dbLogsActions.FETCH_LOG_DETAIL_SUCCESS,
	payload: log,
});

export const fetchLogDetailFailure = (error) => ({
	type: dbLogsActions.FETCH_LOG_DETAIL_FAILURE,
	payload: error,
});
