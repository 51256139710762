import produce from "immer";
import { dbGenericSidebarsActions } from "./actions";

const INITIAL_STATE = {
	genericSidebars: {},
};

const dbGenericSidebarsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbGenericSidebarsActions.FETCH_GENERIC_SIDEBARS_SUCCESS:
			draft.genericSidebars = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbGenericSidebarsReducer;
