/* eslint-disable react/prop-types */
import React from "react";

export const IconClockSvg = () => {
	return (
		<svg width="18px" height="18px" enableBackground="new 0 0 128 128" viewBox="0 0 128 128">
			<path
				fill="#DD4558"
				d="M125.8,62.3l-0.3-4.7c-0.2-1.5-0.5-3.1-0.7-4.6c-0.2-1.5-0.6-3-1.1-4.5c-0.4-1.5-0.8-3-1.4-4.5
				c-2-5.9-5.1-11.4-8.9-16.3c-3.8-4.9-8.2-9.3-13.3-12.7C95,11.6,89.4,8.8,83.6,7C79.8,5.9,75.9,5.2,72,4.8
				c-3.5-0.3-6.5,2.4-6.5,5.9c0,3,2.2,5.5,5.2,5.9c3.1,0.4,6.2,1.1,9.2,2.1c4.6,1.6,8.9,3.9,12.8,6.8c3.9,2.8,7.3,6.4,10.1,10.3
				c2.8,3.9,5.1,8.2,6.4,12.8c0.4,1.1,0.7,2.3,0.9,3.5c0.3,1.2,0.6,2.3,0.7,3.5c0.1,1.2,0.3,2.4,0.4,3.5l0.1,3.6
				c-0.1,4.8-0.7,9.5-2.2,14c-1.5,4.5-3.6,8.7-6.3,12.5c-2.7,3.8-6.1,7.1-9.8,9.9l-1.4,1c-0.5,0.3-0.9,0.7-1.5,1l-3,1.8
				c-2.1,1-4.2,2-6.4,2.7c-4.4,1.5-9,2.4-13.6,2.3c-9.3,0.2-18.4-2.7-25.9-7.8c-3.7-2.6-7-5.8-9.8-9.4l-2-2.8c-0.6-1-1.1-2-1.7-2.9
				c-0.6-0.9-1-2-1.5-3l-0.7-1.5c-0.2-0.5-0.4-1.1-0.6-1.6l-0.5-1.6c-0.2-0.5-0.4-1-0.5-1.6l-0.8-3.3c-0.2-1.1-0.3-2.2-0.5-3.3
				c-0.1-0.6-0.2-1.1-0.2-1.7l0-1.7c0-1.1-0.1-2.2,0-3.4c0.1-1.1,0.1-2.2,0.3-3.3c0.3-2.2,0.7-4.4,1.3-6.5L17.3,41L4,47.4
				c-0.8,3.2-1.3,6.4-1.6,9.7c-0.1,1.6-0.2,3.3-0.2,4.9c0,1.6,0.1,3.3,0.2,4.9l0.2,2.4c0,0.8,0.2,1.6,0.3,2.4
				c0.3,1.6,0.5,3.2,0.9,4.8l1.3,4.7c0.2,0.8,0.5,1.6,0.8,2.3l0.9,2.3c0.3,0.8,0.6,1.5,0.9,2.3l1.1,2.2c0.7,1.4,1.4,2.9,2.3,4.3
				c0.9,1.4,1.7,2.8,2.6,4.1l3,3.9c4.2,5,9,9.3,14.4,12.8c10.9,6.9,23.9,10.5,36.7,9.7l2.4-0.2c0.8-0.1,1.6-0.1,2.4-0.2l4.7-0.8
				c0.8-0.1,1.6-0.4,2.3-0.6l2.3-0.6c0.8-0.2,1.5-0.4,2.3-0.7l2.2-0.8c3-1,5.8-2.5,8.6-4l4-2.5c0.7-0.4,1.3-0.9,1.9-1.4l1.9-1.5
				c4.9-4,9.3-8.6,12.8-13.9c3.5-5.3,6.1-11,7.9-17.1C125.3,74.9,125.9,68.6,125.8,62.3z"
			/>
			<path
				fill="#DD4558"
				d="M64.8,76.5c7.5,0.4,13.7-5.6,13.7-13c0-4.7-2.5-8.8-6.2-11.1l-2-19C70,30.9,67.9,29,65.5,29h0l0,0
					c-2.5,0-4.6,1.9-4.8,4.3l-2,19c-4,2.5-6.6,7.1-6.2,12.3C53.1,71,58.4,76.2,64.8,76.5z M63,57.7c0.7-0.3,1.6-0.5,2.4-0.5
					s1.7,0.2,2.4,0.5c2.3,0.9,3.8,3.2,3.8,5.8c0,3.4-2.8,6.3-6.3,6.3c-3.4,0-6.3-2.8-6.3-6.3C59.2,60.8,60.8,58.6,63,57.7z"
			/>
		</svg>
	);
};
