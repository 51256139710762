/* eslint-disable react/prop-types */
import React from "react";

export const IconSignOutSvg = ({ color = "#65686F", ...props }) => {
	return (
		<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-1587.000000, -398.000000)" fill={color} fillRule="nonzero">
					<g transform="translate(1565.000000, 80.000000)">
						<g transform="translate(0.000000, 318.000000)">
							<g transform="translate(22.000000, 0.000000)">
								<polygon points="11.9593929 10.4243929 12.7675 11.2325 16 8 12.7675 4.7675 11.9593571 5.57564286 13.8123214 7.42857143 5.71428571 7.42857143 5.71428571 8.57142857 13.8123214 8.57142857"></polygon>
								<path d="M12.8389643,12.8487857 C11.5438929,14.1438929 9.82207143,14.8571429 7.99057143,14.8571429 C6.15889286,14.8571429 4.43678571,14.1438929 3.14157143,12.84875 C1.84632143,11.5536071 1.14285714,9.83160714 1.14285714,8 C1.14285714,6.16853571 1.84632143,4.44660714 3.14160714,3.15139286 C4.43685714,1.85617857 6.15892857,1.14285714 7.99057143,1.14285714 C9.82196429,1.14285714 11.5438214,1.85617857 12.8389643,3.15135714 C12.9287627,3.24136687 13.0160927,3.33380591 13.1008571,3.42857143 L14.5556429,3.42857143 C13.1100357,1.35614286 10.7087857,0 7.99057143,0 C3.57217857,0 0,3.58207143 0,8 C0,12.4183929 3.57217857,16 7.99057143,16 C10.7088571,16 13.1102143,14.6439286 14.5556429,12.5714286 L13.101,12.5714286 C13.016207,12.6662593 12.9288289,12.7587465 12.8389643,12.8487857 Z"></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
