import React from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "antd/dist/antd.variable.min.css";
import "./index.css";
import "swiper/css/bundle";

import { store, persistor } from "configs/configureStore";
import ErrorBoundary from "pages/ErrorBoundary";
import AntdConfigProvider from "configs/AntdConfigProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Loading from "components/Loading";

createRoot(document.getElementById("root")).render(
	<ReduxProvider store={store}>
		<PersistGate loading={<Loading />} persistor={persistor}>
			<BrowserRouter>
				<AntdConfigProvider>
					<ErrorBoundary>
						<App />
					</ErrorBoundary>
				</AntdConfigProvider>
			</BrowserRouter>
		</PersistGate>
	</ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
