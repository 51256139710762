import React from "react";

export const IconCompleteSvg = (props) => (
	<svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#a)">
			<rect width={18} height={18} rx={9} fill="#7ED321" />
			<path
				d="M7.555 12.352c-.116.115-.28.212-.424.212-.145 0-.309-.101-.43-.217L4 9.645l.859-.858 2.277 2.277L13.156 5l.844.873-6.445 6.479Z"
				fill="#fff"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h18v18H0z" />
			</clipPath>
		</defs>
	</svg>
);
