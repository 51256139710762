import React from "react";

export const IconChevronDownCircleSvg = ({ ...props }) => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle cx="9" cy="9" r="8" transform="rotate(-180 9 9)" fill="white" stroke="#DADADB" strokeWidth="0.7" />
			<path d="M6.6075 7.5L6 8.0888L9 11L12 8.0888L11.3925 7.5L9 9.82057L6.6075 7.5Z" fill="#65686F" />
		</svg>
	);
};
