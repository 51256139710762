import { combineActiontype } from "utils/helpers";

const page = "mnAircraftCategories";

export const mnAircraftCategoriesActions = combineActiontype(page, {
	FETCH_AIRCRAFT_CATEGORIES_SUCCESS: "FETCH_AIRCRAFT_CATEGORIES_SUCCESS",
	FETCH_AIRCRAFT_CATEGORIES_FAILURE: "FETCH_AIRCRAFT_CATEGORIES_FAILURE",

	FETCH_AIRCRAFT_CATEGORY_DETAIL_SUCCESS: "FETCH_AIRCRAFT_CATEGORY_DETAIL_SUCCESS",
	FETCH_AIRCRAFT_CATEGORY_DETAIL_FAILURE: "FETCH_AIRCRAFT_CATEGORY_DETAIL_FAILURE",
});

export const fetchAircraftCategoriesSuccess = (aircraftCategories) => ({
	type: mnAircraftCategoriesActions.FETCH_AIRCRAFT_CATEGORIES_SUCCESS,
	payload: aircraftCategories,
});

export const fetchAircraftCategoriesFailure = (error) => ({
	type: mnAircraftCategoriesActions.FETCH_AIRCRAFT_CATEGORIES_FAILURE,
	payload: error,
});

export const fetchAircraftCategotyDetailSuccess = (aircraft) => ({
	type: mnAircraftCategoriesActions.FETCH_AIRCRAFT_CATEGORY_DETAIL_SUCCESS,
	payload: aircraft,
});

export const fetchAircraftCategotyDetailFailure = (error) => ({
	type: mnAircraftCategoriesActions.FETCH_AIRCRAFT_CATEGORY_DETAIL_FAILURE,
	payload: error,
});
