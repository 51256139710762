/* eslint-disable react/prop-types */
import React from "react";

export const IconEmailSvg = ({ color = "#A9AEB6", ...props }) => {
	return (
		<svg width="22" height="19" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M6 21C5.88975 21 5.77875 20.9753 5.6745 20.9257C5.41575 20.8005 5.25 20.5387 5.25 20.25V16.5H3C1.7595 16.5 0.75 15.4905 0.75 14.25V2.25C0.75 1.0095 1.7595 0 3 0H21C22.2405 0 23.25 1.0095 23.25 2.25V14.25C23.25 15.4905 22.2405 16.5 21 16.5H11.8883L6.46875 20.8358C6.333 20.9445 6.16725 21 6 21ZM3 1.5C2.586 1.5 2.25 1.83675 2.25 2.25V14.25C2.25 14.6632 2.586 15 3 15H6C6.41475 15 6.75 15.3352 6.75 15.75V18.69L11.1562 15.1642C11.2898 15.0577 11.454 15 11.625 15H21C21.414 15 21.75 14.6632 21.75 14.25V2.25C21.75 1.83675 21.414 1.5 21 1.5H3Z"
				fill={color}
			/>
			<path
				d="M18 7.5H6C5.58525 7.5 5.25 7.164 5.25 6.75C5.25 6.336 5.58525 6 6 6H18C18.4148 6 18.75 6.336 18.75 6.75C18.75 7.164 18.4148 7.5 18 7.5Z"
				fill={color}
			/>
			<path
				d="M12 10.5H6C5.58525 10.5 5.25 10.164 5.25 9.75C5.25 9.336 5.58525 9 6 9H12C12.4148 9 12.75 9.336 12.75 9.75C12.75 10.164 12.4148 10.5 12 10.5Z"
				fill={color}
			/>
		</svg>
	);
};
