import produce from "immer";
import { dbSessionsActions } from "./actions";

const INITIAL_STATE = {
	sessions: {},
	sessionDetail: {},
	error: null,
};

const dbSessionsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbSessionsActions.FETCH_SESSIONS_SUCCESS:
			draft.sessions = action.payload;
			break;
		case dbSessionsActions.FETCH_SESSION_DETAIL_SUCCESS:
			draft.sessionDetail = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbSessionsReducer;
