/* eslint-disable react/prop-types */
import React from "react";

export const IconProviderSvg = ({ ...props }) => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle cx="16" cy="16" r="16" fill="#F0F3F9" />
			<g clipPath="url(#clip0_4956_244916)">
				<path
					d="M23.9355 14.3253L21.0917 9.47962C20.9613 9.25747 20.6761 9.18212 20.453 9.31093L18.7487 10.2949L17.2136 9.73025C17.1571 9.70947 17.0999 9.69203 17.0422 9.67737C16.61 9.5675 16.1519 9.62915 15.7609 9.85487L13.2233 11.32C12.6628 11.6436 12.4708 12.3603 12.7944 12.9208C13.118 13.4813 13.8347 13.6733 14.3952 13.3497L17.1798 11.742C17.4699 11.5745 17.8272 11.5745 18.1173 11.742C18.1173 11.742 23.7275 14.9822 23.7455 14.9933L23.8291 14.9245C24.007 14.778 24.0521 14.524 23.9355 14.3253Z"
					fill="#19C0FF"
				/>
				<path
					d="M23.2226 15.7727L17.6486 12.5545L14.864 14.1622C14.539 14.3499 14.1782 14.4457 13.8127 14.4457C13.6295 14.4457 13.4451 14.4216 13.2634 14.373C12.7191 14.2271 12.2642 13.8781 11.9825 13.3901C11.7008 12.9022 11.626 12.3338 11.7718 11.7895C11.8922 11.3402 12.1514 10.9521 12.5126 10.6718L11.3633 10.0082C11.1341 9.87589 10.8409 9.95939 10.7158 10.1926L8.05574 15.152C7.93609 15.3751 8.01518 15.653 8.23446 15.7795L8.47812 15.9202L8.90577 15.1794C9.26056 14.5649 9.92202 14.1831 10.632 14.1831C10.9804 14.1831 11.3245 14.2755 11.6271 14.4502C11.8012 14.5507 11.9565 14.6746 12.0899 14.8175C12.3054 14.7388 12.5356 14.6965 12.7713 14.6965C13.1197 14.6965 13.4638 14.7889 13.7664 14.9636C14.2272 15.2297 14.5569 15.6593 14.6946 16.1733C14.7098 16.2298 14.7219 16.2866 14.7321 16.3436C14.9237 16.3879 15.1089 16.4604 15.2807 16.5596C15.7415 16.8256 16.0712 17.2553 16.2089 17.7693C16.2241 17.8258 16.2362 17.8826 16.2463 17.9395C16.438 17.9838 16.6231 18.0564 16.7949 18.1555C17.7462 18.7048 18.0734 19.9256 17.5242 20.8769L16.9402 21.8884L18.1919 22.611C18.6964 22.9023 19.3414 22.7295 19.6326 22.225C19.9239 21.7205 19.7511 21.0755 19.2466 20.7843L20.0585 21.253C20.563 21.5443 21.208 21.3714 21.4992 20.867C21.7905 20.3625 21.6176 19.7175 21.1132 19.4262L21.6545 19.7387C22.1589 20.03 22.804 19.8571 23.0952 19.3527C23.3865 18.8482 23.2136 18.2032 22.7091 17.912L22.1679 17.5995C22.6723 17.8907 23.3174 17.7179 23.6086 17.2134C23.8999 16.709 23.727 16.0639 23.2226 15.7727Z"
					fill="#19C0FF"
				/>
				<path
					d="M16.3262 18.966C15.8217 18.6747 15.1767 18.8476 14.8854 19.352L15.1979 18.8107C15.4892 18.3063 15.3163 17.6613 14.8119 17.37C14.3075 17.0788 13.6624 17.2516 13.3712 17.7561L13.6837 17.2148C13.9749 16.7103 13.8021 16.0653 13.2977 15.7741C12.7932 15.4828 12.1482 15.6557 11.8569 16.1601L11.5444 16.7013C11.8357 16.1969 11.6628 15.5519 11.1584 15.2606C10.6539 14.9694 10.0089 15.1422 9.71765 15.6467L8.58484 17.6087C8.29359 18.1132 8.46643 18.7582 8.97087 19.0495C9.47531 19.3407 10.1204 19.1679 10.4116 18.6634C10.1203 19.1678 10.2932 19.8129 10.7976 20.1041C11.3021 20.3954 11.9471 20.2225 12.2383 19.7181C11.9471 20.2225 12.1199 20.8676 12.6244 21.1588C13.1288 21.45 13.7739 21.2772 14.0651 20.7727C13.7739 21.2772 13.9467 21.9222 14.4512 22.2134C14.9556 22.5047 15.6007 22.3318 15.8919 21.8274L16.7122 20.4066C17.0035 19.9022 16.8306 19.2572 16.3262 18.966Z"
					fill="#19C0FF"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4956_244916">
					<rect width="16" height="16" fill="white" transform="translate(8 8)" />
				</clipPath>
			</defs>
		</svg>
	);
};
