import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Modal from "components/Modal";
import Typography from "components/Typography";
import { IconErrorSvg } from "components/Svgs";
import commonSelectors from "stores/common/selectors";
import { cancelModalApiError } from "stores/common/actions";
import { PATH_SIGN_IN } from "configs/routes";
import { UNAUTHORIZED_CODE } from "utils/constants";

const ModalError = () => {
	const { apiError } = useSelector(commonSelectors);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleCancel = () => {
		dispatch(cancelModalApiError());
		if (apiError.statusCode === UNAUTHORIZED_CODE) {
			localStorage.clear();
			navigate(PATH_SIGN_IN);
		}
	};

	return (
		<Modal zIndex={9999} visible={apiError.visible} centered handleCancel={handleCancel}>
			<Container>
				<IconErrorSvg />
				<Typography variant="h3" color="#DD4558" top={30}>
					{apiError.message}
				</Typography>
			</Container>
		</Modal>
	);
};

export default ModalError;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;
