import React from "react";

export const IconFlightSvg = (props) => (
	<svg width={20} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="m2.036 8.427-.002.001a.47.47 0 0 0 .365.861l6.557-2.181L7.73 9.11a.38.38 0 0 0 .499.535l1.286-.668a.378.378 0 0 0 .099-.074l3.809-3.98 4.7-2.452c1.781-.96 1.575-1.698 1.47-1.901-.082-.158-.265-.369-.672-.48-.412-.114-.97-.105-1.703.027-.245.044-.38.04-.524.036-.503-.013-.844.02-2.539.901l-9.27 4.817-3.594-1.865a.469.469 0 0 0-.433 0l-.605.315a.469.469 0 0 0-.15.71l2.495 3.107-.562.29ZM19.107 12.202H1.556a.894.894 0 0 0 0 1.787h17.55a.894.894 0 0 0 0-1.787Z"
			fill="#19C0FF"
		/>
	</svg>
);
