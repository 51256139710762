/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

export const IconUsdSvg = ({ color = "#F98C05", width = 12, height = 12, ...props }) => {
	return (
		<Container style={{ width, height }} color={color}>
			<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
				<g fill={color}>
					<path d="M240 480v-36.42C160.53 439 112.25 398.06 112 336h72c1.77 26.34 23.86 46.45 56 50v-98l-26.77-7c-61-14.18-93.64-49.39-93.64-102.08C119.59 116.81 164.08 76.08 240 70V32h32v38c77.39 6.3 119 47.74 120 106h-72c-.76-24.06-15.83-43.39-48-46v92l30.82 7.28C367.61 243.46 400 277 400 332c0 64.34-43.74 105.88-128 111.32V480zm0-264v-86c-27.59 1.52-47.27 18.47-47.27 42.53 0 22.3 16.39 36.88 47.27 43.47zm32 78v92c38.15-1.54 56.38-18.92 56.38-45.77 0-24.58-18.23-41.13-56.38-46.23z" />
				</g>
			</svg>
		</Container>
	);
};

const Container = styled.div`
	border: ${({ color }) => `1px solid ${color}`};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3px;
`;
