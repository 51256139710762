import { combineActiontype } from "utils/helpers";

const page = "dbUserAccounts";

export const dbUserAccountsActions = combineActiontype(page, {
	FETCH_USER_ACCOUNTS_SUCCESS: "FETCH_USER_ACCOUNTS_SUCCESS",
	FETCH_USER_ACCOUNTS_FAILURE: "FETCH_USER_ACCOUNTS_FAILURE",

	FETCH_USER_ACCOUNT_DETAIL_SUCCESS: "FETCH_USER_ACCOUNT_DETAIL_SUCCESS",
	FETCH_USER_ACCOUNT_DETAIL_FAILURE: "FETCH_USER_ACCOUNT_DETAIL_FAILURE",

	FETCH_ACTIVATE_USER_ACCOUNT_SUCCESS: "FETCH_ACTIVATE_USER_ACCOUNT_SUCCESS",
	FETCH_ACTIVATE_USER_ACCOUNT_FAILURE: "FETCH_ACTIVATE_USER_ACCOUNT_FAILURE",

	FETCH_BLOCK_USER_ACCOUNT_SUCCESS: "FETCH_BLOCK_USER_ACCOUNT_SUCCESS",
	FETCH_BLOCK_USER_ACCOUNT_FAILURE: "FETCH_BLOCK_USER_ACCOUNT_FAILURE",
});

export const fetchUserAccountsSuccess = (userAcounts) => ({
	type: dbUserAccountsActions.FETCH_USER_ACCOUNTS_SUCCESS,
	payload: userAcounts,
});

export const fetchUserAccountsFailure = (error) => ({
	type: dbUserAccountsActions.FETCH_USER_ACCOUNTS_FAILURE,
	payload: error,
});

export const fetchUserAccountDetailSuccess = (userAcount) => ({
	type: dbUserAccountsActions.FETCH_USER_ACCOUNT_DETAIL_SUCCESS,
	payload: userAcount,
});

export const fetchUserAccountDetailFailure = (error) => ({
	type: dbUserAccountsActions.FETCH_USER_ACCOUNT_DETAIL_FAILURE,
	payload: error,
});

export const fetchActivateUserAccountSuccess = (activatedAt) => ({
	type: dbUserAccountsActions.FETCH_ACTIVATE_USER_ACCOUNT_SUCCESS,
	payload: activatedAt,
});

export const fetchActivateUserAccountFailure = (error) => ({
	type: dbUserAccountsActions.FETCH_ACTIVATE_USER_ACCOUNT_FAILURE,
	payload: error,
});

export const fetchBlockUserAccountSuccess = (blockedAt) => ({
	type: dbUserAccountsActions.FETCH_BLOCK_USER_ACCOUNT_SUCCESS,
	payload: blockedAt,
});

export const fetchBlockUserAccountFailure = (error) => ({
	type: dbUserAccountsActions.FETCH_BLOCK_USER_ACCOUNT_FAILURE,
	payload: error,
});
