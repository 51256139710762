import produce from "immer";
import { adPayoutsActions } from "./actions";

const INITIAL_STATE = {
	payouts: {},
	error: null,
};

const adPayoutsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case adPayoutsActions.FETCH_AD_PAYOUTS_SUCCESS:
			draft.payouts = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default adPayoutsReducer;
