import produce from "immer";
import { dbGenericRolesActions } from "./actions";

const INITIAL_STATE = {
	genericRoles: {},
	permissions: {},
};

const dbGenericRolesReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbGenericRolesActions.FETCH_GENERIC_ROLES_SUCCESS:
			draft.genericRoles = action.payload;
			break;
		case dbGenericRolesActions.FETCH_PERMISSIONS_SUCCESS:
			draft.permissions = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbGenericRolesReducer;
