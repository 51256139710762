/* eslint-disable react/prop-types */
import React from "react";

export const IconImageSvg = ({ color = "#8E929A", ...props }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M21.9154 15.15L18.6754 11.88C18.6604 11.8611 18.6408 11.8465 18.6185 11.8373C18.5962 11.8281 18.5719 11.8247 18.5479 11.8275C18.5237 11.8284 18.5 11.8342 18.4781 11.8445C18.4562 11.8548 18.4366 11.8694 18.4204 11.8875L16.2604 14.175C16.1208 14.3235 15.9523 14.442 15.7653 14.5232C15.5784 14.6045 15.3768 14.6467 15.1729 14.6475C14.9691 14.6467 14.7675 14.6045 14.5805 14.5232C14.3935 14.442 14.2251 14.3235 14.0854 14.175L9.45043 9.23247C9.41743 9.20295 9.37471 9.18664 9.33043 9.18664C9.28615 9.18664 9.24343 9.20295 9.21043 9.23247L1.99543 14.8575L1.06543 13.65L8.25043 8.02497C8.5802 7.76858 8.99399 7.64487 9.41037 7.67818C9.82676 7.71149 10.2156 7.89941 10.5004 8.20497L15.1279 13.1475L17.2729 10.86C17.4264 10.6945 17.6119 10.562 17.8182 10.4705C18.0245 10.3789 18.2473 10.3303 18.4729 10.3275C18.6987 10.3216 18.9231 10.3626 19.1323 10.4478C19.3414 10.533 19.5306 10.6605 19.6879 10.8225L22.9279 14.0925L21.9154 15.15Z"
				fill={color}
			/>
			<path
				d="M20.31 23.25H3.69C2.91026 23.25 2.16246 22.9403 1.61111 22.3889C1.05975 21.8375 0.75 21.0897 0.75 20.31V3.69C0.75 2.91026 1.05975 2.16246 1.61111 1.61111C2.16246 1.05975 2.91026 0.75 3.69 0.75H20.31C21.0897 0.75 21.8375 1.05975 22.3889 1.61111C22.9403 2.16246 23.25 2.91026 23.25 3.69V20.31C23.25 21.0897 22.9403 21.8375 22.3889 22.3889C21.8375 22.9403 21.0897 23.25 20.31 23.25ZM3.69 2.25C3.30809 2.25 2.94182 2.40171 2.67177 2.67177C2.40171 2.94182 2.25 3.30809 2.25 3.69V20.31C2.25 20.4991 2.28725 20.6864 2.35961 20.8611C2.43198 21.0358 2.53805 21.1945 2.67177 21.3282C2.80548 21.462 2.96423 21.568 3.13894 21.6404C3.31364 21.7128 3.5009 21.75 3.69 21.75H20.31C20.4991 21.75 20.6864 21.7128 20.8611 21.6404C21.0358 21.568 21.1945 21.462 21.3282 21.3282C21.462 21.1945 21.568 21.0358 21.6404 20.8611C21.7128 20.6864 21.75 20.4991 21.75 20.31V3.69C21.75 3.5009 21.7128 3.31364 21.6404 3.13894C21.568 2.96423 21.462 2.80548 21.3282 2.67177C21.1945 2.53805 21.0358 2.43198 20.8611 2.35961C20.6864 2.28725 20.4991 2.25 20.31 2.25H3.69Z"
				fill={color}
			/>
			<path
				d="M15.2471 8.87256C16.0755 8.87256 16.7471 8.20099 16.7471 7.37256C16.7471 6.54413 16.0755 5.87256 15.2471 5.87256C14.4186 5.87256 13.7471 6.54413 13.7471 7.37256C13.7471 8.20099 14.4186 8.87256 15.2471 8.87256Z"
				fill={color}
			/>
			<path
				d="M1.5 14.6248L8.6475 8.87234L9.9675 8.71484L14.295 13.3348C14.295 13.3348 15.045 14.3623 15.6825 13.6573L17.835 11.3698C17.835 11.3698 18.2625 10.4323 19.1775 11.3698C20.0925 12.3073 22.5 15.3748 22.5 15.3748L22.4475 20.7973C22.4475 20.7973 22.2225 22.4998 20.0175 22.4998C17.8125 22.4998 3.5175 22.2673 3.5175 22.2673C3.5175 22.2673 1.5 22.7623 1.5 20.3098C1.5 17.8573 1.5 14.6248 1.5 14.6248Z"
				fill={color}
			/>
		</svg>
	);
};
