import React from "react";

export const IconGroupUserSvg = (props) => (
	<svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M6.747 13.135c-1.535 0-2.784-1.238-2.784-2.758 0-1.521 1.249-2.76 2.784-2.76 1.535 0 2.784 1.238 2.784 2.76 0 1.52-1.249 2.758-2.784 2.758Zm0-4.743c-1.108 0-2.01.89-2.01 1.985 0 1.094.902 1.984 2.01 1.984 1.108 0 2.01-.89 2.01-1.984 0-1.095-.902-1.985-2.01-1.985ZM7.867 20.773H3.012A1.01 1.01 0 0 1 2 19.767v-1.266C2 15.91 4.13 13.8 6.747 13.8c1.03 0 2.01.321 2.834.929a.387.387 0 0 1-.46.623 3.97 3.97 0 0 0-2.374-.778c-2.19 0-3.973 1.762-3.973 3.927v1.266c0 .128.107.232.238.232h4.856a.387.387 0 0 1 0 .774ZM24.988 20.48h-4.856a.387.387 0 0 1 0-.774h4.856a.235.235 0 0 0 .238-.231v-1.267c0-2.165-1.783-3.926-3.974-3.926a4.01 4.01 0 0 0-2.515.887.387.387 0 1 1-.487-.602 4.786 4.786 0 0 1 3.002-1.06c2.618 0 4.748 2.11 4.748 4.701v1.267a1.01 1.01 0 0 1-1.012 1.005ZM21.253 12.944c-1.535 0-2.784-1.238-2.784-2.759 0-1.52 1.249-2.758 2.784-2.758 1.535 0 2.784 1.237 2.784 2.758 0 1.522-1.25 2.759-2.784 2.759Zm0-4.743c-1.108 0-2.01.89-2.01 1.984 0 1.095.902 1.985 2.01 1.985 1.108 0 2.01-.89 2.01-1.985 0-1.094-.902-1.984-2.01-1.984Z"
			fill="#8E929A"
			stroke="#8E929A"
			strokeWidth={0.3}
		/>
		<path
			d="M19.254 22.032H8.746a1.263 1.263 0 0 1-1.266-1.256v-1.781c0-3.56 2.925-6.455 6.52-6.455 3.595 0 6.52 2.896 6.52 6.455v1.78c0 .694-.568 1.257-1.266 1.257ZM14 13.315c-3.168 0-5.745 2.548-5.745 5.68v1.78c0 .267.22.483.491.483h10.508a.488.488 0 0 0 .491-.482v-1.781c0-3.132-2.577-5.68-5.745-5.68ZM13.866 12.088c-1.973 0-3.578-1.59-3.578-3.544S11.893 5 13.866 5c1.973 0 3.578 1.59 3.578 3.544s-1.606 3.544-3.578 3.544Zm0-6.314c-1.546 0-2.804 1.243-2.804 2.77s1.258 2.77 2.804 2.77c1.546 0 2.803-1.243 2.803-2.77s-1.257-2.77-2.803-2.77Z"
			fill="#8E929A"
			stroke="#8E929A"
			strokeWidth={0.3}
		/>
	</svg>
);
