/* eslint-disable no-unused-vars */
import React from "react";
import { Button as AntdButton } from "antd";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Button = ({ children, width, height, ...props }) => {
	return (
		<StyledButton width={width} height={height} {...props}>
			{children}
		</StyledButton>
	);
};

export default Button;

Button.propTypes = {
	children: PropTypes.node,
	width: PropTypes.string,
	height: PropTypes.string,
};

Button.defaultProps = {
	children: "",
	width: "100%",
	height: "40px",
};

const StyledButton = styled(({ borderColor, ...props }) => <AntdButton {...props} />)`
	${({ borderColor }) => css`
		padding: 0 10px;
		border-radius: 2px;
		cursor: pointer;
		width: 100%;
		display: flex;
		width: ${({ width }) => width};
		height: ${({ height }) => height};
		align-items: center;
		justify-content: center;
		&.ant-btn-primary,
		&.ant-btn-primary p {
			color: #ffffff !important;
		}
		&.ant-btn-default {
			border: 1px solid ${borderColor || "rgba(18, 18, 22, 0.2)"};
		}
		&.ant-btn-primary[disabled] {
			opacity: 0.60052490234375;
			background-color: #8a99a9;
			border: 0;
		}
	`}
`;
