import React from "react";

export const IconInfoOutlinedSvg = (props) => (
	<svg width={12} height={11} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={6.258} cy={3.279} r={0.529} fill="#8E929A" />
		<path d="M6.798 7.826V4.441H5.53v.212h.423v3.173H5.53v.212h1.692v-.212h-.423Z" fill="#8E929A" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M.877 5.5a5.5 5.5 0 0 1 5.5-5.5 5.5 5.5 0 0 1 5.5 5.5 5.5 5.5 0 0 1-5.5 5.5 5.5 5.5 0 0 1-5.5-5.5Zm.457 0a5.048 5.048 0 0 0 5.042 5.042 5.05 5.05 0 0 0 5.043-5.043A5.048 5.048 0 0 0 6.376.457a5.048 5.048 0 0 0-5.042 5.042Z"
			fill="#8E929A"
		/>
	</svg>
);
