import { combineActiontype } from "utils/helpers";

const page = "dbWorldWideCompanies";

export const dbWorldWideCompaniesActions = combineActiontype(page, {
	FETCH_WORLDWIDE_COMPANIES_SUCCESS: "FETCH_WORLDWIDE_COMPANIES_SUCCESS",
	FETCH_WORLDWIDE_COMPANIES_FAILURE: "FETCH_WORLDWIDE_COMPANIES_FAILURE",

	FETCH_WORLDWIDE_COMPANY_DETAIL_SUCCESS: "FETCH_WORLDWIDE_COMPANY_DETAIL_SUCCESS",
	FETCH_WORLDWIDE_COMPANY_DETAIL_FAILURE: "FETCH_WORLDWIDE_COMPANY_DETAIL_FAILURE",

	FETCH_DELETE_WORLDWIDE_COMPANY_SUCCESS: "FETCH_DELETE_WORLDWIDE_COMPANY_SUCCESS",
	FETCH_DELETE_WORLDWIDE_COMPANY_FAILURE: "FETCH_DELETE_WORLDWIDE_COMPANY_FAILURE",
});

export const fetchWorldWideCompaniesSuccess = (companies) => ({
	type: dbWorldWideCompaniesActions.FETCH_WORLDWIDE_COMPANIES_SUCCESS,
	payload: companies,
});

export const fetchWorldWideCompaniesFailure = (error) => ({
	type: dbWorldWideCompaniesActions.FETCH_WORLDWIDE_COMPANIES_FAILURE,
	payload: error,
});

export const fetchWorldWideCompanyDetailSuccess = (company) => ({
	type: dbWorldWideCompaniesActions.FETCH_WORLDWIDE_COMPANY_DETAIL_SUCCESS,
	payload: company,
});

export const fetchWorldWideCompanyDetailFailure = (error) => ({
	type: dbWorldWideCompaniesActions.FETCH_WORLDWIDE_COMPANY_DETAIL_FAILURE,
	payload: error,
});

export const fetchDeleteWorldWideCompanySuccess = (blackedAt) => ({
	type: dbWorldWideCompaniesActions.FETCH_DELETE_WORLDWIDE_COMPANY_SUCCESS,
	payload: blackedAt,
});

export const fetchDeleteWorldWideCompanyFailure = (error) => ({
	type: dbWorldWideCompaniesActions.FETCH_DELETE_WORLDWIDE_COMPANY_FAILURE,
	payload: error,
});
