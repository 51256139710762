/* eslint-disable react/prop-types */
import React from "react";

export const IconDeleteSvg = ({ color = "#65686F", ...props }) => {
	return (
		<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8.43798 5.26953C8.25688 5.26953 8.11011 5.4163 8.11011 5.5974V11.7941C8.11011 11.9751 8.25688 12.122 8.43798 12.122C8.61907 12.122 8.76584 11.9751 8.76584 11.7941V5.5974C8.76584 5.4163 8.61907 5.26953 8.43798 5.26953Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
			<path
				d="M4.56908 5.26953C4.38798 5.26953 4.24121 5.4163 4.24121 5.5974V11.7941C4.24121 11.9751 4.38798 12.122 4.56908 12.122C4.75017 12.122 4.89695 11.9751 4.89695 11.7941V5.5974C4.89695 5.4163 4.75017 5.26953 4.56908 5.26953Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
			<path
				d="M1.74956 4.3652V12.4432C1.74956 12.9206 1.92463 13.369 2.23047 13.6907C2.5349 14.0134 2.95857 14.1965 3.40196 14.1973H9.60532C10.0488 14.1965 10.4725 14.0134 10.7768 13.6907C11.0826 13.369 11.2577 12.9206 11.2577 12.4432V4.3652C11.8657 4.20383 12.2596 3.61648 12.1783 2.99264C12.0969 2.36892 11.5655 1.90235 10.9364 1.90222H9.25773V1.49239C9.25965 1.14774 9.12338 0.8168 8.8794 0.573332C8.63542 0.329993 8.30397 0.194491 7.95932 0.197309H5.04796C4.70331 0.194491 4.37186 0.329993 4.12788 0.573332C3.8839 0.8168 3.74763 1.14774 3.74955 1.49239V1.90222H2.07089C1.4418 1.90235 0.910421 2.36892 0.828966 2.99264C0.74764 3.61648 1.14159 4.20383 1.74956 4.3652V4.3652ZM9.60532 13.5415H3.40196C2.84138 13.5415 2.40529 13.06 2.40529 12.4432V4.39402H10.602V12.4432C10.602 13.06 10.1659 13.5415 9.60532 13.5415ZM4.40529 1.49239C4.40311 1.32166 4.47022 1.15735 4.59138 1.03683C4.71241 0.916313 4.87711 0.850099 5.04796 0.853044H7.95932C8.13017 0.850099 8.29488 0.916313 8.4159 1.03683C8.53706 1.15722 8.60417 1.32166 8.60199 1.49239V1.90222H4.40529V1.49239ZM2.07089 2.55796H10.9364C11.2623 2.55796 11.5266 2.82217 11.5266 3.14812C11.5266 3.47407 11.2623 3.73828 10.9364 3.73828H2.07089C1.74495 3.73828 1.48073 3.47407 1.48073 3.14812C1.48073 2.82217 1.74495 2.55796 2.07089 2.55796V2.55796Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
			<path
				d="M6.50365 5.26953C6.32255 5.26953 6.17578 5.4163 6.17578 5.5974V11.7941C6.17578 11.9751 6.32255 12.122 6.50365 12.122C6.68474 12.122 6.83152 11.9751 6.83152 11.7941V5.5974C6.83152 5.4163 6.68474 5.26953 6.50365 5.26953Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
		</svg>
	);
};
