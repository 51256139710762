import { combineActiontype } from "utils/helpers";

const page = "dbFlights";

export const dbFlightsActions = combineActiontype(page, {
	FETCH_FLIGHTS_SUCCESS: "FETCH_FLIGHTS_SUCCESS",
	FETCH_FLIGHTS_FAILURE: "FETCH_FLIGHTS_FAILURE",

	FETCH_FLIGHT_DETAIL_SUCCESS: "FETCH_FLIGHT_DETAIL_SUCCESS",
	FETCH_FLIGHT_DETAIL_FAILURE: "FETCH_FLIGHT_DETAIL_FAILURE",
});

export const fetchFlightsSuccess = (flights) => ({
	type: dbFlightsActions.FETCH_FLIGHTS_SUCCESS,
	payload: flights,
});

export const fetchFlightsFailure = (error) => ({
	type: dbFlightsActions.FETCH_FLIGHTS_FAILURE,
	payload: error,
});

export const fetchFlightDetailSuccess = (flight) => ({
	type: dbFlightsActions.FETCH_FLIGHT_DETAIL_SUCCESS,
	payload: flight,
});

export const fetchFlightDetailFailure = (error) => ({
	type: dbFlightsActions.FETCH_FLIGHT_DETAIL_FAILURE,
	payload: error,
});
