/* eslint-disable react/prop-types */
import React from "react";

export const IconActiveSvg = ({ color = "#7ED321", ...props }) => {
	return (
		<svg width="8px" height="8px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-1789.000000, -398.000000)" fill={color}>
					<g transform="translate(375.000000, 184.000000)">
						<g transform="translate(776.000000, 213.000000)">
							<g transform="translate(535.000000, 0.000000)">
								<g transform="translate(103.000000, 0.000000)">
									<circle cx="7" cy="8" r="7"></circle>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
