import React from "react";

export const IconBankAccountSvg = (props) => (
	<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M22.31 10.036a.49.49 0 0 0 .49-.49V7.583a.49.49 0 0 0-.24-.422l-9.603-5.703a.196.196 0 0 0-.013-.008c-.597-.33-1.27-.34-1.901.007L1.44 7.16a.49.49 0 0 0-.24.422v1.963c0 .271.22.49.49.49h1.474v8.836c-1.083 0-1.964.88-1.964 1.964v1.472c0 .272.22.491.49.491h20.62a.49.49 0 0 0 .49-.49v-1.473c0-1.083-.88-1.964-1.964-1.964v-8.836h1.473Zm-.492 10.8v.981H2.182v-.981c0-.542.44-.982.982-.982H20.836c.542 0 .982.44.982.982ZM4.145 18.872v-8.836H6.11v8.836H4.145Zm2.946 0v-8.836h2.945v8.836H7.091Zm3.927 0v-8.836h1.964v8.836h-1.964Zm2.946 0v-8.836h2.945v8.836h-2.945Zm3.927 0v-8.836h1.964v8.836H17.89ZM2.18 9.054V7.86l9.349-5.554a.934.934 0 0 1 .933-.003l9.355 5.557v1.193H2.182Z"
			fill="#19C0FF"
			stroke="#19C0FF"
			strokeWidth={0.5}
		/>
	</svg>
);
