import { compact, words } from "lodash";
import { USER_TYPE, IDENTIFY_TYPE } from "utils/constants";

export function isJSON(str) {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
}

export function combineActiontype(page, actions) {
	const newActions = {};
	Object.entries(actions).forEach(([key, action]) => {
		newActions[`${key}`] = `${page}_${action}`;
	});

	return newActions;
}

export function getAuthToken() {
	const accessToken = localStorage.getItem("access_token");
	const refreshToken = localStorage.getItem("refresh_token");
	return {
		access_token: accessToken,
		refresh_token: refreshToken,
	};
}

export function setAuthToken({ accessToken, refreshToken, tokenType = USER_TYPE }) {
	if (accessToken) localStorage.setItem("access_token", accessToken);
	if (refreshToken) localStorage.setItem("refresh_token", refreshToken);
	if (tokenType) localStorage.setItem("token_type", tokenType);
}

export function isAuthenticated() {
	const { access_token } = getAuthToken();
	const tokenType = localStorage.getItem("token_type");
	if (tokenType === USER_TYPE && access_token) return true;
	return false;
}

export function cc_format(value) {
	let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
	let matches = v.match(/\d{4,16}/g);
	let match = (matches && matches[0]) || "";
	let parts = [];

	for (let i = 0, len = match.length; i < len; i += 4) {
		parts.push(match.substring(i, i + 4));
	}

	if (parts.length) {
		return parts.join(" ");
	} else {
		return value;
	}
}

export function isIdentified() {
	const { access_token } = getAuthToken();
	const tokenType = localStorage.getItem("token_type");
	if (tokenType === IDENTIFY_TYPE && access_token) return true;
	return false;
}

export function capitalizeStrings(text = "", lowerCase = true, splitText = "_") {
	const words = text.trim().split(splitText);
	const newWords = words.map((word) =>
		lowerCase
			? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
			: (word.charAt(0) + word.slice(1)).toUpperCase()
	);
	return newWords.join(" ");
}

export function parseTime(time) {
	const hrs = Math.floor(time / 60) > 0 ? `${Math.floor(time / 60)} hrs ` : "";
	const mins = `${(time % 60)?.toString()?.padStart(2, "0")} mins`;
	return hrs + mins;
}

export const findCountries = (text, allCountries) => {
	const countries = allCountries.filter(
		(c) =>
			c?.short_code?.includes(text) ||
			c?.english_name?.includes(text) ||
			c?.native_name?.includes(text) ||
			c?.calling_code?.includes(text)
	);
	return countries;
};

export const validateParams = (params = {}) => {
	const paramsObj = {};
	Object.entries(params).forEach(([key, value]) => {
		if (value) paramsObj[`${key}`] = value;
	});
	return paramsObj;
};

export const trimString = (stringArr = [], joinTxt = ", ") => {
	const newStrings = compact(stringArr)?.map((text) => text?.trim?.() || text);
	if (newStrings.length > 0) {
		return newStrings.join(joinTxt);
	}
	return "";
};

export const formatCurrency = (value, currency, maximumFractionDigits) => {
	try {
		let data = new Intl.NumberFormat("us-US", {
			style: "currency",
			currency: currency || "USD",
			maximumFractionDigits: maximumFractionDigits || undefined,
		}).format(value);

		return data;
	} catch (e) {
		return value;
	}
};

export const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = (error) => reject(error);
	});

export const combineAddress = (address) => {
	let {
		address_line1 = "",
		address_line2 = "",
		line1 = "",
		line2 = "",
		street = "",
		number = "",
		city = "",
		state = "",
		postalcode = "",
		country = { english_name: "" },
	} = address || {};

	let addressText = "";

	if (address_line1 || line1) {
		addressText += address_line1 || line1;

		if (address_line2 || line2) {
			addressText += (addressText ? ", " : "") + (address_line2 || line2);
		}
	} else {
		if (number || street) {
			addressText += `${number} ${street},`;
		}
	}

	if (state) {
		if (city) {
			addressText += ", " + city;
		}

		addressText += (addressText ? ", " : "") + state;

		if (postalcode) {
			addressText += (addressText ? ", " : "") + postalcode;
		}
	} else {
		if (postalcode) {
			addressText += (addressText ? ", " : "") + postalcode;
		}
		if (city) {
			addressText += (postalcode ? " " : addressText ? ", " : "") + city;
		}
	}

	if (country && country.english_name) {
		addressText += (addressText ? ", " : "") + country.english_name;
	}

	return addressText;
};

export const convertVariableToString = (text = "") => {
	const item = text.split("_");
	return item.join(" ");
};

export function getAvatarShortenedName(name) {
	if (typeof name === "string" && name.trim() !== "") {
		const ws = words(name);
		const { length } = ws;
		let sh;
		if (length > 1) {
			sh = ws[0].charAt(0) + ws[length - 1].charAt(0);
		} else {
			sh = ws[0].charAt(0) + ws[0].charAt(ws[0].length - 1);
		}
		return sh.toUpperCase();
	}
	return "UN";
}

export function checkTextIsLink(txt) {
	const regex = new RegExp(
		`https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
		"g"
	);
	const links = regex.exec(txt);
	return links?.[0];
}
