import { combineActiontype } from "utils/helpers";

const page = "dbCLW";

export const dbCLWActions = combineActiontype(page, {
	FETCH_COMPANY_SIDEBAR_SETTINGS_SUCCESS: "FETCH_COMPANY_SIDEBAR_SETTINGS_SUCCESS",
	FETCH_COMPANY_SIDEBAR_SETTINGS_FAILURE: "FETCH_COMPANY_SIDEBAR_SETTINGS_FAILURE",

	FETCH_COMPANIES_SUCCESS: "FETCH_COMPANIES_SUCCESS",
	FETCH_COMPANIES_FAILURE: "FETCH_COMPANIES_FAILURE",

	FETCH_LOCATIONS_SUCCESS: "FETCH_LOCATIONS_SUCCESS",
	FETCH_LOCATIONS_FAILURE: "FETCH_LOCATIONS_FAILURE",

	FETCH_WHITE_LABELS_SUCCESS: "FETCH_WHITE_LABELS_SUCCESS",
	FETCH_WHITE_LABELS_FAILURE: "FETCH_WHITE_LABELS_FAILURE",

	FETCH_COMPANIES_SUGGESTIONS_SUCCESS: "FETCH_COMPANIES_SUGGESTIONS_SUCCESS",
	FETCH_COMPANIES_SUGGESTIONS_FAILURE: "FETCH_COMPANIES_SUGGESTIONS_FAILURE",

	FETCH_UPDATE_COMPANIES_SUGGESTIONS_STATUS_SUCCESS: "FETCH_UPDATE_COMPANIES_SUGGESTIONS_STATUS_SUCCESS",
	FETCH_UPDATE_COMPANIES_SUGGESTIONS_STATUS_FAILURE: "FETCH_UPDATE_COMPANIES_SUGGESTIONS_STATUS_FAILURE",

	FETCH_COMPANIES_SUGGESTIONS_DETAIL_SUCCESS: "FETCH_COMPANIES_SUGGESTIONS_DETAIL_SUCCESS",
	FETCH_COMPANIES_SUGGESTIONS_DETAIL_FAILURE: "FETCH_COMPANIES_SUGGESTIONS_DETAIL_FAILURE",

	FETCH_COMPANY_DETAIL_SUCCESS: "FETCH_COMPANY_DETAIL_SUCCESS",
	FETCH_COMPANY_DETAIL_FAILURE: "FETCH_COMPANY_DETAIL_FAILURE",

	FETCH_BLOCK_COMPANY_SUCCESS: "FETCH_BLOCK_COMPANY_SUCCESS",
	FETCH_BLOCK_COMPANY_FAILURE: "FETCH_BLOCK_COMPANY_FAILURE",

	FETCH_VALIDATE_COMPANY_SUCCESS: "FETCH_VALIDATE_COMPANY_SUCCESS",
	FETCH_VALIDATE_COMPANY_FAILURE: "FETCH_VALIDATE_COMPANY_FAILURE",

	FETCH_WHITELABEL_DETAIL_SUCCESS: "FETCH_WHITELABEL_DETAIL_SUCCESS",
	FETCH_WHITELABEL_DETAIL_FAILURE: "FETCH_WHITELABEL_DETAIL_FAILURE",

	FETCH_LOCATION_DETAIL_SUCCESS: "FETCH_LOCATION_DETAIL_SUCCESS",
	FETCH_LOCATION_DETAIL_FAILURE: "FETCH_LOCATION_DETAIL_FAILURE",

	FETCH_APPROVE_LOCATION_SUCCESS: "FETCH_APPROVE_LOCATION_SUCCESS",
	FETCH_APPROVE_LOCATION_FAILURE: "FETCH_APPROVE_LOCATION_FAILURE",
});

export const fetchCompanySidebarSettingsSuccess = (settings) => ({
	type: dbCLWActions.FETCH_COMPANY_SIDEBAR_SETTINGS_SUCCESS,
	payload: settings,
});

export const fetchCompanySidebarSettingsFailure = (error) => ({
	type: dbCLWActions.FETCH_COMPANY_SIDEBAR_SETTINGS_FAILURE,
	payload: error,
});

export const fetchCompaniesSuccess = (companies) => ({
	type: dbCLWActions.FETCH_COMPANIES_SUCCESS,
	payload: companies,
});

export const fetchCompaniesFailure = (error) => ({
	type: dbCLWActions.FETCH_COMPANIES_FAILURE,
	payload: error,
});

export const fetchLocationsSuccess = (locations) => ({
	type: dbCLWActions.FETCH_LOCATIONS_SUCCESS,
	payload: locations,
});

export const fetchLocationsFailure = (error) => ({
	type: dbCLWActions.FETCH_LOCATIONS_FAILURE,
	payload: error,
});

export const fetchWhitelabelsSuccess = (whitelabels) => ({
	type: dbCLWActions.FETCH_WHITE_LABELS_SUCCESS,
	payload: whitelabels,
});

export const fetchWhitelabelsFailure = (error) => ({
	type: dbCLWActions.FETCH_WHITE_LABELS_FAILURE,
	payload: error,
});

export const fetchCompaniesSuggestionsSuccess = (suggestions) => ({
	type: dbCLWActions.FETCH_COMPANIES_SUGGESTIONS_SUCCESS,
	payload: suggestions,
});

export const fetchCompaniesSuggestionsFailure = (error) => ({
	type: dbCLWActions.FETCH_COMPANIES_SUGGESTIONS_FAILURE,
	payload: error,
});

export const dispatchUpdateCompaniesSuggestionsStatusSuccess = (suggestions) => ({
	type: dbCLWActions.FETCH_UPDATE_COMPANIES_SUGGESTIONS_STATUS_SUCCESS,
	payload: suggestions,
});

export const dispatchUpdateCompaniesSuggestionsStatusFailure = (error) => ({
	type: dbCLWActions.FETCH_UPDATE_COMPANIES_SUGGESTIONS_STATUS_FAILURE,
	payload: error,
});

export const fetchCompaniesSuggestionsDetailSuccess = (suggestion) => ({
	type: dbCLWActions.FETCH_COMPANIES_SUGGESTIONS_DETAIL_SUCCESS,
	payload: suggestion,
});

export const fetchCompaniesSuggestionsDetailFailure = (error) => ({
	type: dbCLWActions.FETCH_COMPANIES_SUGGESTIONS_DETAIL_FAILURE,
	payload: error,
});

export const fetchCompanyDetailSuccess = (company) => ({
	type: dbCLWActions.FETCH_COMPANY_DETAIL_SUCCESS,
	payload: company,
});

export const fetchCompanyDetailFailure = (error) => ({
	type: dbCLWActions.FETCH_COMPANY_DETAIL_FAILURE,
	payload: error,
});

export const fetchBlockCompanySuccess = (blackedAt) => ({
	type: dbCLWActions.FETCH_BLOCK_COMPANY_SUCCESS,
	payload: blackedAt,
});

export const fetchBlockCompanyFailure = (error) => ({
	type: dbCLWActions.FETCH_BLOCK_COMPANY_FAILURE,
	payload: error,
});

export const fetchValidateCompanySuccess = (validatedAt) => ({
	type: dbCLWActions.FETCH_VALIDATE_COMPANY_SUCCESS,
	payload: validatedAt,
});

export const fetchValidateCompanyFailure = (error) => ({
	type: dbCLWActions.FETCH_VALIDATE_COMPANY_FAILURE,
	payload: error,
});

export const fetchWhitelabelDetailSuccess = (whitelabel) => ({
	type: dbCLWActions.FETCH_WHITELABEL_DETAIL_SUCCESS,
	payload: whitelabel,
});

export const fetchWhitelabelDetailFailure = (error) => ({
	type: dbCLWActions.FETCH_WHITELABEL_DETAIL_FAILURE,
	payload: error,
});

export const fetchLocationDetailSuccess = (location) => ({
	type: dbCLWActions.FETCH_LOCATION_DETAIL_SUCCESS,
	payload: location,
});

export const fetchLocationDetailFailure = (error) => ({
	type: dbCLWActions.FETCH_LOCATION_DETAIL_FAILURE,
	payload: error,
});

export const fetchApproveLocationSuccess = (approvedAt) => ({
	type: dbCLWActions.FETCH_APPROVE_LOCATION_SUCCESS,
	payload: approvedAt,
});

export const fetchApproveLocationFailure = (error) => ({
	type: dbCLWActions.FETCH_APPROVE_LOCATION_FAILURE,
	payload: error,
});
