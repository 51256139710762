import { combineActiontype } from "utils/helpers";

const page = "dbAmenities";

export const dbAmenitiesActions = combineActiontype(page, {
	FETCH_AMENITIES_SUCCESS: "FETCH_AMENITIES_SUCCESS",
	FETCH_AMENITIES_FAILURE: "FETCH_AMENITIES_FAILURE",

	FETCH_AMENITY_DETAIL_SUCCESS: "FETCH_AMENITY_DETAIL_SUCCESS",
	FETCH_AMENITY_DETAIL_FAILURE: "FETCH_AMENITY_DETAIL_FAILURE",
});

export const fetchAmenitiesSuccess = (amenities) => ({
	type: dbAmenitiesActions.FETCH_AMENITIES_SUCCESS,
	payload: amenities,
});

export const fetchAmenitiesFailure = (error) => ({
	type: dbAmenitiesActions.FETCH_AMENITIES_FAILURE,
	payload: error,
});

export const fetchAmenityDetailSuccess = (amenity) => ({
	type: dbAmenitiesActions.FETCH_AMENITY_DETAIL_SUCCESS,
	payload: amenity,
});

export const fetchAmenityDetailFailure = (error) => ({
	type: dbAmenitiesActions.FETCH_AMENITY_DETAIL_FAILURE,
	payload: error,
});
