/* eslint-disable react/prop-types */
import React from "react";

export const IconDatabaseSvg = ({ color = "#8E929A", ...props }) => {
	return (
		<svg width="18px" height="20px" viewBox="0 0 18 20" color={color} {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g transform="translate(-39.000000, -163.000000)" stroke="currentColor" strokeWidth="2">
					<g transform="translate(0.000000, 76.000000)">
						<g transform="translate(0.000000, 64.000000)">
							<g transform="translate(40.000000, 24.000000)">
								<ellipse cx="8.1" cy="2.7" rx="8.1" ry="2.7"></ellipse>
								<path d="M16.2,9 C16.2,10.494 12.6,11.7 8.1,11.7 C3.6,11.7 0,10.494 0,9"></path>
								<path d="M0,2.7 L0,15.3 C0,16.794 3.6,18 8.1,18 C12.6,18 16.2,16.794 16.2,15.3 L16.2,2.7"></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

// import { GenIcon } from "react-icons";

// const IconDatabaseSvg = (props) => {
// 	return GenIcon({
// 		tag: "svg",
// 		attr: {
// 			width: "18px",
// 			height: "20px",
// 			viewBox: "0 0 18 20",
// 		},
// 		child: [
// 			{
// 				tag: "g",
// 				attr: {
// 					stroke: "none",
// 					strokeWidth: "1",
// 					fill: "none",
// 					fillRule: "evenodd",
// 					strokeLinecap: "round",
// 					strokeLinejoin: "round",
// 				},
// 				child: [
// 					{
// 						tag: "g",
// 						attr: {
// 							transform: "translate(-39.000000, -163.000000)",
// 							stroke: "currentColor",
// 							strokeWidth: "1.5",
// 						},
// 						child: [
// 							{
// 								tag: "g",
// 								attr: {
// 									transform: "translate(0.000000, 76.000000)",
// 								},
// 								child: [
// 									{
// 										tag: "g",
// 										attr: {
// 											transform: "translate(0.000000, 64.000000)",
// 										},
// 										child: [
// 											{
// 												tag: "g",
// 												attr: { transform: "translate(40.000000, 24.000000)" },
// 												child: [
// 													{
// 														tag: "ellipse",
// 														attr: {
// 															cx: "8.1",
// 															cy: "2.7",
// 															rx: "8.1",
// 															ry: "2.7",
// 														},
// 														child: [],
// 													},
// 													{
// 														tag: "path",
// 														attr: {
// 															d: "M16.2,9 C16.2,10.494 12.6,11.7 8.1,11.7 C3.6,11.7 0,10.494 0,9",
// 														},
// 														child: [],
// 													},
// 													{
// 														tag: "path",
// 														attr: {
// 															d: "M0,2.7 L0,15.3 C0,16.794 3.6,18 8.1,18 C12.6,18 16.2,16.794 16.2,15.3 L16.2,2.7",
// 														},
// 														child: [],
// 													},
// 												],
// 											},
// 										],
// 									},
// 								],
// 							},
// 						],
// 					},
// 				],
// 			},
// 		],
// 	})(props);
// };

// export default IconDatabaseSvg;
