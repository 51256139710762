/* eslint-disable react/prop-types */
import React from "react";

export const IconCanceledSvg = ({ color = "#DD4558", ...props }) => {
	return (
		<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-1565.000000, -899.000000)">
					<g transform="translate(375.000000, 167.000000)">
						<g transform="translate(0.000000, 516.000000)">
							<g transform="translate(0.000000, 209.000000)">
								<g transform="translate(1190.000000, 7.000000)">
									<circle fill={color} cx="9" cy="9" r="9"></circle>
									<g transform="translate(5.000000, 5.000000)" fill="#FFFFFF" fillRule="nonzero">
										<polygon points="8 0.799946309 7.20005369 0 4 3.20005369 0.799946309 0 0 0.799946309 3.20005369 4 0 7.20005369 0.799946309 8 4 4.79994631 7.20005369 8 8 7.20005369 4.79994631 4"></polygon>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
