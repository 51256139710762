import { combineActiontype } from "utils/helpers";

const page = "adBackOfficeUsers";

export const adBackOfficeUsersActions = combineActiontype(page, {
	FETCH_BACK_OFFICE_USERS_SUCCESS: "FETCH_BACK_OFFICE_USERS_SUCCESS",
	FETCH_BACK_OFFICE_USERS_FAILURE: "FETCH_BACK_OFFICE_USERS_FAILURE",

	FETCH_BACK_OFFICE_USER_DETAIL_SUCCESS: "FETCH_BACK_OFFICE_USER_DETAIL_SUCCESS",
	FETCH_BACK_OFFICE_USER_DETAIL_FAILURE: "FETCH_BACK_OFFICE_USER_DETAIL_FAILURE",

	FETCH_BLOCK_BACK_OFFICE_USER_SUCCESS: "FETCH_BLOCK_BACK_OFFICE_USER_SUCCESS",
	FETCH_BLOCK_BACK_OFFICE_USER_FAILURE: "FETCH_BLOCK_BACK_OFFICE_USER_FAILURE",
});

export const fetchBackOfficeUsersSuccess = (backOfficeUsers) => ({
	type: adBackOfficeUsersActions.FETCH_BACK_OFFICE_USERS_SUCCESS,
	payload: backOfficeUsers,
});

export const fetchBackOfficeUsersFailure = (error) => ({
	type: adBackOfficeUsersActions.FETCH_BACK_OFFICE_USERS_FAILURE,
	payload: error,
});

export const fetchBackOfficeUserDetailSuccess = (userAcount) => ({
	type: adBackOfficeUsersActions.FETCH_BACK_OFFICE_USER_DETAIL_SUCCESS,
	payload: userAcount,
});

export const fetchBackOfficeUserDetailFailure = (error) => ({
	type: adBackOfficeUsersActions.FETCH_BACK_OFFICE_USER_DETAIL_FAILURE,
	payload: error,
});

export const fetchBlockBackOfficeUserSuccess = (blockedAt) => ({
	type: adBackOfficeUsersActions.FETCH_BLOCK_BACK_OFFICE_USER_SUCCESS,
	payload: blockedAt,
});

export const fetchBlockBackOfficeUserFailure = (error) => ({
	type: adBackOfficeUsersActions.FETCH_BLOCK_BACK_OFFICE_USER_FAILURE,
	payload: error,
});
