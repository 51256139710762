/* eslint-disable react/prop-types */
import React from "react";

export const IconCloseSvg = ({ color = "#7C7C7C", ...props }) => {
	return (
		<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10 1.87688L9.00007 0.887207L5 4.84622L0.999933 0.887207L0 1.87688L4.00007 5.83589L0 9.79491L0.999933 10.7846L5 6.82556L9.00007 10.7846L10 9.79491L5.99993 5.83589L10 1.87688Z"
				fill={color}
			/>
		</svg>
	);
};
