/* eslint-disable react/prop-types */
import { combineActiontype } from "utils/helpers";

const page = "messages";

export const messagesActions = combineActiontype(page, {
	FETCH_JET_OS_SUPPORT_CONVERSATIONS_SUCCESS: "FETCH_JET_OS_SUPPORT_CONVERSATIONS_SUCCESS",
	FETCH_MORE_JET_OS_SUPPORT_CONVERSATIONS_SUCCESS: "FETCH_MORE_JET_OS_SUPPORT_CONVERSATIONS_SUCCESS",

	FETCH_AVIAN_PAY_SUPPORT_CONVERSATIONS_SUCCESS: "FETCH_AVIAN_PAY_SUPPORT_CONVERSATIONS_SUCCESS",
	FETCH_MORE_AVIAN_PAY_SUPPORT_CONVERSATIONS_SUCCESS: "FETCH_MORE_AVIAN_PAY_SUPPORT_CONVERSATIONS_SUCCESS",

	FETCH_AVIAN_PAY_DISPUTE_CONVERSATIONS_SUCCESS: "FETCH_AVIAN_PAY_DISPUTE_CONVERSATIONS_SUCCESS",
	FETCH_MORE_AVIAN_PAY_DISPUTE_CONVERSATIONS_SUCCESS: "FETCH_MORE_AVIAN_PAY_DISPUTE_CONVERSATIONS_SUCCESS",

	FETCH_CONVERSATION_DETAIL_SUCCESS: "FETCH_CONVERSATION_DETAIL_SUCCESS",
	FETCH_MORE_CONVERSATION_DETAIL_SUCCESS: "FETCH_MORE_CONVERSATION_DETAIL_SUCCESS",

	LOCAL_ADD_NEW_MESSAGE: "LOCAL_ADD_NEW_MESSAGE",
	LOCAL_UPDATE_MESSAGE: "LOCAL_UPDATE_MESSAGE",

	FETCH_SOCKET_NEW_MESSAGE: "FETCH_SOCKET_NEW_MESSAGE",

	FETCH_CONVERSATIONS_UNREAD_LIST: "FETCH_CONVERSATIONS_UNREAD_LIST",
});

export const fetchJetOSSupportConversationsSuccess = (conversations) => ({
	type: messagesActions.FETCH_JET_OS_SUPPORT_CONVERSATIONS_SUCCESS,
	payload: conversations,
});

export const fetchMoreJetOSSupportConversationsSuccess = (conversations) => ({
	type: messagesActions.FETCH_MORE_JET_OS_SUPPORT_CONVERSATIONS_SUCCESS,
	payload: conversations,
});

export const fetchAvianPaySupportConversationsSuccess = (conversations) => ({
	type: messagesActions.FETCH_AVIAN_PAY_SUPPORT_CONVERSATIONS_SUCCESS,
	payload: conversations,
});

export const fetchMoreAvianPaySupportConversationsSuccess = (conversations) => ({
	type: messagesActions.FETCH_MORE_AVIAN_PAY_SUPPORT_CONVERSATIONS_SUCCESS,
	payload: conversations,
});

export const fetchAvianPayDisputeConversationsSuccess = (conversations) => ({
	type: messagesActions.FETCH_AVIAN_PAY_DISPUTE_CONVERSATIONS_SUCCESS,
	payload: conversations,
});

export const fetchMoreAvianPayDisputeConversationsSuccess = (conversations) => ({
	type: messagesActions.FETCH_MORE_AVIAN_PAY_DISPUTE_CONVERSATIONS_SUCCESS,
	payload: conversations,
});

export const fetchConversationDetailSuccess = (conversation) => ({
	type: messagesActions.FETCH_CONVERSATION_DETAIL_SUCCESS,
	payload: conversation,
});

export const fetchMoreConversationDetailSuccess = (conversation) => ({
	type: messagesActions.FETCH_MORE_CONVERSATION_DETAIL_SUCCESS,
	payload: conversation,
});

export const localAddNewMessage = (message) => ({
	type: messagesActions.LOCAL_ADD_NEW_MESSAGE,
	payload: message,
});

export const localUpdateMessage = (message) => ({
	type: messagesActions.LOCAL_UPDATE_MESSAGE,
	payload: message,
});

export const fetchSocketNewMessage = (message) => ({
	type: messagesActions.FETCH_SOCKET_NEW_MESSAGE,
	payload: message,
});

export const fetchConversationsUnreadListSuccess = (payload) => ({
	type: messagesActions.FETCH_CONVERSATIONS_UNREAD_LIST,
	payload,
});
