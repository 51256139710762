/* eslint-disable react/prop-types */
import React from "react";

export const IconTrashSvg = ({ color = "#8E929A", ...props }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 24 24" {...props}>
			<g strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" fill="none" stroke={color}>
				<path d="M3 6h18" />
				<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
			</g>
		</svg>
	);
};
