import React from "react";

export const IconRefundSvg = (props) => (
	<svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={16} cy={16} r={16} fill="#F4EFFC" />
		<g clipPath="url(#a)" fill="#9060DF">
			<path d="M21.326 11.446a7.364 7.364 0 0 0-4.27-2.184l.462-.462a.469.469 0 1 0-.662-.663l-1.188 1.187a.469.469 0 0 0 0 .663l1.188 1.187a.467.467 0 0 0 .662 0 .469.469 0 0 0 0-.663l-.265-.265c1.288.252 2.47.89 3.398 1.852a6.436 6.436 0 0 1 1.818 4.495A6.476 6.476 0 0 1 16 23.063a6.476 6.476 0 0 1-6.469-6.47c0-1.36.418-2.663 1.208-3.765a6.45 6.45 0 0 1 3.085-2.329.469.469 0 0 0-.315-.883 7.384 7.384 0 0 0-3.532 2.666 7.356 7.356 0 0 0-1.383 4.312c0 1.978.77 3.838 2.169 5.237A7.358 7.358 0 0 0 16 24c1.978 0 3.838-.77 5.237-2.17a7.358 7.358 0 0 0 2.17-5.236 7.37 7.37 0 0 0-2.081-5.148Z" />
			<path d="M11.184 16.593A4.822 4.822 0 0 0 16 21.41a4.822 4.822 0 0 0 4.817-4.817A4.822 4.822 0 0 0 16 11.777a4.822 4.822 0 0 0-4.816 4.816Zm5.104.418c-.675-.239-1.066-.427-1.35-.65-.348-.273-.508-.746-.416-1.233.1-.529.468-.95.962-1.099l.02-.005v-.19a.469.469 0 0 1 .938 0v.16c.327.076.556.226.648.295a.469.469 0 0 1-.561.751.855.855 0 0 0-.774-.113c-.237.071-.299.305-.312.374-.026.138.004.268.074.323.246.192.686.362 1.084.503.728.257 1.133.932 1.007 1.678a1.686 1.686 0 0 1-.52.958 1.611 1.611 0 0 1-.646.365v.224a.469.469 0 0 1-.938 0v-.178c-.311-.037-.572-.133-.922-.362a.469.469 0 1 1 .514-.785c.324.213.447.232.874.23a.72.72 0 0 0 .714-.607.545.545 0 0 0-.396-.64Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" transform="translate(8 8)" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
