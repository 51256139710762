import { combineActiontype } from "utils/helpers";

const page = "dbAircrafts";

export const dbAircraftsActions = combineActiontype(page, {
	FETCH_ACTIVE_AIRCRAFTS_SUCCESS: "FETCH_ACTIVE_AIRCRAFTS_SUCCESS",
	FETCH_ACTIVE_AIRCRAFTS_FAILURE: "FETCH_ACTIVE_AIRCRAFTS_FAILURE",

	FETCH_PENDING_AIRCRAFTS_SUCCESS: "FETCH_PENDING_AIRCRAFTS_SUCCESS",
	FETCH_PENDING_AIRCRAFTS_FAILURE: "FETCH_PENDING_AIRCRAFTS_FAILURE",

	FETCH_AIRCRAFT_DETAIL_SUCCESS: "FETCH_AIRCRAFT_DETAIL_SUCCESS",
	FETCH_AIRCRAFT_DETAIL_FAILURE: "FETCH_AIRCRAFT_DETAIL_FAILURE",

	FETCH_ACTIVATE_AIRCRAFT_SUCCESS: "FETCH_ACTIVATE_AIRCRAFT_SUCCESS",
	FETCH_ACTIVATE_AIRCRAFT_FAILURE: "FETCH_ACTIVATE_AIRCRAFT_FAILURE",
});

export const fetchActiveAircraftsSuccess = (aircarfts) => ({
	type: dbAircraftsActions.FETCH_ACTIVE_AIRCRAFTS_SUCCESS,
	payload: aircarfts,
});

export const fetchActiveAircraftsFailure = (error) => ({
	type: dbAircraftsActions.FETCH_ACTIVE_AIRCRAFTS_FAILURE,
	payload: error,
});

export const fetchPendingAircraftsSuccess = (aircarfts) => ({
	type: dbAircraftsActions.FETCH_PENDING_AIRCRAFTS_SUCCESS,
	payload: aircarfts,
});

export const fetchPendingAircraftsFailure = (error) => ({
	type: dbAircraftsActions.FETCH_PENDING_AIRCRAFTS_FAILURE,
	payload: error,
});

export const fetchAircraftDetailSuccess = (aircarft) => ({
	type: dbAircraftsActions.FETCH_AIRCRAFT_DETAIL_SUCCESS,
	payload: aircarft,
});

export const fetchAircraftDetailFailure = (error) => ({
	type: dbAircraftsActions.FETCH_AIRCRAFT_DETAIL_FAILURE,
	payload: error,
});

export const fetchActivateAircraftSuccess = (activatedAt) => ({
	type: dbAircraftsActions.FETCH_ACTIVATE_AIRCRAFT_SUCCESS,
	payload: activatedAt,
});

export const fetchActivateAircraftFailure = (error) => ({
	type: dbAircraftsActions.FETCH_ACTIVATE_AIRCRAFT_FAILURE,
	payload: error,
});
