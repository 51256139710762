import produce from "immer";
import { dbFlightsActions } from "./actions";

const INITIAL_STATE = {
	flights: {},
	flightDetail: {},
	error: null,
};

const dbFlightsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbFlightsActions.FETCH_FLIGHTS_SUCCESS:
			draft.flights = action.payload;
			break;
		case dbFlightsActions.FETCH_FLIGHT_DETAIL_SUCCESS:
			draft.flightDetail = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbFlightsReducer;
