/* eslint-disable react/prop-types */
import React from "react";

export const IconSucceedSvg = ({ ...props }) => {
	return (
		<svg width={props.width || "56px"} height={props.height || "56px"} viewBox="0 0 56 56" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g>
					<circle fill="#7ED321" cx="28" cy="28" r="28"></circle>
					<g
						transform="translate(16.000000, 18.000000)"
						stroke="#FFFFFF"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3"
					>
						<polyline points="25 0 7.5 20 0 12.5"></polyline>
					</g>
				</g>
			</g>
		</svg>
	);
};
