import React from "react";

export const IconIncomingSvg = (props) => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M8 2.5v11M3.5 9 8 13.5 12.5 9"
			stroke="#12B04A"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
