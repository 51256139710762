import React from "react";

export const IconTransactionSvg = (props) => (
	<svg width={19} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M17.713 10.363a.25.25 0 0 1-.089.191l-4.193 3.557a.25.25 0 0 1-.412-.19v-1.53H6.156a.25.25 0 1 1 0-.5h7.113a.25.25 0 0 1 .25.25v1.24l3.557-3.018-3.557-3.016v1.238a.25.25 0 0 1-.25.25H6.406v1.528a.25.25 0 0 1-.412.191L1.801 6.997a.25.25 0 0 1 0-.38l4.193-3.558a.25.25 0 0 1 .412.191v1.528h6.863a.25.25 0 0 1 0 .5H6.156a.25.25 0 0 1-.25-.25V3.79L2.349 6.807l3.557 3.017V8.585a.25.25 0 0 1 .25-.25h6.863V6.807a.25.25 0 0 1 .412-.19l4.193 3.556a.25.25 0 0 1 .089.19Z"
			fill="#8E929A"
			stroke="#8E929A"
			strokeWidth={0.4}
		/>
	</svg>
);
