/* eslint-disable react/prop-types */
import React from "react";

export const IconLayoutSvg = ({ color = "#8E929A", ...props }) => {
	return (
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M2.09055 19H18.7805C19.0865 18.9987 19.3795 18.8766 19.5958 18.6603C19.8121 18.4439 19.9342 18.1509 19.9355 17.845V2.155C19.9342 1.84908 19.8121 1.55607 19.5958 1.33975C19.3795 1.12343 19.0865 1.00132 18.7805 1H2.09055C1.78463 1.00132 1.49161 1.12343 1.27529 1.33975C1.05897 1.55607 0.936863 1.84908 0.935547 2.155V17.845C0.936863 18.1509 1.05897 18.4439 1.27529 18.6603C1.49161 18.8766 1.78463 18.9987 2.09055 19ZM2.09055 18C2.04944 18 2.01001 17.9837 1.98095 17.9546C1.95188 17.9255 1.93555 17.8861 1.93555 17.845V5.5H6.43555V18H2.09055ZM18.9355 17.845C18.9355 17.8861 18.9192 17.9255 18.8901 17.9546C18.8611 17.9837 18.8217 18 18.7805 18H7.43555V5.5H18.9355V17.845ZM1.93555 2.155C1.93555 2.11389 1.95188 2.07447 1.98095 2.0454C2.01001 2.01633 2.04944 2 2.09055 2H18.7805C18.8217 2 18.8611 2.01633 18.8901 2.0454C18.9192 2.07447 18.9355 2.11389 18.9355 2.155V4.5H1.93555V2.155Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
		</svg>
	);
};
