import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";

import rootReducer from "./rootReducer";
import localforage from "./localforage";

export const PERSIST_KEY = "root";

const persistConfig = {
	key: PERSIST_KEY,
	storage: localforage,
	whitelist: [],
	blacklist: [],
};

const composeEnhancers = process.env.NODE_ENV === "production" ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const persistedReducer = persistReducer(persistConfig, rootReducer);
const enhancer =
	typeof composeEnhancers === "function" ? composeEnhancers(applyMiddleware(thunk)) : compose(applyMiddleware(thunk));

const store = createStore(persistedReducer, enhancer);

const persistor = persistStore(store);

export { store, persistor };
