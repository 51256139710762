import { combineActiontype } from "utils/helpers";

const page = "dbGenericRoles";

export const dbGenericRolesActions = combineActiontype(page, {
	FETCH_GENERIC_ROLES_SUCCESS: "FETCH_GENERIC_ROLES_SUCCESS",
	FETCH_GENERIC_ROLES_FAILURE: "FETCH_GENERIC_ROLES_FAILURE",

	FETCH_PERMISSIONS_SUCCESS: "FETCH_PERMISSIONS_SUCCESS",
	FETCH_PERMISSIONS_FAILURE: "FETCH_PERMISSIONS_FAILURE",
});

export const fetchGenericRolesSuccess = (genericRoles) => ({
	type: dbGenericRolesActions.FETCH_GENERIC_ROLES_SUCCESS,
	payload: genericRoles,
});

export const fetchGenericRolesFailure = (error) => ({
	type: dbGenericRolesActions.FETCH_GENERIC_ROLES_FAILURE,
	payload: error,
});

export const fetchPermissionsSuccess = (permissions) => ({
	type: dbGenericRolesActions.FETCH_PERMISSIONS_SUCCESS,
	payload: permissions,
});

export const fetchPermissionsFailure = (error) => ({
	type: dbGenericRolesActions.FETCH_PERMISSIONS_FAILURE,
	payload: error,
});
