import * as React from "react";

export const DepartureWindowSvg = (props) => (
	<svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M17.174 12.071v1.64a4.587 4.587 0 0 1-4.586 4.589H9.384A4.587 4.587 0 0 1 4.8 13.712v-2.63M4.799 10.104V6.332C4.799 3.83 6.852 1.8 9.384 1.8h3.204c2.533 0 4.586 2.03 4.586 4.532v4.75"
			stroke="#19C0FF"
			strokeWidth={0.8}
			strokeMiterlimit={10}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.113 13.477V6.523a3.36 3.36 0 0 1 3.36-3.36h2.816a3.36 3.36 0 0 1 3.36 3.36v6.954a3.36 3.36 0 0 1-3.36 3.36H9.473a3.36 3.36 0 0 1-3.36-3.36Z"
			stroke="#19C0FF"
			strokeWidth={0.8}
			strokeMiterlimit={10}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="m15.64 13.68-4.629-3.568a.537.537 0 0 0-.328-.112H8.971c-.35 0-.606.33-.52.669.417 1.655 1.473 5.828 1.554 6.072l.391.096h1.89a3.36 3.36 0 0 0 3.354-3.156Z"
			stroke="#19C0FF"
			strokeWidth={0.8}
			strokeMiterlimit={10}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.288 13.978H8.146a.56.56 0 0 1 0-1.12h.858l.284 1.12ZM9.22 6.342a.86.86 0 0 0-.297.052A1.183 1.183 0 0 0 7.92 5.3a1.285 1.285 0 0 0-1.083-.86 3.346 3.346 0 0 0-.724 2.083v1.314l.031-.03a.859.859 0 0 0 1.23 0 .859.859 0 0 0 1.23 0 .862.862 0 1 0 .615-1.465ZM11.246 5.156h1.445M11.613 6.072h2.534"
			stroke="#19C0FF"
			strokeWidth={0.8}
			strokeMiterlimit={10}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
