import produce from "immer";
import { dbAircraftsActions } from "./actions";

const INITIAL_STATE = {
	activeAircrafts: {},
	pendingAircrafts: {},
	aircraftDetail: {},
	error: null,
};

const dbAircraftsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbAircraftsActions.FETCH_ACTIVE_AIRCRAFTS_SUCCESS:
			draft.activeAircrafts = action.payload;
			break;
		case dbAircraftsActions.FETCH_PENDING_AIRCRAFTS_SUCCESS:
			draft.pendingAircrafts = action.payload;
			break;
		case dbAircraftsActions.FETCH_AIRCRAFT_DETAIL_SUCCESS:
			draft.aircraftDetail = action.payload;
			break;
		case dbAircraftsActions.FETCH_ACTIVATE_AIRCRAFT_SUCCESS:
			draft.aircraftDetail.verified_at = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbAircraftsReducer;
