import { combineReducers } from "redux";
import {
	adPayoutsReducer,
	adInvoicesReducer,
	adBackOfficeUsersReducer,
	commonReducer,
	dbAircraftsReducer,
	dbBookingsReducer,
	dbCLWReducer,
	dbFlightsReducer,
	messagesReducer,
	dbSessionsReducer,
	dbUserAccountsReducer,
	mnAirportsReducer,
	mnAircraftCategoriesReducer,
	mnGenericAircraftsReducer,
	signInReducer,
	notificationsReducer,
	dbLogsReducer,
	dbAmenitiesReducer,
	dbWorldWideAircraftsReducer,
	dbWorldWideCompaniesReducer,
	dbGenericSidebarsReducer,
	dbGenericRolesReducer,
	apAccountsReducer,
} from "stores";

const rootReducer = combineReducers({
	adPayoutsReducer,
	adInvoicesReducer,
	adBackOfficeUsersReducer,
	commonReducer,
	dbAircraftsReducer,
	dbBookingsReducer,
	dbCLWReducer,
	dbFlightsReducer,
	messagesReducer,
	dbSessionsReducer,
	dbUserAccountsReducer,
	mnAirportsReducer,
	mnAircraftCategoriesReducer,
	mnGenericAircraftsReducer,
	signInReducer,
	notificationsReducer,
	dbLogsReducer,
	dbAmenitiesReducer,
	dbWorldWideAircraftsReducer,
	dbWorldWideCompaniesReducer,
	dbGenericSidebarsReducer,
	dbGenericRolesReducer,
	apAccountsReducer,
});

export default rootReducer;
