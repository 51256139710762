/* eslint-disable react/prop-types */
import React from "react";

export const IconConfirmedSvg = ({ color = "#7ED321", ...props }) => {
	return (
		<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-1565.000000, -782.000000)">
					<g transform="translate(375.000000, 167.000000)">
						<g transform="translate(0.000000, 516.000000)">
							<g transform="translate(0.000000, 92.000000)">
								<g transform="translate(1190.000000, 7.000000)">
									<path
										d="M9,0 C13.9705627,-9.13077564e-16 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 6.08718376e-16,13.9705627 0,9 C-6.08718376e-16,4.02943725 4.02943725,9.13077564e-16 9,0 Z"
										fill={color}
									></path>
									<path
										d="M7.55523396,12.3516643 C7.43945972,12.4674385 7.27544621,12.563917 7.13072841,12.563917 C6.98601061,12.563917 6.82199711,12.4626146 6.70139894,12.3468403 L4,9.64544139 L4.85865895,8.78678244 L7.13555234,11.0636758 L13.1558128,5 L14,5.87313073 L7.55523396,12.3516643 Z"
										fill="#FFFFFF"
										fillRule="nonzero"
									></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
