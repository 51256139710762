/* eslint-disable react/prop-types */
import React from "react";

export const IconBaggageSvg = ({ color = "#65686F", ...props }) => {
	return (
		<svg width="18px" height="15px" viewBox="0 0 18 15" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-388.000000, -15.000000)" fill={color} fillRule="nonzero">
					<g transform="translate(347.000000, 0.000000)">
						<g transform="translate(41.000000, 15.000000)">
							<path d="M15.5069346,2.50567113 L11.3118178,2.50567113 L11.3118178,1.44355418 C11.3118178,0.647583284 10.6658932,0 9.87189088,0 L8.12821459,0 C7.33428255,0 6.68832287,0.647583284 6.68832287,1.44355418 L6.68832287,2.50567113 L2.49303029,2.50567113 C1.11835328,2.50567113 0,3.6268964 0,5.00506839 L0,11.5812127 C0,12.9593846 1.11835328,14.0806452 2.49303029,14.0806452 L15.5069697,14.0806452 C16.8816116,14.0806452 18,12.9594199 18,11.5812127 L18,5.00506839 C18,3.6268964 16.8816116,2.50567113 15.5069346,2.50567113 Z M8.12828491,1.06775638 L9.87196119,1.06775638 C10.0786092,1.06775638 10.2467964,1.23634022 10.2467964,1.44351893 L10.2467964,2.50563588 L7.75348486,2.50563588 L7.75348486,1.44351893 L7.7534497,1.44351893 C7.7534497,1.23630497 7.92160172,1.06775638 8.12828491,1.06775638 L8.12828491,1.06775638 Z M3.95953524,13.0128183 L2.49303029,13.0128183 C1.70567245,13.0128183 1.06509167,12.3705925 1.06509167,11.5812127 L1.06509167,5.00506839 C1.06509167,4.21568858 1.70567245,3.57346276 2.49303029,3.57346276 L3.95953524,3.57346276 L3.95953524,13.0128183 L3.95953524,13.0128183 Z M12.9756192,13.0128183 L5.02455659,13.0128183 L5.02455659,3.57346276 L12.9756192,3.57346276 L12.9756192,13.0128183 L12.9756192,13.0128183 Z M16.9348732,11.5812127 C16.9348732,12.3705925 16.2942924,13.0128183 15.5069346,13.0128183 L14.0406757,13.0128183 L14.0406757,3.57346276 L15.5069346,3.57346276 C16.2942924,3.57346276 16.9348732,4.21568858 16.9348732,5.00506839 L16.9348732,11.5812127 L16.9348732,11.5812127 Z"></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
