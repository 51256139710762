import produce from "immer";
import { mnAirportsActions } from "./actions";

const INITIAL_STATE = {
	airports: {},
	airportDetail: {},
	error: null,
};

const mnAirportsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case mnAirportsActions.FETCH_AIRPORTS_SUCCESS:
			draft.airports = action.payload;
			break;
		case mnAirportsActions.FETCH_AIRPORT_DETAIL_SUCCESS:
			draft.airportDetail = action.payload;
		default:
			break;
	}
	return draft;
});

export default mnAirportsReducer;
