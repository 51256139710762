import produce from "immer";
import { mnAircraftCategoriesActions } from "./actions";

const INITIAL_STATE = {
	aircraftCategories: {},
	aircraftCategory: {},
	error: null,
};

const mnAircraftCategoriesReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case mnAircraftCategoriesActions.FETCH_AIRCRAFT_CATEGORIES_SUCCESS:
			draft.aircraftCategories = action.payload;
			break;
		case mnAircraftCategoriesActions.FETCH_AIRCRAFT_CATEGORY_DETAIL_SUCCESS:
			draft.aircraftCategory = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default mnAircraftCategoriesReducer;
