/* eslint-disable react/prop-types */
import React from "react";

export const IconServerSvg = ({ color = "#8E929A", ...props }) => {
	return (
		<svg width="20px" height="20px" viewBox="0 0 20 20" color={color} {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g transform="translate(-39.000000, -562.000000)" stroke="currentColor" strokeWidth="2">
					<g transform="translate(0.000000, 76.000000)">
						<g transform="translate(40.000004, 484.174393)">
							<g transform="translate(0.000000, 3.000000)">
								<rect x="0" y="0" width="18" height="7.2" rx="2"></rect>
								<rect x="0" y="10.8" width="18" height="7.2" rx="2"></rect>
								<line x1="3.6" y1="3.6" x2="3.609" y2="3.6" id="Path"></line>
								<line x1="3.6" y1="14.4" x2="3.609" y2="14.4" id="Path"></line>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
