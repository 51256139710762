/* eslint-disable react/prop-types */
import React from "react";

export const IconBellSvg = ({ color = "#6c6f76", ...props }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="19px" viewBox="0 0 16 19" version="1.1" {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-1848.000000, -31.000000)" fill={color} fillRule="nonzero">
					<g transform="translate(330.000000, 0.000000)">
						<g transform="translate(1502.000000, 16.000000)">
							<g transform="translate(16.000000, 15.000000)">
								<path d="M8.04545455,3.29413636 C8.36740909,3.29413636 9.13231818,3.49772727 9.13231818,3.49772727 C11.2045455,3.97259091 12.6631364,5.88131818 12.6631364,8.03636364 L12.6631364,13.8369091 L13.0868182,14.2625455 L13.4408182,14.6181364 L2.55918182,14.6181364 L2.91318182,14.2625455 L3.33686364,13.8369091 L3.33686364,8.03636364 C3.33686364,5.88136364 4.79545455,3.97259091 6.86768182,3.49772727 C6.86768182,3.49772727 7.68781818,3.29413636 7.95454545,3.29413636 L8.04545455,3.29413636 Z M8,4.08562073e-14 C7.19990909,4.08562073e-14 6.58822727,0.614454545 6.58822727,1.41818182 L6.58822727,2.07995455 C3.90572727,2.69463636 1.88231818,5.15272727 1.88231818,8.03636364 L1.88231818,13.2363636 L0,15.1272727 L0,16.0727273 L16,16.0727273 L16,15.1272727 L14.1176818,13.2363636 L14.1176818,8.03636364 C14.1176818,5.15272727 12.0942273,2.69463636 9.41177273,2.07995455 L9.41177273,1.41818182 C9.41177273,0.614454545 8.80009091,4.08562073e-14 8,4.08562073e-14 Z M9.88231818,17.0181818 L6.11763636,17.0181818 C6.11763636,18.0582273 6.96459091,18.9090909 8,18.9090909 C9.03540909,18.9090909 9.88231818,18.0582273 9.88231818,17.0181818 Z" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
