/* eslint-disable react/prop-types */
import React from "react";

export const IconAwaitingRefundSvg = ({ ...props }) => {
	return (
		<svg width="18px" height="18px" viewBox="0 0 64 64" {...props}>
			<defs>
				<linearGradient id="a" x1="57.99" x2="57.99" y1="63.945" y2=".055" gradientUnits="userSpaceOnUse">
					<stop offset="0" stopColor="#fd5900" />
					<stop offset="1" stopColor="#fedc00" />
				</linearGradient>
				<linearGradient id="b" x1="52.805" x2="52.805" y1="63.945" y2=".055" xlinkHref="#a" />
				<linearGradient id="c" x1="30" x2="30" y1="63.945" y2=".055" xlinkHref="#a" />
				<linearGradient id="d" x1="58.772" x2="58.772" y1="63.945" y2=".055" xlinkHref="#a" />
				<linearGradient id="e" x1="29.5" x2="29.5" y1="63.945" y2=".055" xlinkHref="#a" />
				<linearGradient id="f" x1="34.5" x2="34.5" y1="63.945" y2=".055" xlinkHref="#a" />
				<linearGradient id="g" x1="32" x2="32" y1="63.945" y2=".055" xlinkHref="#a" />
			</defs>
			<path
				fill="url(#a)"
				d="M57.45,42a1.02,1.02,0,0,1-.336-.058,1,1,0,0,1-.606-1.278,25.674,25.674,0,0,0,1.039-3.851,1,1,0,0,1,1.965.374,27.7,27.7,0,0,1-1.12,4.149A1,1,0,0,1,57.45,42Z"
			/>
			<path
				fill="url(#b)"
				d="M51.17,52a1,1,0,0,1-.71-1.7,25.926,25.926,0,0,0,3.148-3.851,1,1,0,1,1,1.664,1.11A27.86,27.86,0,0,1,51.88,51.7,1,1,0,0,1,51.17,52Z"
			/>
			<path
				fill="url(#c)"
				d="M55.413,9.09a1,1,0,0,0-1.072.157L51.4,11.825a27.986,27.986,0,1,0-5.915,44.722,1,1,0,0,0-.962-1.754,26.008,26.008,0,1,1,5.366-41.647l-3.544,3.1A1,1,0,0,0,47,18h8a1,1,0,0,0,1-1V10A1,1,0,0,0,55.413,9.09Z"
			/>
			<path
				fill="url(#d)"
				d="M59,32a1,1,0,0,1-1-.963,24.924,24.924,0,0,0-.438-3.846,1,1,0,0,1,1.963-.382A27.009,27.009,0,0,1,60,30.963,1,1,0,0,1,59.037,32Z"
			/>
			<path fill="url(#e)" d="M28,28a3,3,0,0,0,3,3V25A3,3,0,0,0,28,28Z" />
			<path fill="url(#f)" d="M36,36a3,3,0,0,0-3-3v6A3,3,0,0,0,36,36Z" />
			<path
				fill="url(#g)"
				d="M32,15A17,17,0,1,0,49,32,17.019,17.019,0,0,0,32,15Zm6,14a1,1,0,0,1-2,0V28a3,3,0,0,0-3-3v6a5,5,0,0,1,0,10v1a1,1,0,0,1-2,0V41a5.006,5.006,0,0,1-5-5V35a1,1,0,0,1,2,0v1a3,3,0,0,0,3,3V33a5,5,0,0,1,0-10V22a1,1,0,0,1,2,0v1a5.006,5.006,0,0,1,5,5Z"
			/>
		</svg>
	);
};
