import React from "react";
import { Navigate } from "react-router-dom";

import Loading from "components/Loading";

export const PATH_NAVIGATE_COMPANY_SUGGESTIONS = (companyId) => `https://dev.avianjets.com/company/${companyId}`;

export const COMPANY_TAB_NAME = {
	COMPANIES: "companies",
	LOCATIONS: "locations",
	WHITELABELS: "whitelabels",
	COMPANIES_SUGGESTIONS: "companies-suggestions",
};

export const AVIAN_PAY_ACCOUNT_TAB_NAME = {
	OVERVIEW: "overview",
	STATEMENT: "statement",
	TRANSFERS: "transfers",
	DISPUTES: "disputes",
	PAYOUTS: "payouts",
};

export const AIRCRAFT_TAB_NAME = {
	ACTIVE_AIRCRAFTS: "active_aircrafts",
	PENDING_AIRCRAFTS: "pending_aircrafts",
};

const MainLayout = React.lazy(() => import("features/MainLayout"));
const AuthLayout = React.lazy(() => import("features/AuthLayout"));
const CLWLayout = React.lazy(() => import("features/CLWLayout"));
const AircraftsLayout = React.lazy(() => import("features/AircraftsLayout"));
const DashboardPage = React.lazy(() => import("pages/dashboard"));
const DBAircraftDetailPage = React.lazy(() => import("pages/database/dbAircraftDetail"));
const DBWorldWideAircraftsPage = React.lazy(() => import("pages/database/dbWorldWideAircrafts"));
const DBWorldWideAircraftDetailPage = React.lazy(() => import("pages/database/dbWorldWideAircraftDetail"));
const DBWorldWideCompaniesPage = React.lazy(() => import("pages/database/dbWorldWideCompanies"));
const DBWorldWideCompanyDetailPage = React.lazy(() => import("pages/database/dbWorldWideCompanyDetail"));
const DBBookingsPage = React.lazy(() => import("pages/database/dbBookings"));
const DBBookingDetailPage = React.lazy(() => import("pages/database/dbBookingDetail"));
const DBNewCompanyPage = React.lazy(() => import("pages/database/dbNewCompany"));
const DBGenericSidebarsPage = React.lazy(() => import("pages/database/dbGenericSidebars"));
const DBGenericRolesPage = React.lazy(() => import("pages/database/dbGenericRoles"));
const DBNewGenericRolePage = React.lazy(() => import("pages/database/dbNewGenericRole"));
const DBCompanyDetailPage = React.lazy(() => import("pages/database/dbCompanyDetail"));
const DBWhitelabelDetailPage = React.lazy(() => import("pages/database/dbWhitelabelDetail"));
const DBLocationDetailPage = React.lazy(() => import("pages/database/dbLocationDetail"));
const DBFlightsPage = React.lazy(() => import("pages/database/dbFlights"));
const DBAmenitiesPage = React.lazy(() => import("pages/database/dbAmenities"));
const DBAmenityDetailPage = React.lazy(() => import("pages/database/dbAmenityDetail"));
const DBNewAmenityPage = React.lazy(() => import("pages/database/dbNewAmenity"));
const DBFlightDetailPage = React.lazy(() => import("pages/database/dbFlightDetail"));
const DBSessionsPage = React.lazy(() => import("pages/database/dbSessions"));
const DBLogsPage = React.lazy(() => import("pages/database/dbLogs"));
const DBLogDetailPage = React.lazy(() => import("pages/database/dbLogDetail"));
const DBSessionDetailPage = React.lazy(() => import("pages/database/dbSessionDetail"));
const DBUserAccountsPage = React.lazy(() => import("pages/database/dbUserAccounts"));
const DBUserAccountDetailPage = React.lazy(() => import("pages/database/dbUserAccountDetail"));
const APDashboard = React.lazy(() => import("pages/avianPay/apDashboard"));
const APAccountDetail = React.lazy(() => import("pages/avianPay/apAccountDetail"));
const APAccountJetCard = React.lazy(() => import("pages/avianPay/apJetCard"));
const APAccounts = React.lazy(() => import("pages/avianPay/apAccounts"));
const APTransferRequests = React.lazy(() => import("pages/avianPay/apTransferRequests"));
const MessagesPage = React.lazy(() => import("pages/messages"));
const MNAirportsPage = React.lazy(() => import("pages/maintenance/mnAirports"));
const MNAirportDetailPage = React.lazy(() => import("pages/maintenance/mnAirportDetail"));
const MNGenericAircraftsPage = React.lazy(() => import("pages/maintenance/mnGenericAircrafts"));
const MNNewGenericAicraftPage = React.lazy(() => import("pages/maintenance/mnNewGenericAircraft"));
const MNNewAicraftCategoryPage = React.lazy(() => import("pages/maintenance/mnNewAircraftCategory"));
const MNGenericAircraftDetailPage = React.lazy(() => import("pages/maintenance/mnGenericAircraftDetail"));
const MNAircraftCategoriesPage = React.lazy(() => import("pages/maintenance/mnAircraftCategories"));
const MNAircraftCategoryDetailPage = React.lazy(() => import("pages/maintenance/mnAircraftCategoryDetail"));
const SettingsPage = React.lazy(() => import("pages/settings"));
const NotificationsPage = React.lazy(() => import("pages/notifications"));
const ActivateUserPage = React.lazy(() => import("pages/activateUser"));
const AdministrationPayoutsPage = React.lazy(() => import("pages/administration/administrationPayouts"));
const AdministrationInvoicesPage = React.lazy(() => import("pages/administration/administrationInvoices"));
const AdministrationJetCardsPage = React.lazy(() => import("pages/administration/administrationJetCards"));
const AdministrationPaymentMethodsPage = React.lazy(() => import("pages/administration/administrationPaymentMethods"));
const AdministrationSubcriptionsPage = React.lazy(() => import("pages/administration/administrationSubcriptions"));
const AdministrationSystemSettingsPage = React.lazy(() => import("pages/administration/administrationSystemSettings"));
const AdministrationBackOfficeUsersPage = React.lazy(() =>
	import("pages/administration/administrationBackOfficeUsers")
);
const AdministrationBackOfficeUserDetailPage = React.lazy(() =>
	import("pages/administration/administrationBackOfficeUserDetail")
);
const AdministrationNewBackOfficeUserPage = React.lazy(() =>
	import("pages/administration/administrationNewBackOfficeUser")
);
const AdministrationPayoutDetailPage = React.lazy(() => import("pages/administration/administrationPayoutDetail"));
const AdministrationInvoiceDetailPage = React.lazy(() => import("pages/administration/administrationInvoiceDetail"));
const AdministrationJetCardDetailPage = React.lazy(() => import("pages/administration/administrationJetCardDetail"));
const AdministrationPaymentMethodDetailPage = React.lazy(() =>
	import("pages/administration/administrationPaymentMethodDetail")
);
const AdministrationSubcriptionDetailPage = React.lazy(() =>
	import("pages/administration/administrationSubcriptionDetail")
);
const MarketplacePage = React.lazy(() => import("pages/marketplace"));
const NotfoundPage = React.lazy(() => import("pages/notfound"));
const SignInPage = React.lazy(() => import("pages/signIn"));

export const PATH_BASE = "/";
export const PATH_DASHBOARD = "/dashboard";

export const CLW_LAYOUT_KEY = "clw";

export const PATH_DATABASE = "/database";
export const PATH_DATABASE_GENERIC_SIDEBARS = "/database/company-menu";
export const PATH_DATABASE_GENERIC_ROLES = "/database/company-permissions";
export const PATH_DATABASE_GENERIC_ROLE_DETAIL = "/database/company-permissions/:uid";
export const PATH_DATABASE_NEW_GENERIC_ROLE = "/database/company-permissions/new";
export const PATH_DATABASE_CLW = `/database/${CLW_LAYOUT_KEY}`;
export const PATH_DATABASE_COMPANIES = `/database/${CLW_LAYOUT_KEY}/companies`;
export const PATH_DATABASE_NEW_COMPANY = `/database/${CLW_LAYOUT_KEY}/companies/new`;
export const PATH_DATABASE_COMPANY_DETAIL = `/database/${CLW_LAYOUT_KEY}/companies/:uid`;
export const PATH_DATABASE_WHITELABELS = `/database/${CLW_LAYOUT_KEY}/whitelabels`;
export const PATH_DATABASE_COMPANIES_SUGGESTIONS = `/database/${CLW_LAYOUT_KEY}/companies-suggestions`;
export const PATH_DATABASE_WHITELABEL_DETAIL = `/database/${CLW_LAYOUT_KEY}/whitelabels/:uid`;
export const PATH_DATABASE_LOCATIONS = `/database/${CLW_LAYOUT_KEY}/locations`;
export const PATH_DATABASE_LOCATION_DETAIL = `/database/${CLW_LAYOUT_KEY}/locations/:uid`;

export const PATH_DATABASE_WORLDWIDE_COMPANIES = "/database/world-wide-companies";
export const PATH_DATABASE_WORLDWIDE_COMPANY_DETAIL = "/database/world-wide-companies/:uid";

export const PATH_DATABASE_USER_ACCOUNTS = "/database/user-accounts";
export const PATH_DATABASE_USER_ACCOUNT_DETAIL = "/database/user-accounts/:uid";

export const PATH_DATABASE_AIRCRAFTS_LAYOUT = "/database/aircrafts";
export const PATH_DATABASE_ACTIVE_AIRCRAFTS = "/database/aircrafts/active";
export const PATH_DATABASE_PENDING_AIRCRAFTS = "/database/aircrafts/pending";
export const PATH_DATABASE_ACTIVE_AIRCRAFT_DETAIL = "/database/aircrafts/active/:uid";
export const PATH_DATABASE_PENDING_AIRCRAFT_DETAIL = "/database/aircrafts/pending/:uid";

export const PATH_DATABASE_WORLDWIDE_AIRCRAFTS = "/database/world-wide-aircrafts";
export const PATH_DATABASE_WORLDWIDE_AIRCRAFT_DETAIL = "/database/world-wide-aircrafts/:uid";

export const PATH_DATABASE_BOOKINGS = "/database/bookings";
export const PATH_DATABASE_BOOKING_DETAIL = "/database/bookings/:uid";

export const PATH_DATABASE_FLIGHTS = "/database/flights";
export const PATH_DATABASE_FLIGHT_DETAIL = "/database/flights/:uid";

export const PATH_DATABASE_AMENITIES = "/database/amenities";
export const PATH_DATABASE_AMENITY_DETAIL = "/database/amenities/:uid";
export const PATH_DATABASE_NEW_AMENITY = "/database/amenities/new";

export const PATH_DATABASE_SESSIONS = "/database/sessions";
export const PATH_DATABASE_SESSION_DETAIL = "/database/sessions/:uid";

export const PATH_DATABASE_LOGS = "/database/logs";
export const PATH_DATABASE_LOG_DETAIL = "/database/logs/:uid";

export const PATH_MAINTENANCE = "/maintenance";
export const PATH_AVIAN_PAY = "/avian-pay";
export const PATH_AVIAN_PAY_DASHBOARD = "/avian-pay/dashboard";
export const PATH_AVIAN_PAY_ACCOUNTS = "/avian-pay/accounts";
export const PATH_AVIAN_PAY_ACCOUNT_DETAIL = "/avian-pay/accounts/:uid";
export const PATH_AVIAN_PAY_TRANSACTIONS = "/avian-pay/transactions";
export const PATH_AVIAN_PAY_TRANSFER_REQUESTS = "/avian-pay/transfer-requests";
export const PATH_AVIAN_PAY_DISPUTES = "/avian-pay/disputes";
export const PATH_AVIAN_PAY_JET_CARD = "/avian-pay/jet-card/:uid";
export const PATH_MESSAGES = "/messages";
export const PATH_MESSAGE_DETAIL = "/messages/:conversation_id";
export const PATH_MARKETPLACE = "/marketplace";
export const PATH_MAINTENANCE_GENERIC_AIRCRAFTS = "/maintenance/generic-aircrafts";
export const PATH_MAINTENANCE_NEW_GENERIC_AIRCRAFT = "/maintenance/generic-aircrafts/new";
export const PATH_MAINTENANCE_GENERIC_AIRCRAFT_DETAIL = "/maintenance/generic-aircrafts/:uid";

export const PATH_MAINTENANCE_AIRCRAFT_CATEGORIES = "/maintenance/aircraft-categories";
export const PATH_MAINTENANCE_NEW_AIRCRAFT_CATEGORY = "/maintenance/aircraft-categories/new";
export const PATH_MAINTENANCE_AIRCRAFT_CATEGORY_DETAIL = "/maintenance/aircraft-categories/:uid";

export const PATH_MAINTENANCE_AIRPORTS = "/maintenance/airports";
export const PATH_MAINTENANCE_AIRPORT_DETAIL = "/maintenance/airports/:uid";

export const PATH_ADMINISTRATION = "/administration";
export const PATH_ADMINISTRATION_INVOICES = "/administration/invoices";
export const PATH_ADMINISTRATION_INVOICE_DETAIL = "/administration/invoices/:uid";
export const PATH_ADMINISTRATION_JET_CARDS = "/administration/jet-cards";
export const PATH_ADMINISTRATION_JET_CARD_DETAIL = "/administration/jet-cards/:uid";
export const PATH_ADMINISTRATION_PAYMENT_METHODS = "/administration/payment-methods";
export const PATH_ADMINISTRATION_PAYMENT_METHOD_DETAIL = "/administration/payment-methods/:uid";
export const PATH_ADMINISTRATION_PAYOUTS = "/administration/payouts";
export const PATH_ADMINISTRATION_PAYOUT_DETAIL = "/administration/payouts/:uid";
export const PATH_ADMINISTRATION_SUBCRIPTIONS = "/administration/subcriptions";
export const PATH_ADMINISTRATION_SUBCRIPTION_DETAIL = "/administration/subcriptions/:uid";
export const PATH_ADMINISTRATION_SYSTEM_SETTINGS = "/administration/system-settings";
export const PATH_ADMINISTRATION_BACK_OFFICE_USERS = "/administration/backoffice-users";
export const PATH_ADMINISTRATION_BACK_OFFICE_USER_DETAIL = "/administration/backoffice-users/:uid";
export const PATH_ADMINISTRATION_NEW_BACK_OFFICE_USER = "/administration/backoffice-users/new";
export const PATH_SETTINGS = "/settings";
export const PATH_NOTIFICATIONS = "/notifications";
export const PATH_ACTIVATE_USER = "/activate/:uid/:token";
export const PATH_SIGN_IN = "/sign-in";
export const PATH_NO_MATCH = "*";

export const menuSubKeys = [PATH_DATABASE, PATH_MAINTENANCE, PATH_ADMINISTRATION, PATH_AVIAN_PAY];

export const menuItemKeys = [
	PATH_DATABASE_CLW,
	PATH_DATABASE_GENERIC_SIDEBARS,
	PATH_DATABASE_GENERIC_ROLES,
	PATH_DATABASE_WORLDWIDE_COMPANIES,
	PATH_DATABASE_USER_ACCOUNTS,
	PATH_DATABASE_AIRCRAFTS_LAYOUT,
	PATH_DATABASE_WORLDWIDE_AIRCRAFTS,
	PATH_DATABASE_BOOKINGS,
	PATH_DATABASE_FLIGHTS,
	PATH_DATABASE_AMENITIES,
	PATH_DATABASE_SESSIONS,
	PATH_DATABASE_LOGS,
	PATH_AVIAN_PAY_DASHBOARD,
	PATH_AVIAN_PAY_ACCOUNTS,
	PATH_AVIAN_PAY_TRANSACTIONS,
	PATH_AVIAN_PAY_TRANSFER_REQUESTS,
	PATH_AVIAN_PAY_DISPUTES,
	PATH_MESSAGES,
	PATH_MESSAGE_DETAIL,
	PATH_MAINTENANCE_GENERIC_AIRCRAFTS,
	PATH_MAINTENANCE_AIRCRAFT_CATEGORIES,
	PATH_MAINTENANCE_AIRPORTS,
	PATH_ADMINISTRATION_PAYOUTS,
	PATH_ADMINISTRATION_INVOICES,
	PATH_ADMINISTRATION_SUBCRIPTIONS,
	PATH_ADMINISTRATION_PAYMENT_METHODS,
	PATH_ADMINISTRATION_JET_CARDS,
	PATH_ADMINISTRATION_SYSTEM_SETTINGS,
	PATH_ADMINISTRATION_BACK_OFFICE_USERS,
	PATH_SETTINGS,
	PATH_DASHBOARD,
	PATH_MARKETPLACE,
];

export const PROFILE_SETTING_KEYS = {
	SETTINGS: "settings",
	SIGN_OUT: "signout",
};

const routes = [
	{
		path: PATH_BASE,
		element: (
			<React.Suspense fallback={<Loading />}>
				<MainLayout />
			</React.Suspense>
		),
		children: [
			{
				index: true,
				element: <Navigate to={PATH_DASHBOARD} replace />,
			},
			{
				path: PATH_DASHBOARD,
				element: (
					<React.Suspense fallback={<Loading />}>
						<DashboardPage />
					</React.Suspense>
				),
			},
			{
				path: PATH_DATABASE,
				children: [
					{
						index: true,
						element: <Navigate to={PATH_DATABASE_COMPANIES} replace />,
					},
					{
						path: PATH_DATABASE_CLW,
						element: <Navigate to={PATH_DATABASE_COMPANIES} replace />,
					},
					{
						path: PATH_DATABASE_COMPANIES,
						element: (
							<React.Suspense fallback={<Loading />}>
								<CLWLayout defaultTabName={COMPANY_TAB_NAME.COMPANIES} />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_LOCATIONS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<CLWLayout defaultTabName={COMPANY_TAB_NAME.LOCATIONS} />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_WHITELABELS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<CLWLayout defaultTabName={COMPANY_TAB_NAME.WHITELABELS} />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_COMPANIES_SUGGESTIONS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<CLWLayout defaultTabName={COMPANY_TAB_NAME.COMPANIES_SUGGESTIONS} />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_NEW_COMPANY,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBNewCompanyPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_COMPANY_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBCompanyDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_WHITELABEL_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBWhitelabelDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_LOCATION_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBLocationDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_GENERIC_SIDEBARS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBGenericSidebarsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_GENERIC_ROLE_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBGenericRolesPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_GENERIC_ROLES,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBGenericRolesPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_NEW_GENERIC_ROLE,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBNewGenericRolePage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_WORLDWIDE_COMPANIES,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBWorldWideCompaniesPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_WORLDWIDE_COMPANY_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBWorldWideCompanyDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_USER_ACCOUNTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBUserAccountsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_USER_ACCOUNT_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBUserAccountDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_AIRCRAFTS_LAYOUT,
						element: <Navigate to={PATH_DATABASE_ACTIVE_AIRCRAFTS} replace />,
					},
					{
						path: PATH_DATABASE_ACTIVE_AIRCRAFTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AircraftsLayout defaultTabName={AIRCRAFT_TAB_NAME.ACTIVE_AIRCRAFTS} />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_PENDING_AIRCRAFTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AircraftsLayout defaultTabName={AIRCRAFT_TAB_NAME.PENDING_AIRCRAFTS} />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_ACTIVE_AIRCRAFT_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBAircraftDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_PENDING_AIRCRAFT_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBAircraftDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_WORLDWIDE_AIRCRAFTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBWorldWideAircraftsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_WORLDWIDE_AIRCRAFT_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBWorldWideAircraftDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_BOOKINGS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBBookingsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_BOOKING_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBBookingDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_FLIGHTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBFlightsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_AMENITIES,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBAmenitiesPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_AMENITY_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBAmenityDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_NEW_AMENITY,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBNewAmenityPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_FLIGHT_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBFlightDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_SESSIONS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBSessionsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_LOGS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBLogsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_LOG_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBLogDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_DATABASE_SESSION_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<DBSessionDetailPage />
							</React.Suspense>
						),
					},
				],
			},
			{
				path: PATH_AVIAN_PAY,
				children: [
					{
						index: true,
						element: <Navigate to={PATH_AVIAN_PAY_DASHBOARD} replace />,
					},
					{
						path: PATH_AVIAN_PAY_DASHBOARD,
						element: (
							<React.Suspense fallback={<Loading />}>
								<APDashboard />
							</React.Suspense>
						),
					},
					{
						path: PATH_AVIAN_PAY_ACCOUNTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<APAccounts />
							</React.Suspense>
						),
					},
					{
						path: PATH_AVIAN_PAY_ACCOUNT_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<APAccountDetail />
							</React.Suspense>
						),
					},
					{
						path: PATH_AVIAN_PAY_TRANSFER_REQUESTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<APTransferRequests />
							</React.Suspense>
						),
					},
					{
						path: PATH_AVIAN_PAY_JET_CARD,
						element: (
							<React.Suspense fallback={<Loading />}>
								<APAccountJetCard />
							</React.Suspense>
						),
					},
				],
			},
			{
				path: PATH_MESSAGES,
				element: (
					<React.Suspense fallback={<Loading />}>
						<MessagesPage />
					</React.Suspense>
				),
				children: [
					{
						path: PATH_MESSAGE_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<MessagesPage />
							</React.Suspense>
						),
					},
				],
			},
			{
				path: PATH_MARKETPLACE,
				element: (
					<React.Suspense fallback={<Loading />}>
						<MarketplacePage />
					</React.Suspense>
				),
			},
			{
				path: PATH_MAINTENANCE,
				children: [
					{
						index: true,
						element: <Navigate to={PATH_MAINTENANCE_GENERIC_AIRCRAFTS} replace />,
					},
					{
						path: PATH_MAINTENANCE_GENERIC_AIRCRAFTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<MNGenericAircraftsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_MAINTENANCE_NEW_GENERIC_AIRCRAFT,
						element: (
							<React.Suspense fallback={<Loading />}>
								<MNNewGenericAicraftPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_MAINTENANCE_GENERIC_AIRCRAFT_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<MNGenericAircraftDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_MAINTENANCE_AIRCRAFT_CATEGORIES,
						element: (
							<React.Suspense fallback={<Loading />}>
								<MNAircraftCategoriesPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_MAINTENANCE_NEW_AIRCRAFT_CATEGORY,
						element: (
							<React.Suspense fallback={<Loading />}>
								<MNNewAicraftCategoryPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_MAINTENANCE_AIRCRAFT_CATEGORY_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<MNAircraftCategoryDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_MAINTENANCE_AIRPORTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<MNAirportsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_MAINTENANCE_AIRPORT_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<MNAirportDetailPage />
							</React.Suspense>
						),
					},
				],
			},
			{
				path: PATH_ADMINISTRATION,
				children: [
					{
						index: true,
						element: <Navigate to={PATH_ADMINISTRATION_PAYOUTS} replace />,
					},
					{
						path: PATH_ADMINISTRATION_PAYOUTS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationPayoutsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_PAYOUT_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationPayoutDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_INVOICES,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationInvoicesPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_INVOICE_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationInvoiceDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_SUBCRIPTIONS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationSubcriptionsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_SUBCRIPTION_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationSubcriptionDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_PAYMENT_METHODS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationPaymentMethodsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_PAYMENT_METHOD_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationPaymentMethodDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_JET_CARDS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationJetCardsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_JET_CARD_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationJetCardDetailPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_SYSTEM_SETTINGS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationSystemSettingsPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_BACK_OFFICE_USERS,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationBackOfficeUsersPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_NEW_BACK_OFFICE_USER,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationNewBackOfficeUserPage />
							</React.Suspense>
						),
					},
					{
						path: PATH_ADMINISTRATION_BACK_OFFICE_USER_DETAIL,
						element: (
							<React.Suspense fallback={<Loading />}>
								<AdministrationBackOfficeUserDetailPage />
							</React.Suspense>
						),
					},
				],
			},
			{
				path: PATH_SETTINGS,
				element: (
					<React.Suspense fallback={<Loading />}>
						<SettingsPage />
					</React.Suspense>
				),
			},
			{
				path: PATH_NOTIFICATIONS,
				element: (
					<React.Suspense fallback={<Loading />}>
						<NotificationsPage />
					</React.Suspense>
				),
			},
		],
	},
	{
		path: PATH_SIGN_IN,
		element: (
			<React.Suspense fallback={<Loading />}>
				<AuthLayout>
					<SignInPage />
				</AuthLayout>
			</React.Suspense>
		),
	},
	{
		path: PATH_ACTIVATE_USER,
		element: (
			<React.Suspense fallback={<Loading />}>
				<AuthLayout>
					<ActivateUserPage />
				</AuthLayout>
			</React.Suspense>
		),
	},
	{
		path: PATH_NO_MATCH,
		element: (
			<React.Suspense fallback={<Loading />}>
				<NotfoundPage />
			</React.Suspense>
		),
	},
];

export default routes;
