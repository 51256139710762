/* eslint-disable react/prop-types */
import React from "react";

export const IconStatusSvg = ({ color = "#7ED321", ...props }) => {
	return (
		<svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle cx="3.5" cy="3.5" r="3.5" fill={color} />
		</svg>
	);
};
