import React from "react";

export const IconAvailableSvg = (props) => (
	<svg width={37} height={37} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={18.877} cy={18.5} r={18} fill="#12B04A" />
		<path
			d="M19.5 10c-4.963 0-9 4.038-9 9s4.037 9 9 9c4.962 0 9-4.038 9-9s-4.038-9-9-9Zm5.03 6.632-5.752 5.706a.885.885 0 0 1-1.24.023l-3.046-2.774a.915.915 0 0 1-.068-1.264.892.892 0 0 1 1.264-.045l2.413 2.21 5.143-5.142a.899.899 0 0 1 1.286 0c.36.36.36.925 0 1.286Z"
			fill="#fff"
		/>
	</svg>
);
