import React from "react";

export const IconInternalSvg = (props) => (
	<svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={16} cy={16} r={16} fill="#40A1F6" fillOpacity={0.1} />
		<g clipPath="url(#a)" fill="#40A1F6">
			<path d="M16.687 9.247 9.36 8.134c-.34-.054-.687.04-.947.26-.26.226-.413.553-.413.893v14.047c0 .367.3.666.667.666h2.166v-3.5c0-.646.52-1.166 1.167-1.166h1.667c.646 0 1.166.52 1.166 1.166V24h2.834V10.4c0-.573-.414-1.06-.98-1.153Zm-5.02 8.587h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Zm0-2h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Zm0-2h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Zm0-2h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Zm3.333 6h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Zm0-2h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Zm0-2h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Zm0-2h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1ZM23.08 15.228l-4.746-.994V24h4.5c.643 0 1.167-.523 1.167-1.166v-6.466c0-.55-.376-1.019-.92-1.14ZM21.5 22h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Zm0-2h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Zm0-2h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" transform="translate(8 8)" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
