import React from "react";
import PropTypes from "prop-types";
import { Modal as AntdModal } from "antd";
import { noop } from "lodash";
import styled from "styled-components";

import { IconCloseSvg } from "../Svgs";

const Modal = ({ visible, handleCancel, children, ...props }) => {
	return (
		<StyledModal centered footer={null} open={visible} onCancel={handleCancel} closeIcon={<IconCloseSvg />} {...props}>
			{children}
		</StyledModal>
	);
};

export default Modal;

Modal.propTypes = {
	visible: PropTypes.bool,
	handleCancel: PropTypes.func,
	children: PropTypes.node,
};

Modal.defaultProps = {
	visible: true,
	handleCancel: noop,
};

const StyledModal = styled(AntdModal)`
	.ant-modal-content {
		background-color: #ffffff;
		border-radius: 8px;
		box-shadow: 0 17px 20px 0 rgba(14, 14, 18, 0.1);
		position: relative;
	}
	.ant-modal-body {
		padding: 50px;
	}
	.ant-modal-close,
	.ant-modal-close-x {
		background-color: #f0f3f9;
		border-radius: 100%;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		top: -22px;
		right: -22px;
	}
`;
