import React from "react";

export const IconArrowDownSvg = (props) => (
	<svg width={10} height={7} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M5.001 4.636 8.782.852a.712.712 0 0 1 1.01 0c.28.28.276.733 0 1.012L5.506 6.151a.713.713 0 0 1-.985.021L.208 1.867A.714.714 0 0 1 0 1.361.714.714 0 0 1 1.218.855L5 4.636Z"
			fill="#8E929A"
		/>
	</svg>
);
