import produce from "immer";
import { dbCLWActions } from "./actions";

const INITIAL_STATE = {
	sidebarSettings: {},
	companies: {},
	locations: {},
	whitelabels: {},
	companiesSuggestions: {},
	companiesSuggestionsDetail: {},
	companyDetail: {},
	whitelabelDetail: {},
	locationDetail: {},
	error: null,
};

const dbCLWReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case dbCLWActions.FETCH_COMPANY_SIDEBAR_SETTINGS_SUCCESS:
			draft.sidebarSettings = action.payload;
			break;
		case dbCLWActions.FETCH_COMPANIES_SUCCESS:
			draft.companies = action.payload;
			break;
		case dbCLWActions.FETCH_LOCATIONS_SUCCESS:
			draft.locations = action.payload;
			break;
		case dbCLWActions.FETCH_WHITE_LABELS_SUCCESS:
			draft.whitelabels = action.payload;
			break;
		case dbCLWActions.FETCH_COMPANIES_SUGGESTIONS_SUCCESS:
			draft.companiesSuggestions = action.payload;
			break;
		case dbCLWActions.FETCH_UPDATE_COMPANIES_SUGGESTIONS_STATUS_SUCCESS:
			const companiesSuggestionsDocs = draft.companiesSuggestions?.docs?.map((company) =>
				company._id === action.payload?.companyId ? { ...company, status: action.payload?.status } : company
			);
			draft.companiesSuggestions = { ...draft.companiesSuggestions, docs: companiesSuggestionsDocs };
			break;
		case dbCLWActions.FETCH_COMPANIES_SUGGESTIONS_DETAIL_SUCCESS:
			draft.companiesSuggestionsDetail = action.payload;
			break;
		case dbCLWActions.FETCH_COMPANY_DETAIL_SUCCESS:
			draft.companyDetail = action.payload;
			break;
		case dbCLWActions.FETCH_BLOCK_COMPANY_SUCCESS:
			draft.companyDetail.blocked_at = action.payload;
			break;
		case dbCLWActions.FETCH_VALIDATE_COMPANY_SUCCESS:
			draft.companyDetail.validated_at = action.payload;
			break;
		case dbCLWActions.FETCH_WHITELABEL_DETAIL_SUCCESS:
			draft.whitelabelDetail = action.payload;
			break;
		case dbCLWActions.FETCH_LOCATION_DETAIL_SUCCESS:
			draft.locationDetail = action.payload;
			break;
		case dbCLWActions.FETCH_APPROVE_LOCATION_SUCCESS:
			draft.locationDetail.validated_at = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default dbCLWReducer;
