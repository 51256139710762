import React from "react";

export const IconTimeReleasesSvg = (props) => (
	<svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={16} cy={16} r={16} fill="#FCECEE" />
		<g clipPath="url(#a)">
			<path
				d="M16 24c4.417 0 8-3.583 8-8s-3.583-8-8-8-8 3.583-8 8 3.583 8 8 8Zm-.571-12.571c0-.315.257-.572.571-.572.314 0 .571.257.571.572v4.297l2.643 2.114a.572.572 0 0 1-.714.891l-2.857-2.285a.573.573 0 0 1-.214-.446v-4.571Z"
				fill="#DD4558"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" transform="translate(8 8)" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
