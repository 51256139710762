import { IDENTIFY_TYPE } from "utils/constants";
import produce from "immer";
import { setAuthToken } from "utils/helpers";
import { commonActions } from "./actions";

const INITIAL_STATE = {
	identify: {},
	avUser: {},
	apiError: {
		visible: false,
		statusCode: -1,
	},
};

const commonReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case commonActions.FETCH_IDENTIFY_SUCCESS:
			const authToken = action.payload?.auth_token;
			setAuthToken({
				accessToken: authToken?.access_token,
				refreshToken: authToken?.refresh_token,
				tokenType: IDENTIFY_TYPE,
			});
			draft.identify = action.payload;
			break;
		case commonActions.FETCH_VALIDATE_ACTIVATION_SUCCESS:
			draft.avUser = action.payload;
			break;
		case commonActions.OPEN_MODAL_API_ERROR:
			draft.apiError = { visible: true, message: action.payload?.message, statusCode: action.payload?.statusCode };
			break;
		case commonActions.CANCEL_MODAL_API_ERROR:
			draft.apiError.visible = false;
			break;
		default:
			break;
	}
	return draft;
});

export default commonReducer;
