/* eslint-disable react/prop-types */
import React from "react";

export const IconMembersSvg = ({ color = "#19C0FF", ...props }) => {
	return (
		<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.1663 9.68538C11.1663 9.02472 10.7737 8.39005 10.047 7.89472C9.13301 7.27138 7.66234 6.85205 5.99967 6.85205C4.33701 6.85205 2.86634 7.27138 1.95234 7.89472C1.22567 8.39005 0.833008 9.02472 0.833008 9.68538V10.3974C0.833008 10.6541 0.935008 10.9001 1.11634 11.0814C1.29767 11.2627 1.54301 11.3641 1.79967 11.3641H10.1997C10.4563 11.3641 10.7017 11.2627 10.883 11.0814C11.0643 10.9001 11.1663 10.6541 11.1663 10.3974V9.68538ZM10.1663 10.3641H1.83301V9.68538C1.83301 9.31538 2.10901 8.99805 2.51567 8.72072C3.30101 8.18538 4.57167 7.85205 5.99967 7.85205C7.42767 7.85205 8.69834 8.18538 9.48368 8.72072C9.89034 8.99805 10.1663 9.31538 10.1663 9.68538V10.3641Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.1665 10.3642H11.9999C11.7239 10.3642 11.4999 10.5882 11.4999 10.8642C11.4999 11.1402 11.7239 11.3642 11.9999 11.3642H14.1999C14.4565 11.3642 14.7019 11.2629 14.8832 11.0816C15.0645 10.9002 15.1665 10.6542 15.1665 10.3976V9.68558C15.1665 9.13024 14.8932 8.59691 14.3759 8.14758C13.7299 7.58491 12.6739 7.14291 11.4072 6.95424C11.1339 6.91358 10.8792 7.10225 10.8385 7.37491C10.7979 7.64825 10.9865 7.90291 11.2592 7.94358C12.3052 8.09958 13.1865 8.43825 13.7199 8.90225C13.9899 9.13625 14.1665 9.39558 14.1665 9.68558V10.3642Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.99935 0.63623C4.43535 0.63623 3.16602 1.90556 3.16602 3.46956C3.16602 5.0329 4.43535 6.3029 5.99935 6.3029C7.56335 6.3029 8.83268 5.0329 8.83268 3.46956C8.83268 1.90556 7.56335 0.63623 5.99935 0.63623ZM5.99935 1.63623C7.01135 1.63623 7.83268 2.45756 7.83268 3.46956C7.83268 4.4809 7.01135 5.3029 5.99935 5.3029C4.98735 5.3029 4.16602 4.4809 4.16602 3.46956C4.16602 2.45756 4.98735 1.63623 5.99935 1.63623Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 1.63623C11.012 1.63623 11.8333 2.45756 11.8333 3.46956C11.8333 4.4809 11.012 5.3029 10 5.3029C9.724 5.3029 9.5 5.5269 9.5 5.8029C9.5 6.0789 9.724 6.3029 10 6.3029C11.564 6.3029 12.8333 5.0329 12.8333 3.46956C12.8333 1.90556 11.564 0.63623 10 0.63623C9.724 0.63623 9.5 0.86023 9.5 1.13623C9.5 1.41223 9.724 1.63623 10 1.63623Z"
				fill={color}
			/>
		</svg>
	);
};
