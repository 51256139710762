import React from "react";

export const IconDisputeSvg = (props) => (
	<svg width={19} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M9.61 14.992a1.095 1.095 0 0 0-.881-1.742H3.416c-.292 0-.567.114-.773.32-.207.207-.32.481-.32.774 0 .242.079.466.213.647a1.564 1.564 0 0 0-.838 1.384v.312c0 .173.14.313.312.313h8.125c.172 0 .312-.14.312-.313v-.312c0-.6-.34-1.122-.837-1.383Zm-6.525-.98a.466.466 0 0 1 .331-.137H8.73a.47.47 0 0 1 0 .937H3.416a.47.47 0 0 1-.331-.8Zm-.762 2.363c0-.517.42-.938.937-.938H8.885c.517 0 .937.421.937.938h-7.5Z"
			fill="#8E929A"
			stroke="#8E929A"
			strokeWidth={0.2}
		/>
		<path
			d="M17.407 14.129a1.965 1.965 0 0 0-.376-.432l-1.46-1.274a.312.312 0 0 0-.125-.108l-.883-.77-4.087-3.566a.93.93 0 0 0 .26-.647.932.932 0 0 0-.534-.845l.378-.378a1.246 1.246 0 0 0 1.573-1.207c0-.334-.13-.648-.367-.884L9.135 1.366A1.242 1.242 0 0 0 8.25 1a1.25 1.25 0 0 0-1.207 1.573L3.271 6.346a1.258 1.258 0 0 0-1.207.323 1.251 1.251 0 0 0 0 1.768l2.652 2.652c.243.243.563.365.884.365a1.246 1.246 0 0 0 1.206-1.572l.378-.378a.938.938 0 0 0 1.493.274l4.353 5.002a.312.312 0 0 0 .06.069l1.297 1.49a1.859 1.859 0 0 0 1.686.642 1.89 1.89 0 0 0 1.396-.965 1.85 1.85 0 0 0-.062-1.887ZM7.81 1.809a.62.62 0 0 1 .884 0l2.652 2.65a.621.621 0 0 1 0 .885.625.625 0 0 1-.884 0L7.809 2.692a.625.625 0 0 1 0-.884Zm-1.767 8.838a.626.626 0 0 1-.884 0L2.506 7.995a.626.626 0 0 1 .884-.884l2.652 2.652a.625.625 0 0 1 0 .884Zm1.315-2.2-.874.874-2.651-2.652 3.535-3.535 2.652 2.652-.876.875-.008.008-1.767 1.767-.01.011Zm1.116.652-.002.002-.22.22a.31.31 0 0 1-.442 0 .31.31 0 0 1-.004-.438L9.58 7.108a.314.314 0 0 1 .53.224.31.31 0 0 1-.091.221l-.219.219a.314.314 0 0 0-.004.004L8.473 9.1Zm.647.237.914-.914 3.866 3.373-1.41 1.412-3.37-3.871Zm3.78 4.343 1.473-1.472.472.412-1.533 1.533-.411-.473Zm4.024 2.032c-.202.36-.543.597-.936.652a1.242 1.242 0 0 1-1.128-.432l-1.137-1.307 1.594-1.593 1.304 1.138.003.002a1.234 1.234 0 0 1 .3 1.54Z"
			fill="#8E929A"
			stroke="#8E929A"
			strokeWidth={0.2}
		/>
	</svg>
);
