import React from "react";

export const IconFilterSvg = (props) => (
	<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.333 3.333v1.481h13.333V3.333H1.333Zm5.185 8.889H9.48v-1.481H6.518v1.481Zm5.927-3.704h-8.89V7.037h8.89v1.481Z"
			fill="#8E929A"
		/>
	</svg>
);
