/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

export const IconInfoFilledSvg = ({ includeBorder = false, size = 32, ...props }) => (
	<Container includeBorder={includeBorder} size={`${size}px`}>
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.79922 11.9992H7.19922V7.19922H8.79922V11.9992ZM7.19922 5.6H8.79922V4H7.19922V5.6Z"
				fill="#19C0FF"
			/>
		</svg>
	</Container>
);

const Container = styled.div`
	background: ${({ includeBorder }) => (includeBorder ? "#f0f3f9" : "transparent")};
	display: flex;
	border-radius: 50%;
	width: ${({ includeBorder, size }) => (includeBorder ? size : "fit-content")};
	height: ${({ includeBorder, size }) => (includeBorder ? size : "fit-content")};
	justify-content: center;
	align-items: center;
`;
