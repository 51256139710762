import { combineActiontype } from "utils/helpers";

const page = "dbGenericSidebars";

export const dbGenericSidebarsActions = combineActiontype(page, {
	FETCH_GENERIC_SIDEBARS_SUCCESS: "FETCH_GENERIC_SIDEBARS_SUCCESS",
	FETCH_GENERIC_SIDEBARS_FAILURE: "FETCH_GENERIC_SIDEBARS_FAILURE",
});

export const fetchGenericSidebarsSuccess = (genericSidebars) => ({
	type: dbGenericSidebarsActions.FETCH_GENERIC_SIDEBARS_SUCCESS,
	payload: genericSidebars,
});

export const fetchGenericSidebarsFailure = (error) => ({
	type: dbGenericSidebarsActions.FETCH_GENERIC_SIDEBARS_FAILURE,
	payload: error,
});
