import { combineActiontype } from "utils/helpers";

const page = "dbWorldWideAircrafts";

export const dbWorldWideAircraftsActions = combineActiontype(page, {
	FETCH_WORLDWIDE_AIRCRAFTS_SUCCESS: "FETCH_WORLDWIDE_AIRCRAFTS_SUCCESS",
	FETCH_WORLDWIDE_AIRCRAFTS_FAILURE: "FETCH_WORLDWIDE_AIRCRAFTS_FAILURE",

	FETCH_WORLDWIDE_AIRCRAFT_DETAIL_SUCCESS: "FETCH_WORLDWIDE_AIRCRAFT_DETAIL_SUCCESS",
	FETCH_WORLDWIDE_AIRCRAFT_DETAIL_FAILURE: "FETCH_WORLDWIDE_AIRCRAFT_DETAIL_FAILURE",

	FETCH_ACTIVATE_WORLDWIDE_AIRCRAFT_SUCCESS: "FETCH_ACTIVATE_WORLDWIDE_AIRCRAFT_SUCCESS",
	FETCH_ACTIVATE_WORLDWIDE_AIRCRAFT_FAILURE: "FETCH_ACTIVATE_WORLDWIDE_AIRCRAFT_FAILURE",
});

export const fetchWorldWideAircraftsSuccess = (wwAircarfts) => ({
	type: dbWorldWideAircraftsActions.FETCH_WORLDWIDE_AIRCRAFTS_SUCCESS,
	payload: wwAircarfts,
});

export const fetchWorldWideAircraftsFailure = (error) => ({
	type: dbWorldWideAircraftsActions.FETCH_WORLDWIDE_AIRCRAFTS_FAILURE,
	payload: error,
});

export const fetchWorldWideAircraftDetailSuccess = (wwAircarft) => ({
	type: dbWorldWideAircraftsActions.FETCH_WORLDWIDE_AIRCRAFT_DETAIL_SUCCESS,
	payload: wwAircarft,
});

export const fetchWorldWideAircraftDetailFailure = (error) => ({
	type: dbWorldWideAircraftsActions.FETCH_WORLDWIDE_AIRCRAFT_DETAIL_FAILURE,
	payload: error,
});

export const fetchActivateWorldWideAircraftSuccess = (activatedAt) => ({
	type: dbWorldWideAircraftsActions.FETCH_ACTIVATE_WORLDWIDE_AIRCRAFT_SUCCESS,
	payload: activatedAt,
});

export const fetchActivateWorldWideAircraftFailure = (error) => ({
	type: dbWorldWideAircraftsActions.FETCH_ACTIVATE_WORLDWIDE_AIRCRAFT_FAILURE,
	payload: error,
});
