import produce from "immer";
import { apAccountsActions } from "./actions";

const INITIAL_STATE = {
	accounts: {},
	error: null,
};

const apAccountsReducer = produce((draft = INITIAL_STATE, action) => {
	switch (action.type) {
		case apAccountsActions.FETCH_AP_ACCOUNTS_SUCCESS:
			draft.accounts = action.payload;
			break;
		default:
			break;
	}
	return draft;
});

export default apAccountsReducer;
